import { TemplateScenarioShortReadModel, useApiDeleteTemplateScenarioMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface TemplateScenarioDeleteModalProps extends ModalOnCloseProps {
  scenario: TemplateScenarioShortReadModel | undefined;
}

export const TemplateScenarioDeleteModal = ({ scenario, onClose }: TemplateScenarioDeleteModalProps) => {
  const { t } = useTranslation();

  const [postDelete, { isLoading: isDeleting }] = useApiDeleteTemplateScenarioMutation();

  const handleDelete = async () => {
    if (scenario){
      try {
        await safeMutation(
          postDelete,
          {
            templateId: scenario?.templateId ?? '',
            scenarioId: scenario?.id ?? '',
          },
          isDeleting
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${scenario?.name ?? ''}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeleting}
    >
      {isDeleting && <LoadingIndicator mode="overlay-window" text={t('templates.catalogTemplatesLoadingIndicator')} />}
      <Trans i18nKey="templates.catalogTemplateDeleteConfirmation" values={{ entity: `${scenario?.name ?? ''}` }}>
        <div className="block">
          Do you want to delete
          <span className="font-bold">Project template</span>
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
