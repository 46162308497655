import classNames from 'classnames';
import { FormattedCurrency, FormulaEditorBenchmarkSelectableFilter } from '.';
import { CheckBox, Highlighted } from '@client/shared/toolkit';
import { countryCodes, formatDate } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { BenchmarkingFilterProject } from '../utils';
import React, { useEffect, useMemo, useRef } from 'react';

interface BenchmarkingProjectSelectProps {
  benchmarkProjects: BenchmarkingFilterProject[];
  selectedFilters: FormulaEditorBenchmarkSelectableFilter;
  selectedElements: BenchmarkingFilterProject[];
  setSelectedElements: (elements: BenchmarkingFilterProject[]) => void;
  showCalculation?: boolean;
  setCalculatedAverage?: (value: number | undefined) => void;
}

const BENCHMARKING_PROJECT_PAGE_SIZE = 10;
const BENCHMARKING_PROJECT_ROW_HEIGHT = 72;

export const BenchmarkingProjectSelect = ({
  benchmarkProjects,
  selectedFilters,
  selectedElements,
  setSelectedElements,
  showCalculation = true,
  setCalculatedAverage,
}: BenchmarkingProjectSelectProps) => {
  const { t } = useTranslation();

  const [page, setPage] = React.useState<number>(1);

  const filteredBenchmarkProjects = useMemo(() => {
    return benchmarkProjects.filter((project) => {
      const matchesAssetClasses =
        selectedFilters.assetClasses.length === 0 ||
        project.assetClasses.some((assetClass) => selectedFilters.assetClasses.includes(assetClass.id));

      const matchesStartDate = !selectedFilters.start || new Date(project.start) >= selectedFilters.start;

      const matchesEndDate = !selectedFilters.end || new Date(project.end) <= selectedFilters.end;

      const searchInputs = selectedFilters.searchInput.split(',').map((input) => input.trim().toLowerCase());
      const matchesSearchInput = searchInputs.some(
        (input) =>
          project.name.toLowerCase().includes(input) ||
          (project.countryCode &&
            (countryCodes.getCountryName(project.countryCode) ?? project.countryCode).toLowerCase().includes(input)) ||
          project?.city?.toLowerCase().includes(input),
      );

      return matchesAssetClasses && matchesStartDate && matchesEndDate && matchesSearchInput;
    }) as BenchmarkingFilterProject[];
  }, [benchmarkProjects, selectedFilters]);

  const calculatedAverage = useMemo(() => {
    return filteredBenchmarkProjects.length === 1
      ? filteredBenchmarkProjects[0].adjustedCatalogElementValue
      : filteredBenchmarkProjects.reduce((acc, project) => acc + (project.adjustedCatalogElementValue ?? 0), 0) /
          filteredBenchmarkProjects.length || 0;
  }, [filteredBenchmarkProjects]);

  useEffect(() => {
    if (setCalculatedAverage) {
      setCalculatedAverage(calculatedAverage);
    }
  }, [calculatedAverage, setCalculatedAverage]);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * BENCHMARKING_PROJECT_ROW_HEIGHT);
    if (position === BENCHMARKING_PROJECT_ROW_HEIGHT) {
      setPage(page + 1);
    }
  };

  return (
    <div>
      <div
        className="flex flex-col divide-y overflow-y-scroll max-h-[220px]"
        onScroll={handleScroll}
        ref={containerRef}
      >
        {filteredBenchmarkProjects.map((element: BenchmarkingFilterProject, i) => {
          if (i > page * BENCHMARKING_PROJECT_PAGE_SIZE) {
            return null;
          }
          return (
            <div className="w-full bg-white flex items-center divide-x font-medium text-gray-900" key={element.id}>
              <div className="w-7/12 flex items-center justify-between p-2">
                <div className="flex items-center gap-4 pl-2">
                  <div className="flex flex-col text-start ">
                    <div>
                      <Highlighted text={element.name} highlight={selectedFilters.searchInput} />
                    </div>
                    <div className="text-xs text-slate-700">
                      <Highlighted text={element.city ?? ''} highlight={selectedFilters.searchInput} />
                      {' - '}
                      <Highlighted
                        text={
                          element.countryCode
                            ? countryCodes.getCountryName(element.countryCode) ?? element.countryCode
                            : ''
                        }
                        highlight={selectedFilters.searchInput}
                      />
                    </div>
                    <div className="text-xs text-slate-700 flex gap-2">
                      {`${formatDate(new Date(element.start))} - ${formatDate(new Date(element.end))}`}
                    </div>
                  </div>
                </div>
                {showCalculation && (
                  <div className="p-2 h-full flex items-center">
                    <FormattedCurrency amount={element.catalogElementValue} />
                  </div>
                )}
              </div>
              {showCalculation && (
                <div className="w-1/12 p-6 h-full flex items-center justify-center">{element.regionalFactor}</div>
              )}

              <div
                className={classNames('flex items-center p-4', {
                  'w-4/12 justify-between': showCalculation,
                  'w-full justify-end': !showCalculation,
                })}
              >
                {showCalculation && (
                  <div className="w-full text-end mr-4">
                    <FormattedCurrency amount={element.adjustedCatalogElementValue} />
                  </div>
                )}
                <CheckBox
                  className="cursor-pointer"
                  checked={selectedElements.some((project) => project.id === element.id)}
                  onChange={(checked) => {
                    setSelectedElements(
                      checked
                        ? [...selectedElements, element]
                        : selectedElements.filter((project: BenchmarkingFilterProject) => project.id !== element.id),
                    );
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {showCalculation && (
        <div className="flex flex-col w-full items-end pr-16 mt-2">
          <div className="font-medium text-gray-900">
            <FormattedCurrency amount={calculatedAverage} />
          </div>
          <div className="text-xs">{t('projectCalculate.benchmarkingAverage')}</div>
        </div>
      )}
    </div>
  );
};
