import {
  Button,
  Modal,
  Wizard,
  WizardSelectList,
  WizardSelectListItemProps,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BenchmarkingProjectFilter,
  FormulaEditorBenchmarkSelectableFilter,
} from '.';
import { BenchmarkProjectReadModel } from '@client/shared/api';
import { BenchmarkingProjectSelect } from './BenchmarkingProjectSelect';
import { BenchmarkingFilterProject } from '../utils';

interface BenchmarkingValuationModalProps {
  referenceMethodItems: WizardSelectListItemProps[];
  benchmarkProjects?: BenchmarkProjectReadModel[];
  handleOnClose: () => void;
  handleAdd: (value: string) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export const BenchmarkingValuationModal = ({
  referenceMethodItems,
  benchmarkProjects = [],
  handleOnClose,
  handleAdd,
  currentStep,
  setCurrentStep,
}: BenchmarkingValuationModalProps) => {
  const { t } = useTranslation();

  const [selectedProjects, setSelectedProjects] = useState<BenchmarkingFilterProject[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<FormulaEditorBenchmarkSelectableFilter>({
    searchInput: '',
    assetClasses: [] as string[],
    start: null,
    end: null,
  });
  const [calculatedAverage, setCalculatedAverage] = useState<number | undefined>();

  return (
    <Wizard currentStep={currentStep} onSlideChange={(step) => setCurrentStep(step)}>
      <Wizard.Slides>
        <Wizard.Slide>
          <WizardSlideHeader
            title={t('projectCalculate.benchmarkingValuationTitle')}
            subTitle={t('projectCalculate.benchmarkingValuationDescription')}
          />
          <WizardSlideContent className="flex justify-center items-center">
            <WizardSelectList items={referenceMethodItems} className="justify-center" />
          </WizardSlideContent>
        </Wizard.Slide>
        <Wizard.Slide>
          <WizardSlideHeader
            title={t('projectCalculate.benchmarkingProjectAndFactor')}
            subTitle={t('projectCalculate.benchmarkingProjectAndFactorDescription')}
          />
          <WizardSlideContent className="h-[310px]">
            <BenchmarkingProjectFilter
              benchmarkProjects={benchmarkProjects}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <BenchmarkingProjectSelect
              benchmarkProjects={benchmarkProjects}
              selectedElements={selectedProjects}
              setSelectedElements={setSelectedProjects}
              selectedFilters={selectedFilters}
              setCalculatedAverage={setCalculatedAverage}
            />
          </WizardSlideContent>
        </Wizard.Slide>
      </Wizard.Slides>
      <Wizard.Navigation>
        {({ previous, next, isLast }) => (
          <Modal.Controls className="bg-white">
            {currentStep === 0 ? (
              <Button variant="secondary" onClick={handleOnClose}>
                {t('common.cancel')}
              </Button>
            ) : (
              <Button variant="text" onClick={previous}>
                {t('common.back')}
              </Button>
            )}

            {isLast ? (
              <Button
                variant="primary"
                onClick={() => {
                  handleAdd(calculatedAverage ? calculatedAverage.toString() : '');
                }}
              >
                {t('common.add')}
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  next();
                }}
              >
                {t('common.next')}
              </Button>
            )}
          </Modal.Controls>
        )}
      </Wizard.Navigation>
    </Wizard>
  );
};
