import {
  createAppError,
  finishAppInit,
  getAppUser,
  setSession,
  settings,
  UserState,
  appError,
  login,
  logout,
} from '@client/shared/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { fork, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  api,
  ApiPostLoginApiResponse,
  ApiPostCreateUserInvitationApiResponse,
  ApiPostCheckSessionApiResponse,
  ApiPostRefreshTokenApiResponse,
  ApiGetChallengeApiResponse,
  ApiGetExchangeApiResponse,
  IAccessToken,
} from '@client/shared/api';
import { parseDomain, wait, i18n, setLanguageAndLocale } from '@client/shared/utilities';
import toast from 'react-hot-toast';
import { Userpilot } from 'userpilot';
import { getCookie, removeCookie, setCookie } from 'typescript-cookie';
import { ROUTES_CONFIG } from '@client/shared/permissions';

export const ACCESS_TOKEN_COOKIE_NAME = 'access-token';
export const REFRESH_TOKEN_COOKIE_NAME = 'refresh-token';

const setAccessTokenCookies = (payload: ApiPostLoginApiResponse | ApiPostRefreshTokenApiResponse | IAccessToken) => {
  setCookie(ACCESS_TOKEN_COOKIE_NAME, payload.access_token, {
    domain: window.location.hostname,
    sameSite: 'Lax',
    path: '/',
  });

  setCookie(REFRESH_TOKEN_COOKIE_NAME, payload.refresh_token, {
    domain: window.location.hostname,
    sameSite: 'Lax',
    path: '/',
    expires: new Date(payload.refresh_token_expires_in),
  });
};

function* clearCacheOnLogout() {
  yield takeEvery(logout, function* () {
    yield put(api.util.resetApiState());
  });
}

function* onUserLoggedOut() {
  // react to certain known calls and ensure that the user is logged out
  yield takeEvery(
    [
      api.endpoints.apiPostLogout.matchFulfilled,
      api.endpoints.apiPostLogoutSessions.matchFulfilled,
      api.endpoints.apiPostLogin.matchRejected,
      // api.endpoints.apiPostCheckSession.matchRejected,
    ],
    function* (action) {
      yield put(logout());
      removeCookie(ACCESS_TOKEN_COOKIE_NAME, {
        domain: window.location.hostname,
        path: '/',
      });
      removeCookie(REFRESH_TOKEN_COOKIE_NAME, {
        domain: window.location.hostname,
        path: '/',
      });
    },
  );
}

function* onCheckSession() {
  // react to certain known calls and ensure that the user is logged out
  yield takeEvery([api.endpoints.apiPostCheckSession.matchRejected], function* () {
    const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_NAME);
    removeCookie(ACCESS_TOKEN_COOKIE_NAME, {
      domain: window.location.hostname,
      path: '/',
    });
    if (refreshToken) {
      yield put(
        setSession({
          sessionId: '',
          refreshToken: refreshToken,
        }),
      );
    } else {
      yield put(logout());
    }
  });
}

function* onUserAuthenticated() {
  yield takeEvery(
    [api.endpoints.apiPostLogin.matchFulfilled],
    function* (action: PayloadAction<ApiPostLoginApiResponse>) {
      setAccessTokenCookies(action.payload);
      yield put(
        setSession({
          sessionId: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
        }),
      );
    },
  );
}

function* onGetRefreshToken() {
  yield takeEvery(
    [api.endpoints.apiPostRefreshToken.matchFulfilled],
    function* (action: PayloadAction<ApiPostRefreshTokenApiResponse>) {
      if (action.payload.access_token && action.payload.refresh_token) {
        setAccessTokenCookies(action.payload);
        yield put(
          setSession({
            sessionId: action.payload.access_token,
            refreshToken: action.payload.refresh_token,
          }),
        );
        const appUser: UserState = yield select(getAppUser);
        if (!appUser?.userId) {
          try {
            // // check if the session is valid on the server (ignore cache!)
            yield put(
              api.endpoints.apiPostCheckSession.initiate(undefined, {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }) as any,
            );

            // wait for the result of the call.
            // no need to distinguish between the ok/error here, this is done in another saga
            yield take([
              api.endpoints.apiPostCheckSession.matchFulfilled,
              api.endpoints.apiPostCheckSession.matchRejected,
            ]);
            yield put(finishAppInit());
          } catch (err) {
            yield put(appError(createAppError(err)));
          }
        } else {
          yield put(finishAppInit());
        }
      }
    },
  );

  yield takeEvery(
    [api.endpoints.apiPostRefreshToken.matchRejected],
    function* (action: PayloadAction<ApiPostRefreshTokenApiResponse>) {
      toast.error(i18n.t('app.notificationUnauthorized'));
      yield wait(500);
      yield put(logout());
      removeCookie(ACCESS_TOKEN_COOKIE_NAME, {
        domain: window.location.hostname,
        path: '/',
      });
      removeCookie(REFRESH_TOKEN_COOKIE_NAME, {
        domain: window.location.hostname,
        path: '/',
      });
      if (window.location.pathname !== '/auth/login') {
        window.location.href = '/auth/login';
      }
    },
  );
}

function* onCheckSessionAuthenticated() {
  yield takeEvery(
    [api.endpoints.apiPostCheckSession.matchFulfilled],
    function* (action: PayloadAction<ApiPostCheckSessionApiResponse>) {
      const tenants = action.payload.tenants ?? [];

      // the user is not active on any tenant, we cannot continue
      if (tenants.length === 0) {
        yield put(appError("You currently don't have access to any tenant, logging out"));
        yield wait(500);
        yield put(logout());
        window.location.reload();
      }

      const selectedTenant = action.payload.tenant ?? tenants[0];

      const searchParams = new URLSearchParams(window.location.search);
      let redirectTarget = searchParams.get('_r') ?? '';
      if (window.location.pathname === '/datev/callback' && window.location.search) {
        redirectTarget = '/datev/callback' + window.location.search;
      }

      // calculate a fallback target from tenant-session
      const fallbackTarget = `${window.location.protocol}//${selectedTenant?.subdomain}.${settings.mainDomain}:${window.location.port}`;

      const fallback = function* () {
        yield put(appError(`Redirecting to ${fallbackTarget}/`));
        setTimeout(() => {
          window.location.href = fallbackTarget + redirectTarget;
          return;
        }, 2000);
      };

      const domain = parseDomain(window.location.hostname);

      if (domain.subdomain === undefined && window.location.pathname.includes('/auth/register-trusted-company')) {
        yield put(appError(`Redirecting to ${fallbackTarget}/`));
        setTimeout(() => {
          window.location.href = fallbackTarget + window.location.pathname;
          return;
        }, 2000);
        return;
      }

      if (domain.depth !== 3) {
        yield fallback();
        return;
      }

      if (domain.subdomain === 'admin') {
        if (!action.payload.user?.superuser) {
          yield fallback();
          return;
        }
      } else if (selectedTenant?.subdomain !== domain.subdomain) {
        yield fallback();
        return;
      }

      yield put(
        login({
          userId: action.payload.user?.userId,
          firstName: action.payload?.user?.firstName ?? '',
          lastName: action.payload?.user?.lastName ?? '',
          email: action.payload?.user?.email ?? '',
          hasAvatar: action.payload?.user?.hasAvatar,
          lastUpdate: action.payload?.user?.lastUpdate,
          language: action.payload?.user?.language ?? undefined,
          tenant: selectedTenant,
          tenants: tenants,
          permissions: action.payload?.tenantPermissions ?? undefined,
        }),
      );

      const userLanguage = action.payload.user?.language;
      if (userLanguage) {
        yield setLanguageAndLocale(userLanguage);
      }

      //TODO: hopefully remove this again when we decide against userpilot
      Userpilot.identify(action.payload.user?.userId || '', {
        email: action.payload.user?.email,
        name: `${action.payload.user?.firstName} ${action.payload.user?.lastName}`,
      });

      yield true;
    },
  );
}

function* onGetChallengeFulfilled() {
  // If there is a SSO realm configured, redirect to the challenge URL to authenticate
  yield takeEvery(
    [api.endpoints.apiGetChallenge.matchFulfilled],
    function* (action: PayloadAction<ApiGetChallengeApiResponse>) {
      const isDevMode = settings.devMode;
      if (!window.location.href.includes(ROUTES_CONFIG.SSO.path) && !window.location.href.includes('accept-invitation') && action?.payload?.challengeUrl && !isDevMode) {
        yield (window.location.href = action.payload.challengeUrl);
      } else {
        yield put(finishAppInit());
      }
    },
  );
}

function* onGetExchangeFulfilled() {
  // Exchanged SSO code for a session, now set the tokens and redirect to the returnUrl
  yield takeEvery(
    [api.endpoints.apiGetExchange.matchFulfilled],
    function* (action: PayloadAction<ApiGetExchangeApiResponse>) {
      if (action?.payload?.auth) {
        setAccessTokenCookies(action.payload.auth);
        yield put(
          setSession({
            sessionId: action.payload.auth.access_token,
            refreshToken: action.payload.auth.refresh_token,
          }),
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(api.endpoints.apiPostCheckSession.initiate(undefined, {}) as any);
      yield take([api.endpoints.apiPostCheckSession.matchFulfilled, api.endpoints.apiPostCheckSession.matchRejected]);
    },
  );
}

function* onInvitationChange() {
  yield takeEvery(
    [api.endpoints.apiPostCreateUserInvitation.matchFulfilled],
    function* (_: PayloadAction<ApiPostCreateUserInvitationApiResponse>) {
      toast.success(i18n.t('auth.notificationInvitationSent'));
      yield;
    },
  );
}

export const authSaga = function* () {
  yield fork(onUserAuthenticated);
  yield fork(onCheckSessionAuthenticated);
  yield fork(onCheckSession);
  yield fork(onGetChallengeFulfilled);
  yield fork(onGetExchangeFulfilled);
  yield fork(onGetRefreshToken);
  yield fork(onUserLoggedOut);
  yield fork(clearCacheOnLogout);
  yield fork(onInvitationChange);
};
