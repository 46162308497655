import { useTranslation } from 'react-i18next';
import { AddButton } from '@client/shared/toolkit';
import { SelectProjectReadModel } from '@client/shared/api';
import React, { useState } from 'react';
import {
  BenchmarkingEmptyColumns,
  BenchmarkingParameterHeader,
  BenchmarkingProjectHeader,
  BenchmarkingReportLayout,
  BenchmarkingRow,
  BenchmarkingSectionProps,
} from '..';
import { BENCHMARK_DRAG_TYPE } from '../BenchmarkingHeader';
import { FormattedCurrency } from '@client/project/shared';

export const BenchmarkingColumnsSection = ({
  layout,
  projects,
  removeProject,
  setIsAddWizardOpen,
  parameters,
  columns,
  removeParameter,
  rows,
  dropItem,
  columnsSectionRef
}: BenchmarkingSectionProps) => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const hoverItem = (index: number | null) => {
    setHoveredIndex(index);
  }
  return (
    <div className="w-full max-w-[calc(100%-365px)]">
      <div className="w-full flex flex-col h-full">
        <div className="overflow-x-auto flex-1 h-full">
          <div className="flex h-full">
            <div className="flex-1 h-full flex flex-col pb-3 pr-3">
              <div className="h-10 flex-none flex items-center">
                {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS ? (
                  <>
                    {parameters.length > 0 && (
                      <div className="flex">
                        {parameters.map((parameter, i) => (
                          <BenchmarkingParameterHeader
                            key={`benchmark-parameter-header-${parameter.id}`}
                            column={parameter}
                            handleDelete={() => removeParameter(parameter.id)}
                            isLast={i === columns.length - 1}
                            index={i}
                            type={BENCHMARK_DRAG_TYPE.COLUMN}
                            dropItem={dropItem}
                            isDragDisabled={false}
                            hoverItem={hoverItem}
                            isHovered={hoveredIndex === i}
                          />
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {projects && projects.length > 0 && (
                      <div className="flex">
                        {projects.map((project, i) => (
                          <BenchmarkingProjectHeader
                            key={`benchmark-project-${project.id}-${i}`}
                            project={project as SelectProjectReadModel}
                            handleDelete={() => removeProject(project.id)}
                            // at least one project needs to be selected for the report
                            isRemoveDisabled={projects.length === 1 && columns.length > 0}
                            index={i}
                            type={BENCHMARK_DRAG_TYPE.COLUMN}
                            isLast={i === projects.length - 1}
                            dropItem={dropItem}
                            isDragDisabled={false}
                            hoverItem={hoverItem}
                            isHovered={hoveredIndex === i}
                          />
                        ))}
                      </div>
                    )}
                  </>
                )}
                <div className="flex gap-3 items-center pl-1">
                  {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS ? (
                    <>
                      <AddButton
                        onClick={setIsAddWizardOpen}
                        className="shadow-lg w-8 h-8"
                        disabled={!projects?.length}
                        variant={!projects?.length ? 'gray' : undefined}
                        title={!projects?.length ? t('reporting.benchmarking.addProjectsFirst') : undefined}
                      />
                      {!columns.length && (
                        <span className="font-bold text-lg leading-none">{t('reporting.benchmarking.benchmarks')}</span>
                      )}
                    </>
                  ) : (
                    <>
                      <AddButton onClick={setIsAddWizardOpen} className="shadow-lg w-8 h-8" />
                      {!projects.length && (
                        <span className="font-bold text-lg leading-none">{t('reporting.benchmarking.projects')}</span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex-1 relative min-w-full shadow bg-white w-auto h-full" ref={columnsSectionRef}>
                {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS ? (
                  <>
                    {columns.length > 0 && rows.length > 0 ? (
                      rows.map((row) => <BenchmarkingRow cells={row.cells} key={`benchmarking-row-${row.rowId}`} />)
                    ) : (
                      <BenchmarkingEmptyColumns
                        title={t('reporting.benchmarking.noProjects')}
                        message={t('reporting.benchmarking.addColumn.message')}
                        setIsAddWizardOpen={setIsAddWizardOpen}
                        isAddDisabled={!projects?.length}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {projects && projects.length > 0 ? (
                      columns.map((column) => (
                        <BenchmarkingRow cells={column.cells} key={`benchmarking-row-${column.columnId}`} />
                      ))
                    ) : (
                      <BenchmarkingEmptyColumns
                        title={t('reporting.benchmarking.addColumn.message')}
                        message={t('reporting.benchmarking.noProjects')}
                        setIsAddWizardOpen={setIsAddWizardOpen}
                        isAddDisabled={false}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="flex-none h-14 w-auto items-center">
                {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS && columns.length > 0 && (
                  <div className="flex h-full items-center">
                    {columns.map((column, i) => (
                      <div
                        key={`column-average-${column.columnId}`}
                        className="w-[184px] h-full flex-none overflow-hidden flex items-center justify-center border-b-4 border-double mx-2 border-slate-500"
                      >
                        <span className="font-bold truncate">
                          {t('reporting.benchmarking.average')} <FormattedCurrency amount={column.average} />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
