import { WidgetConfig, WidgetDashboardTypeSizesConfig } from '../WidgetDashboard';
import { ResponsiveProps } from 'react-grid-layout';

/**
 * All existing widget dashboards
 */
export type DashboardConfigKeys = 'carestone' | 'default' | 'report';

/**
 * All existing widget types
 */
export enum DashboardWidgetType {
  // Carestone Dashboard
  'ProjectCustomFields' = 'ProjectCustomFields',
  'ProjectMetaDataAddress' = 'ProjectMetaDataAddress',
  'ProjectMetaDataGrz' = 'ProjectMetaDataGrz',
  'ProjectMetaDataCustom' = 'ProjectMetaDataCustom',
  'MaBVRate' = 'MaBVRate',
  'Assets' = 'Assets',
  'CashFlowChart' = 'CashFlowChart',
  'IsShouldChart' = 'IsShouldChart',
  'ProjectMilestones' = 'ProjectMilestones',
  'Mixed' = 'Mixed',
  // Custom Dashboard / Report Dashboard
  'BudgetStatus' = 'BudgetStatus',
  'CashOutPlan' = 'CashOutPlan',
  'FinancialControlling' = 'FinancialControlling',
  'KPIs' = 'KPIs',
  'MapView' = 'MapView',
  'ProjectInformation' = 'ProjectInformation',
  'ProjectUdfs' = 'ProjectUdfs',
  'RiskMatrix' = 'RiskMatrix',
  'RiskMitigationList' = 'RiskMitigationList',
  'Tasks' = 'Tasks',
  'TextBox' = 'TextBox',
  'Timeline' = 'Timeline',
  'TrafficLight' = 'TrafficLight',
  'ProjectClock' = 'ProjectClock',
  'Waterfall' = 'Waterfall',
  // General
  'Placeholder' = 'Placeholder',
}

export type WidgetSizeType = {
  w: number;
  h: number;
  disabled?: boolean;
};

export type WidgetsConfig = {
  [key in DashboardWidgetType]: WidgetConfig;
};

/**
 * Carestone Widgets and their config
 */
export const carestoneWidgets = {
  [DashboardWidgetType.Assets]: {
    type: DashboardWidgetType.Assets
  },
  [DashboardWidgetType.CashFlowChart]: {
    type: DashboardWidgetType.CashFlowChart
  },
  [DashboardWidgetType.IsShouldChart]: {
    type: DashboardWidgetType.IsShouldChart
  },
  [DashboardWidgetType.MaBVRate]: {
    type: DashboardWidgetType.MaBVRate
  },
  [DashboardWidgetType.ProjectCustomFields]: {
    type: DashboardWidgetType.ProjectCustomFields
  },
  [DashboardWidgetType.ProjectMetaDataAddress]: {
    type: DashboardWidgetType.ProjectMetaDataAddress
  },
  [DashboardWidgetType.ProjectMetaDataCustom]: {
    type: DashboardWidgetType.ProjectMetaDataCustom
  },
  [DashboardWidgetType.ProjectMetaDataGrz]: {
    type: DashboardWidgetType.ProjectMetaDataGrz
  },
  [DashboardWidgetType.ProjectMilestones]: {
    type: DashboardWidgetType.ProjectMilestones
  },
  [DashboardWidgetType.Mixed]: {
    type: DashboardWidgetType.Mixed
  },
}

/**
 * Custom Dashboard and Report Dashboard Widgets and their config
 */
export const customAndReportDashboardWidgets = {
  [DashboardWidgetType.BudgetStatus]: {
    type: DashboardWidgetType.BudgetStatus,
    name: 'BudgetStatus',
    title: 'dashboard.widget.budgetStatus.title',
    description: 'dashboard.widget.budgetStatus.description',
    sizes: [
      {
        w: 1,
        h: 2
      },
    ],
    defaultSize: '1x2'
  },
  [DashboardWidgetType.CashOutPlan]: {
    type: DashboardWidgetType.CashOutPlan,
    name: 'CashOutPlan',
    title: 'dashboard.widget.cashOutPlan.title',
    description: 'dashboard.widget.cashOutPlan.description',
    sizes: [
      {
        w: 2,
        h: 2,
      },
    ],
    defaultSize: '2x2'
  },
  [DashboardWidgetType.FinancialControlling]: {
    type: DashboardWidgetType.FinancialControlling,
    name: 'FinancialControlling',
    title: 'dashboard.widget.financingControlling.title',
    description: 'dashboard.widget.financingControlling.description',
    sizes: [
      {
        w: 2,
        h: 2,
      },
    ],
    defaultSize: '2x2'
  },
  [DashboardWidgetType.KPIs]: {
    type: DashboardWidgetType.KPIs,
    name: 'KPIs',
    title: 'dashboard.widget.kpis.title',
    description: 'dashboard.widget.kpis.description',
    sizes: {
      default: [
        {
          w: 1,
          h: 2
        },
        {
          w: 1,
          h: 3
        },
        {
          w: 2,
          h: 1
        }
      ],
      report: [],
      carestone: []
    } as WidgetDashboardTypeSizesConfig,
  },
  [DashboardWidgetType.MapView]: {
    type: DashboardWidgetType.MapView,
    name: 'MapView',
    title: 'dashboard.widget.mapView.title',
    description: 'dashboard.widget.mapView.description',
    sizes: [
      {
        w: 3,
        h: 3
      },
      {
        w: 1,
        h: 3
      },
    ],
    defaultSize: '3x3'
  },
  [DashboardWidgetType.ProjectInformation]: {
    type: DashboardWidgetType.ProjectInformation,
    name: 'ProjectInformation',
    title: 'dashboard.widget.projectInformation.title',
    description: 'dashboard.widget.projectInformation.description',
    sizes: [
      {
        w: 1,
        h: 2
      }
    ],
    defaultSize: '1x2'
  },
  [DashboardWidgetType.ProjectUdfs]: {
    type: DashboardWidgetType.ProjectUdfs,
    name: 'ProjectUdfs',
    title: 'dashboard.widget.projectUdfs.title',
    description: 'dashboard.widget.projectUdfs.description',
    sizes: [
      {
        w: 1,
        h: 2
      },
      {
        w: 1,
        h: 3
      }
    ]
  },
  [DashboardWidgetType.RiskMatrix]: {
    type: DashboardWidgetType.RiskMatrix,
    name: 'RiskMatrix',
    title: 'dashboard.widget.riskMatrix.title',
    description: 'dashboard.widget.riskMatrix.description',
    sizes: []
  },
  [DashboardWidgetType.RiskMitigationList]: {
    type: DashboardWidgetType.RiskMitigationList,
    name: 'RisksList',
    title: 'dashboard.widget.riskMitigationList.title',
    description: 'dashboard.widget.riskMitigationList.description',
    sizes: [
      {
        w: 1,
        h: 2
      },
      {
        w: 1,
        h: 3
      },
      {
        w: 1,
        h: 4
      }
    ],
  },
  [DashboardWidgetType.Tasks]: {
    type: DashboardWidgetType.Tasks,
    name: 'Tasks',
    title: 'dashboard.widget.tasks.title',
    description: 'dashboard.widget.tasks.description',
    sizes: [
      {
        w: 1,
        h: 2
      },
    ],
  },
  [DashboardWidgetType.TextBox]: {
    type: DashboardWidgetType.TextBox,
    name: 'TextBox',
    title: 'dashboard.widget.textBox.title',
    description: 'dashboard.widget.textBox.description',
    sizes: [
      {
        w: 1,
        h: 2,
      },
    ],
  },
  [DashboardWidgetType.Timeline]: {
    type: DashboardWidgetType.Timeline,
    name: 'TextBox',
    title: 'dashboard.widget.timeline.title',
    description: 'dashboard.widget.timeline.description',
    sizes: [],
  },
  [DashboardWidgetType.TrafficLight]: {
    type: DashboardWidgetType.TrafficLight,
    name: 'TrafficLight',
    title: 'dashboard.widget.trafficLight.title',
    description: 'dashboard.widget.trafficLight.description',
    sizes: [],
  },
  [DashboardWidgetType.ProjectClock]: {
    type: DashboardWidgetType.ProjectClock,
    name: 'ProjectClock',
    title: 'dashboard.widget.projectClock.title',
    description: 'dashboard.widget.projectClock.description',
    sizes: [
      {
        w: 1,
        h: 3
      }
    ],
    defaultSize: '1x3'
  },
  [DashboardWidgetType.Waterfall]: {
    type: DashboardWidgetType.Waterfall,
    name: 'Waterfall',
    title: 'dashboard.widget.waterfall.title',
    description: 'dashboard.widget.waterfall.description',
    sizes: [
      {
        w: 1,
        h: 2
      }
    ]
  }
}

/**
 * All widgets with their config
 */
export const widgets: WidgetsConfig = {
  //----- General ----
  [DashboardWidgetType.Placeholder]: {
    type: DashboardWidgetType.Placeholder,
    name: 'Placeholder'
  },
  //---- Carestone Dashboard ----
  ...carestoneWidgets,
  //---- Custom Dashboard / Report Dashboard ----
  ...customAndReportDashboardWidgets
};

export interface DashboardConfig extends ResponsiveProps {
  rows: number;
  rowHeights?: Record<string, number>;
  availableWidgets: WidgetConfig[];
  classNames?: string;
}

export type DashboardConfigs = {
  [key in DashboardConfigKeys]: DashboardConfig;
};

/**
 * Different dashboard configs
 */
export const dashboardConfig: DashboardConfigs = {
  carestone: {
    breakpoints: { xl: 1900, lg: 1200, md: 996, sm: 768, xs: 480 },
    cols: { xl: 6, lg: 4, md: 4, sm: 2, xs: 1, xss: 1 },
    rows: 3,
    availableWidgets: [],
    margin: [15, 15],
    containerPadding: [0, 0],
    classNames: 'max-w-[2000px] mx-auto',
    rowHeight: 70,
  },
  default: {
    cols: { lg: 5, md: 3, sm: 2, xs: 1, xxs: 1},
    rows: 3,
    availableWidgets: [
      widgets[DashboardWidgetType.CashOutPlan],
      widgets[DashboardWidgetType.FinancialControlling],
      widgets[DashboardWidgetType.KPIs],
      widgets[DashboardWidgetType.MapView],
      widgets[DashboardWidgetType.ProjectUdfs],
    ],
    margin: [40, 40],
    containerPadding: [0, 0],
    classNames: 'max-w-[1800px] mx-auto',
    rowHeights: {
      lg: 180,
      md: 270,
      sm: 350,
      xs: 350,
      xxs: 350,
    },
    maxRows: 3,
  },
  report: {
    cols: { lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 },
    rows: 4,
    availableWidgets: [
      widgets[DashboardWidgetType.BudgetStatus],
      widgets[DashboardWidgetType.CashOutPlan],
      widgets[DashboardWidgetType.KPIs],
      widgets[DashboardWidgetType.ProjectInformation],
      // widgets[DashboardWidgetType.RiskMatrix],
      widgets[DashboardWidgetType.RiskMitigationList],
      widgets[DashboardWidgetType.Tasks],
      widgets[DashboardWidgetType.TextBox],
      widgets[DashboardWidgetType.TrafficLight],
      widgets[DashboardWidgetType.ProjectClock],
      widgets[DashboardWidgetType.Waterfall]
    ],
    classNames: 'mt-6',
    rowHeight: 180,
    maxRows: 4,
  },
};
