import {
  BaseSelect,
  BaseSelectOption,
  BooleanInput,
  ComboSelect,
  ComboSelectOption,
  CURRENCIES,
  CurrencyExchangeIcon,
  Form,
  FormField,
  LoadingIndicator,
  PaidSearchIcon,
  TotalSalesIcon,
  WizardSlideContent,
  WizardSlideHeader,
  WorldwideLocationIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import {
  CostCatalogElementReadModel,
  Currency,
  EarningsCatalogElementReadModel,
  RiskCatalogElementReadModel,
  useApiGetCatalogQuery,
  useApiGetCatalogsQuery,
  useApiGetEarningsCatalogQuery,
  useApiGetEarningsCatalogsQuery,
  useApiGetRiskCatalogQuery,
  useApiGetRiskCatalogsQuery,
  useApiPostCreateBenchmarkReportColumnMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';
import { BenchmarkingColumnType } from './BenchmarkAddParameterWizard';

export const MultiReportBenchmarkColumnFormValidationSchema = yup.object({
  type: yup.mixed<BenchmarkingColumnType>().required('validation.required'),
  catalogId: yup.string().required('validation.required'),
  catalogElementId: yup.string().required('validation.required'),
  regionalFactor: yup.boolean().default(false).required('validation.required'),
  indexation: yup.boolean().default(false).required('validation.required'),
  currency: yup.mixed<Currency>().oneOf(CURRENCIES).default('Eur').required('validation.required'),
});

export type MultiReportBenchmarkColumnFormValidationValues = InferType<
  typeof MultiReportBenchmarkColumnFormValidationSchema
>;

export const BenchmarkAddParameterForm = ({
  formRef,
  configId,
  setIsCreateDisabled,
  onClose,
  type,
  orderNumber
}: {
  formRef: RefObject<HTMLFormElement>;
  configId: string;
  setIsCreateDisabled: (isDisabled: boolean) => void;
  onClose: () => void;
  type: BenchmarkingColumnType;
  orderNumber: number;
}) => {
  const { t } = useTranslation();

  const [catalogId, setCatalogId] = useState('');
  const [catalogElementId, setCostCatalogElementId] = useState<string | null>(null);

  const [saveColumn, { isLoading: isSaving }] = useApiPostCreateBenchmarkReportColumnMutation();

  const { data: costCatalogs, isFetching: isFetchingCatalogs } = useApiGetCatalogsQuery(undefined, {
    skip: type !== 'costs',
  });

  const { data: riskCatalogs, isFetching: isFetchingRisksCatalogs } = useApiGetRiskCatalogsQuery(undefined, {
    skip: type !== 'risks',
  });

  const { data: earningCatalogs, isFetching: isFetchingEarningsCatalogs } = useApiGetEarningsCatalogsQuery(undefined, {
    skip: type !== 'rent' && type !== 'sales' && type !== 'additions',
  });

  const { data: costCatalogElements, isFetching: isFetchingCatalog } = useApiGetCatalogQuery(
    { id: catalogId },
    { skip: !catalogId || type !== 'costs' },
  );

  const { data: riskCatalogElements, isFetching: isFetchingRisksCatalog } = useApiGetRiskCatalogQuery(
    { id: catalogId },
    { skip: !catalogId || type !== 'risks' },
  );

  const { data: earningCatalogElements, isFetching: isFetchingEarningsCatalog } = useApiGetEarningsCatalogQuery(
    { id: catalogId },
    { skip: !catalogId || type === 'costs' || type === 'risks' },
  );

  const currencyOptions = useMemo(() => {
    return [
      { label: 'Euro (default)', value: 'Eur' },
      { label: 'US Dollar', value: 'Usd' },
      { label: 'British Pound', value: 'Gbp' },
    ];
  }, []);

  const defaultFormValues = useMemo(() => {
    return {
      type: type,
      catalogId: '',
      catalogElementId: '',
      regionalFactor: false,
      indexation: false,
      currency: 'Eur' as Currency,
    };
  }, [type]);

  useEffect(() => {
    formRef?.current?.resetForm(defaultFormValues);
  }, [formRef, defaultFormValues]);

  const handleOnSubmit = async (data: MultiReportBenchmarkColumnFormValidationValues) => {
    try {
      await safeMutation(
        saveColumn,
        {
          reportConfigurationId: configId,
          body: {
            orderNumber: orderNumber,
            currency: data.currency,
            applyIncrease: data.indexation,
            applyRegionalFactor: data.regionalFactor,
            costCatalogElementId: type === 'costs' ? data.catalogElementId : null,
            earningsCatalogElementId:
              type === 'rent' || type === 'sales' || type === 'additions' ? data.catalogElementId : null,
            riskCatalogElementId: type === 'risks' ? data.catalogElementId : null,
          },
        },
        isSaving,
      );
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const catalogOptions: BaseSelectOption[] = useMemo(() => {
    const selectedCatalog = costCatalogs?.catalogs ?? riskCatalogs?.riskCatalogs ?? earningCatalogs?.earningsCatalogs;
    if (selectedCatalog) {
      const options = selectedCatalog.map((catalog) => {
        return {
          label: catalog.name,
          value: catalog.id,
        };
      });

      return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }
    return [];
  }, [costCatalogs?.catalogs, riskCatalogs?.riskCatalogs, earningCatalogs?.earningsCatalogs]);

  useEffect(() => {
    setIsCreateDisabled(!catalogElementId);
  }, [setIsCreateDisabled, catalogElementId]);

  const catalogElementOptions: ComboSelectOption[] = useMemo(() => {
    let catalogElements = costCatalogElements?.costCatalog.elements ?? riskCatalogElements?.riskCatalog.elements;

    if (type === 'rent' || type === 'sales' || type === 'additions') {
      catalogElements = earningCatalogElements?.earningsCatalog.elements.filter(
        (elem: EarningsCatalogElementReadModel) => {
          if (type === 'additions') {
            // service
            return elem.earningsType === 'OperatingRevenue' || elem.earningsType === 'ServiceRevenue';
          } else if (type === 'sales') {
            // purchase
            return elem.earningsType === 'SaleRevenue';
          } else if (type === 'rent') {
            // rent
            return elem.earningsType === 'RentRevenue';
          }
          return false;
        },
      );
    }

    if (catalogId && catalogElements) {
      const getChildOptions = (
        children: CostCatalogElementReadModel[] | RiskCatalogElementReadModel[] | EarningsCatalogElementReadModel[],
      ): ComboSelectOption[] => {
        return children
          .map((child) => {
            return {
              label: `${child.code} ${child.description}`,
              value: child.id,
              options: getChildOptions(child.children),
            };
          })
          .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      };

      return catalogElements
        .filter((element) => !element.parentId)
        .map((element) => {
          return {
            label: `${element.code} ${element.description}`,
            value: element.id,
            options: element.children ? getChildOptions(element.children) : undefined,
          };
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }
    return [];
  }, [
    type,
    catalogId,
    costCatalogElements?.costCatalog.elements,
    riskCatalogElements?.riskCatalog.elements,
    earningCatalogElements?.earningsCatalog.elements,
  ]);

  const isLoading = useMemo(() => {
    return (
      isSaving ||
      isFetchingCatalogs ||
      isFetchingCatalog ||
      isFetchingRisksCatalog ||
      isFetchingRisksCatalogs ||
      isFetchingEarningsCatalogs ||
      isFetchingEarningsCatalog
    );
  }, [
    isSaving,
    isFetchingCatalog,
    isFetchingRisksCatalog,
    isFetchingEarningsCatalog,
    isFetchingCatalogs,
    isFetchingRisksCatalogs,
    isFetchingEarningsCatalogs,
  ]);

  const wizardTitles = useMemo(() => {
    switch (type) {
      case 'costs':
        return {
          title: t('reporting.benchmarking.parameter.constructionCosts.title'),
          subtitle: t('reporting.benchmarking.parameter.constructionCosts.description'),
        };
      case 'risks':
        return {
          title: t('reporting.benchmarking.parameter.risks.title'),
          subtitle: t('reporting.benchmarking.parameter.risks.description'),
        };
      case 'sales':
        return {
          title: t('reporting.benchmarking.parameter.sales.title'),
          subtitle: t('reporting.benchmarking.parameter.sales.description'),
        };
      case 'rent':
        return {
          title: t('reporting.benchmarking.parameter.rent.title'),
          subtitle: t('reporting.benchmarking.parameter.rent.description'),
        };
      case 'additions':
        return {
          title: t('reporting.benchmarking.parameter.additions.title'),
          subtitle: t('reporting.benchmarking.parameter.additions.description'),
        };
      default:
        return {
          title: type,
          subtitle: '',
        };
    }
  }, [t, type]);

  return (
    <>
      <WizardSlideHeader title={wizardTitles.title} subTitle={wizardTitles.subtitle} />
      <WizardSlideContent>
        <div className="h-full relative">
          {isLoading && <LoadingIndicator mode="overlay" />}
          <Form<MultiReportBenchmarkColumnFormValidationValues>
            validationSchema={MultiReportBenchmarkColumnFormValidationSchema}
            defaultValues={defaultFormValues}
            onSubmit={handleOnSubmit}
            ref={formRef}
            className="h-full"
          >
            <FormField name="catalogId">
              {(control) => (
                <BaseSelect
                  label={t('reporting.benchmarking.parameter.columnForm.catalog')}
                  icon={<Bars3CenterLeftIcon />}
                  options={catalogOptions}
                  {...control}
                  onChange={(val) => {
                    setCatalogId(val);
                    control.onChange(val);
                  }}
                />
              )}
            </FormField>
            <FormField name="catalogElementId">
              {(control) => (
                <ComboSelect
                  label={t('reporting.benchmarking.parameter.columnForm.element')}
                  icon={<PaidSearchIcon className="h-6 w-6" />}
                  options={catalogElementOptions}
                  disabled={!catalogId}
                  pageOptions
                  {...control}
                  onChange={(val) => {
                    setCostCatalogElementId(val);
                    control.onChange(val);
                  }}
                />
              )}
            </FormField>
            <FormField name="regionalFactor">
              {(control) => (
                <BooleanInput
                  icon={<WorldwideLocationIcon />}
                  label={t('reporting.benchmarking.parameter.constructionCosts.regionalFactor')}
                  variant="switch"
                  subLabel={t('reporting.benchmarking.parameter.constructionCosts.adjustment')}
                  {...control}
                />
              )}
            </FormField>
            {(type === 'costs' || type === 'rent') && (
              <FormField name="indexation">
                {(control) => (
                  <BooleanInput
                    icon={<TotalSalesIcon />}
                    label={t('reporting.benchmarking.parameter.constructionCosts.indexation')}
                    variant="switch"
                    subLabel={t('reporting.benchmarking.parameter.constructionCosts.adjustment')}
                    {...control}
                  />
                )}
              </FormField>
            )}
            <FormField name="currency">
              {(control) => (
                <BaseSelect
                  icon={<CurrencyExchangeIcon className="h-6 w-6" />}
                  label={t('projectSettings.benchmarking.currencyConversion')}
                  options={currencyOptions}
                  {...control}
                />
              )}
            </FormField>
          </Form>
        </div>
      </WizardSlideContent>
    </>
  );
};
