import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DistributionType,
  EarningsElementReadModel,
  EarningsElementValuation,
  EarningsType,
  ElementTimelineReadModel,
  ElementUserDefinedFieldDefinitionReadModel,
  FormulaPayload,
  InflationElementReadModel,
  ModifyEarningsElementPayload,
  PaymentFrequency,
  UpdateInflationElementPayload,
  useApiGetCalculationModelDeliveryPhasesQuery,
  useApiGetCalculationModelEarningsQuery,
  useApiGetCalculationModelQuery,
  useApiGetEarningsElementQuery,
  useApiGetProjectWritableEarningsCatalogQuery,
  useApiPostCreateEarningsElementMutation,
  useApiPostEarningsElementValuationMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiPostUpdateEarningsElementMutation,
  UserDefinedFieldPayload
} from '@client/shared/api';
import {
  BaseSelect,
  BaseSelectOption,
  BooleanInput,
  Button,
  DateFromIcon,
  DatePicker,
  LoadingIndicator,
  Modal,
  NumberInput,
  PositiveDynamicIcon,
  ScatterPlotIcon,
  SlideOver,
  SlideOverOnCloseProps,
  TearOffCalenderIcon,
  EditNodeIcon,
  DepositIcon,
  SlideOverTitle,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import { EarningsElementDeleteModal } from './EarningsElementDeleteModal';
import { useTranslation } from 'react-i18next';
import { getFlattenedDecoratedElements, getUnitLabel, getAllDistributionTypes } from '../utils';
import {
  safeMutation,
  formatDate,
  formatNumber,
  getLanguageAndLocale,
  formatDateOnly,
  formatNullableDateOnly,
} from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedProjectUnitSystem, useLoadedVariantId } from '@client/project/store';
import { DecoratedElement, EarningElement, useEarnings, useElementErrors } from '../hooks';
import { useFeatureFlags } from '@client/shared/store';
import { useValidateCanWriteCatalogElement, useValidateProjectPermission } from '@client/shared/permissions';
import { EarningsFormulaInput } from './EarningsFormulaInput';
import EarningElementMetadata from './EarningElementMetadata';
import DisplayErrors from './DisplayErrors';
import { CalculateElementDocuments } from './Documents';
import { SlideOverTotalSection } from './SlideOverTotalSection';
import { FormattedCurrency, timelineReadModelToPayload, TimeLineDistribution, EditUserDefinedFields } from '@client/project/shared';
import { FeatureElement, FeatureElementEdit } from './FeatureElement';

interface EarningsElementSlideOverProps extends SlideOverOnCloseProps {
  variantId?: string;
  disabled?: boolean;
  decoratedElement?: DecoratedElement<EarningElement>;
  earningsCatalogId?: string;
  preselectedParent?: PreselectedEarningsParent;
}

type NestedModal = 'None' | 'Delete' | 'UserDefinedFields';

type SubArea = 'Values' | 'Documents' | 'History' | 'Comments';

const CalculateMultiple = ({ multiplier }: { multiplier: number | null }) => {
  const { t } = useTranslation();
  if (!multiplier) return null;

  return (
    <>
      <div className="mx-8 text-xs text-gray-600">{t('projectCalculate.earningsElementLabelCalculatedMultiple')}</div>
      <div className="mx-8 text-gray-600 text-sm font-bold">
        Ø{formatNumber(multiplier, { maxDigits: 2, minDigits: 2 })}x
      </div>
    </>
  );
};

const RentRelevantForValuation = ({
  relevantForValuation,
  elementValuation,
  isUpdatingValueCalculation,
}: {
  relevantForValuation: boolean;
  elementValuation: EarningsElementValuation | null;
  isUpdatingValueCalculation: boolean;
}) => {
  const { t } = useTranslation();
  if (!relevantForValuation || !elementValuation) return null;

  return (
    <div className="relative">
      {isUpdatingValueCalculation && <LoadingIndicator mode="overlay" />}

      <div className="mt-2 border-t grid grid-cols-3 text-right mx-8 px-2 pb-2">
        <div>
          <div className="text-xs text-gray-600">{t('projectCalculate.earningsElementLabelIndexedRent')}</div>

          <div className="text-sm font-bold">
            <FormattedCurrency amount={elementValuation.indexedRent} options={{ maxDigits: 2, minDigits: 2 }} />
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-600">{t('projectCalculate.earningsElementLabelMonthlyRent')}</div>
          <div className="text-sm font-bold">
            <FormattedCurrency amount={elementValuation.monthlyRent} />
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-600">{t('projectCalculate.earningsElementLabelYearlyRent')}</div>
          <div className="text-sm font-bold">
            <FormattedCurrency amount={elementValuation.yearlyRent} />
          </div>
        </div>
      </div>
      <div className="px-2 border-t-4 border-double text-right mr-8 ml-8 text-sm text-gray-600 pt-2">
        {`${t('projectCalculate.earningsElementLabelTo')} ${formatDate(elementValuation.calculationDate)}`}
      </div>
      <div className="mt-2 px-2 text-xl font-bold text-right mr-8 text-sky-900">
        <FormattedCurrency amount={elementValuation.valuation} />
      </div>
    </div>
  );
};

export type PreselectedEarningsParent = {
  preselectedGroupId?: string;
  preselectedGroupType?: string;
};
export const EarningsElementSlideOver = ({
  disabled,
  onClose,
  variantId,
  decoratedElement,
  earningsCatalogId,
  preselectedParent,
}: EarningsElementSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { fakeUi: showFakeUi } = useFeatureFlags();

  const currentLanguage = getLanguageAndLocale().language;

  const projectId = useLoadedProjectId();

  const [subarea, setSubarea] = useState<SubArea>('Values');

  const catalogElementId = decoratedElement?.element.group
    ? decoratedElement?.element.group?.groupId
    : decoratedElement?.element.earningElement?.earningsCatalogElementId;
  const canDeleteEarnings = useValidateProjectPermission(['EARNINGS_DELETE'], projectId ?? '');
  const readOnly = !useValidateCanWriteCatalogElement(projectId ?? '', earningsCatalogId, catalogElementId) || disabled;

  const earningsTypes = useMemo(
    () => [
      {
        label: t('projectCalculate.earningsTypes.SaleRevenue'),
        value: 'SaleRevenue',
      },
      {
        label: t('projectCalculate.earningsTypes.RentRevenue'),
        value: 'RentRevenue',
      },
      {
        label: t('projectCalculate.earningsTypes.OperatingRevenue'),
        value: 'OperatingRevenue',
      },
      {
        label: t('projectCalculate.earningsTypes.ServiceRevenue'),
        value: 'ServiceRevenue',
      },
    ],
    [t],
  );

  const paymentFrequencies = useMemo(
    () => [
      {
        label: t('projectCalculate.paymentFrequency.Monthly'),
        value: 'Monthly',
      },
      {
        label: t('projectCalculate.paymentFrequency.Quarterly'),
        value: 'Quarterly',
      },
      {
        label: t('projectCalculate.paymentFrequency.Yearly'),
        value: 'Yearly',
      },
    ],
    [t],
  );

  const loadedVariantId = useLoadedVariantId();
  const projectUnitSystem = useLoadedProjectUnitSystem();

  const calculationState = decoratedElement?.element.group?.calculationState;
  const timelineErrors = decoratedElement?.element.errors;

  const translatedErrors = useElementErrors(calculationState, timelineErrors ?? [], []);

  const { data: variantData, isFetching: isFetchingCalculationModel } = useApiGetCalculationModelQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  const { data: earningsData, isFetching: isFetchingCalculationModelEarnings } = useApiGetCalculationModelEarningsQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  const elementId = useMemo(
    () =>
      decoratedElement?.element.group?.earningsElementId ?? decoratedElement?.element.earningElement?.id ?? undefined,
    [decoratedElement],
  );

  const isEditing = !!elementId;
  const { data: dataEarningsElement, isFetching: isFetchingEarningsElement } = useApiGetEarningsElementQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
      id: elementId ?? '',
    },
    { skip: !isEditing || !elementId },
  );

  const { data: deliveryPhases, isFetching: isFetchingDeliveryPhases } = useApiGetCalculationModelDeliveryPhasesQuery({
    projectId: projectId ?? 'unset',
    calculationModelId: variantId ?? 'unset',
  });

  const [selectedCustomFields, setSelectedCustomFields] = useState<ElementUserDefinedFieldDefinitionReadModel[] | null>(
    null,
  );
  const [userDefinedFieldsPayload, setUserDefinedFieldsPayload] = useState<UserDefinedFieldPayload[] | undefined>();

  // for custom fields valid check
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true);

  const [isInit, setIsInit] = useState(false);
  const [nestedModal, setNestedModal] = useState<NestedModal>('None');
  const [code, setCode] = useState(decoratedElement?.element.group?.code ?? decoratedElement?.element.earningElement?.code ?? '');

  const [description, setDescription] = useState(decoratedElement?.element.group?.description ?? dataEarningsElement?.readModel?.description ?? '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unit, setUnit] = useState('piece');
  const [effectiveUnitPrice, setEffectiveUnitPrice] = useState<number | null>(null);
  const [effectiveAmount, setEffectiveAmount] = useState<number | null>(null);

  const [earningsType, setEarningsType] = useState(preselectedParent?.preselectedGroupType ?? 'SaleRevenue');
  const [paymentFrequency, setPaymentFrequency] = useState('Monthly');

  const [isLoadingFormula, setIsLoadingFormula] = useState(false);

  const [contractStartDate, setContractStartDate] = useState<Date | undefined>(undefined);
  const [contractEndDate, setContractEndDate] = useState<Date | undefined>(undefined);
  const [calculationDate, setCalculationDate] = useState<Date>(new Date());
  const [rentIndex, setRentIndex] = useState<number | null>(null);
  const [rentIndexDate, setRentIndexDate] = useState<Date | undefined>(undefined);
  const [relevantForValuation, setRelevantForValuation] = useState<boolean>(false);
  const [multiple, setMultiple] = useState(25);
  const [calculatedMultiple, setCalculatedMultiple] = useState<number | null>(null);
  const [elementValuation, setElementValuation] = useState<EarningsElementValuation | null>(null);

  const [paymentTimeline, setPaymentTimeline] = useState<ElementTimelineReadModel | null>(null);
  const [formulaResult, setFormulaResult] = useState(dataEarningsElement?.readModel);

  /* Feature element */
  const [inflationElement, setInflationElement] = useState<InflationElementReadModel | null>(null);
  const [isUpdatingFeatureElement, setIsUpdatingFeatureElement] = useState(false);

  const [isGroup, setIsGroup] = useState(decoratedElement?.element.group !== undefined);

  const earningElementsFiltered = useEarnings(earningsData?.payload?.earnings ?? []);
  const earningElementsFlattened = useMemo(
    () => getFlattenedDecoratedElements<EarningElement>(earningElementsFiltered),
    [earningElementsFiltered],
  );
  const [selectedCatalogElementId, setSelectedCatalogElementId] = useState<string | null>(
    preselectedParent?.preselectedGroupId ?? decoratedElement?.element.group?.groupId ?? null,
  );
  const [selectedCatalogElement, setSelectedCatalogElement] = useState<DecoratedElement<EarningElement> | null>(null);

  const defaultEarningsElement: EarningsElementReadModel = useMemo(
    () => ({
      id: '',
      earningsCatalogElementId: null,
      tenantId: '',
      isGroup: false,
      code: '',
      description: t('projectCalculate.rowMenu.newEarningElementTitle'),
      amount: { type: 'Static', staticValue: { unit: 'piece', value: null } },
      unitPrice: { type: 'Static', staticValue: null },
      totalValue: 0,
      unit: 'piece',
      calculationDate: '',
      earningsType: earningsType as EarningsType,
      isRelevantForValuation: false,
      documents: [],
      paymentFrequency: paymentFrequency as PaymentFrequency,
      isInflationElement: false
    }),
    [earningsType, paymentFrequency, t],
  );

  const hasCatalog = !!earningsCatalogId;

  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();
  const [updateElement, { isLoading: isUpdating }] = useApiPostUpdateEarningsElementMutation();
  const [createElement, { isLoading: isCreating }] = useApiPostCreateEarningsElementMutation();
  const [updateValueCalculationModel, { isLoading: isUpdatingValueCalculation }] =
    useApiPostEarningsElementValuationMutation();

  const { data: earningsCatalog, isLoading: isLoadingEarningsCatalog } = useApiGetProjectWritableEarningsCatalogQuery(
    {
      projectId: projectId ?? '',
      id: earningsCatalogId ?? 'unset',
    },
    {
      skip: !earningsCatalogId,
    },
  );

  const isSaleRevenue = useMemo(() => {
    return earningsType === 'SaleRevenue';
  }, [earningsType]);

  const disableDistributionTypes: DistributionType[] = useMemo(() => {
    if (isSaleRevenue)
      return ['Effective'];

    return getAllDistributionTypes().filter(type => type !== 'Constant' && type !== 'None');
  }, [isSaleRevenue]);

  const handleEarningElementChange = (
    value: EarningsElementReadModel,
    effectiveAmount: number,
    effectiveUnitPrice: number,
  ) => {
    setFormulaResult(value);
    setUnit(value.amount?.staticValue?.unit ?? '');
    setEarningsElementTotal(value.totalValue);
    setEffectiveAmount(effectiveAmount);
    setEffectiveUnitPrice(effectiveUnitPrice);
  };

  const catalogOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    for (const catalogOption of earningsCatalog?.elements ?? []) {
      options.push({
        label: (catalogOption.code || catalogOption.description) ? `${catalogOption.code} ${catalogOption.description}` : t('projectCalculate.costElementNoLabel'),
        value: catalogOption.id ?? 'no-id',
      });
    }

    return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [earningsCatalog?.elements, t]);

  // TODO: Enforce those units in formula
  const unitOptions: BaseSelectOption[] = useMemo(() => {
    const metricEarningsUnits = ['m²', 'piece'];
    const imperialEarningsUnits = ['ft²', 'piece'];
    const earningsUnits = projectUnitSystem === 'Metric' ? metricEarningsUnits : imperialEarningsUnits;
    return earningsUnits.map((unit) => ({
      label: getUnitLabel(unit),
      value: unit,
    }));
  }, [projectUnitSystem]);

  useEffect(() => {
    if (effectiveAmount !== null && effectiveUnitPrice !== null && !isUpdatingValueCalculation && isInit) {
      handleUpdateValueCalculation(effectiveAmount ?? 0, effectiveUnitPrice ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formulaResult?.totalValue,
    effectiveAmount,
    effectiveUnitPrice,
    calculationDate,
    rentIndex,
    rentIndexDate,
    relevantForValuation,
    multiple,
    contractStartDate,
    contractEndDate,
    paymentFrequency,
  ]);

  const getFormulaRequestBody = (arg: 'amount' | 'unitPrice'): FormulaPayload => {
    const formulaPayload: FormulaPayload = {
      expression: formulaResult?.[arg]?.formulaValue?.expression ?? '',
      expressionParameterPayloads:
        formulaResult?.[arg]?.formulaValue?.expressionParameters?.map((p) => ({
          position: p.position,
          taxonomyElementId: p?.taxonomyItem?.id,
          costCatalogElementId: p?.costCatalogElement?.id,
          earningsCatalogElementId: p?.earningsCatalogElement?.id,
          plotId: p?.plot?.id,
        })) ?? [],
      catalogElementId: selectedCatalogElementId ?? elementId ?? null,
      elementType: 'EarningsElement',
    };
    return formulaPayload;
  };

  const handleUpdateValueCalculation = async (amount: number, unitPrice: number) => {
    if (earningsType !== 'SaleRevenue') {
      try {
        const result = await safeMutation(
          updateValueCalculationModel,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            body: {
              // TODO: use effective?
              amount: amount, //Number(formulaResult?.totalValue),
              rent: unitPrice, // Number(unitPrice),
              calculationDate: formatDateOnly(calculationDate),
              index: rentIndex,
              rentIndexDate: formatNullableDateOnly(rentIndexDate),
              relevantForValuation,
              multiple: multiple ?? selectedCatalogElement?.element.group?.multiple ?? 25,
              rentStart: formatNullableDateOnly(contractStartDate),
              rentEnd: formatNullableDateOnly(contractEndDate),
              paymentFrequency: paymentFrequency as PaymentFrequency,
              distributionFrequency: paymentTimeline?.distribution?.frequency,
              isFeatureElement: isFeatureElement,
            },
          },
          isUpdatingValueCalculation,
        );
        if (result && result.valuation) {
          setElementValuation(result.valuation);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getInflationElementPayload = (inflationElement: InflationElementReadModel | null): UpdateInflationElementPayload | null => {
    if (isInflationElement && inflationElement) {
      return {
        inflationRate: inflationElement.inflationRate,
        startDate: inflationElement.startDate,
        groupIds: inflationElement.groupIds ?? [],
        userDefinedFieldId: inflationElement.userDefinedFieldId,
        userDefinedFieldLabelIds: inflationElement.userDefinedFieldLabelIds,
      };
    }
    return null;
  }

  const getRequestPayload = () => {
    return {
      code: code,
      description: description,
      amount: {
        type: formulaResult?.amount?.type ?? 'Static',
        staticFactor:
          formulaResult?.amount?.type === 'Static'
            ? {
              value: formulaResult?.amount?.staticValue?.value ?? 0,
              unit: formulaResult?.amount.staticValue?.unit ?? '', // TODO: maybe nullable?
            }
            : undefined,
        formula: formulaResult?.amount?.type === 'Formula' ? getFormulaRequestBody('amount') : undefined,
      },
      unitPrice: {
        type: formulaResult?.unitPrice?.type ?? 'Static',
        staticFactor:
          formulaResult?.unitPrice?.type === 'Static'
            ? {
              value: formulaResult?.unitPrice?.staticValue?.value ?? 0,
              unit: formulaResult?.unitPrice?.staticValue?.unit ?? 'lumpsum', // TODO: maybe nullable?
            }
            : null,
        formula: formulaResult?.unitPrice?.type === 'Formula' ? getFormulaRequestBody('unitPrice') : undefined,
      },
      type: earningsType as EarningsType,
      paymentFrequency: paymentFrequency as PaymentFrequency,
      paymentTimeline: paymentTimeline === null || paymentTimeline?.distribution === null
        ? null
        : timelineReadModelToPayload(paymentTimeline ?? null),
      earningsCatalogElementId: hasCatalog ? selectedCatalogElementId : undefined,
      isGroup: isGroup,
      rentIndex: rentIndex,
      rentIndexDate: formatNullableDateOnly(rentIndexDate) ?? null,
      calculationDate: formatDateOnly(calculationDate),
      relevantForValuation: relevantForValuation,
      multiple: multiple ?? selectedCatalogElement?.element.group?.multiple ?? 25,
      userDefinedFieldsPayload: userDefinedFieldsPayload?.length ? userDefinedFieldsPayload : undefined,
      inflationElementPayload: getInflationElementPayload(inflationElement),
    } as ModifyEarningsElementPayload;
  };

  const handleUpdate = async () => {
    if (elementId && customFieldsAreValid) {
      try {
        await safeMutation(
          updateElement,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            id: elementId,
            body: getRequestPayload(),
          },
          isUpdating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleCreate = async () => {
    if (customFieldsAreValid) {
      try {
        await safeMutation(
          createElement,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            body: getRequestPayload(),
          },
          isCreating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // use earning type from catalog for new earning elements
  useEffect(() => {
    if (!isEditing && hasCatalog && selectedCatalogElementId && earningsCatalog?.elements) {
      const newType = earningsCatalog?.elements.find((e) => e.id === selectedCatalogElementId)?.earningsType;
      if (newType) {
        setEarningsType(newType);
      }
    }
  }, [earningsCatalog?.elements, hasCatalog, isEditing, selectedCatalogElementId]);

  useEffect(() => {
    const getNextContractCode = async () => {
      if (projectId && loadedVariantId) {
        const isGettingNextCode = false;
        try {
          const nextCodeResponse = await safeMutation(
            getNextCode,
            {
              projectId: projectId,
              calculationModelId: loadedVariantId,
              body: {
                ownerObjectId: preselectedParent?.preselectedGroupId,
                projectObjectType: 'EarningsElements'
              }
            },
            isGettingNextCode,
          );
          if (typeof nextCodeResponse !== 'undefined') {
            setCode(nextCodeResponse.code);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (isEditing) {
      if (dataEarningsElement?.readModel) {
        setCode(dataEarningsElement?.readModel?.code ?? '');
        setDescription(dataEarningsElement?.readModel?.description ?? '');
        setFormulaResult(dataEarningsElement?.readModel);
        setUnit(dataEarningsElement?.readModel?.unit ?? '');
        setSelectedCatalogElementId(dataEarningsElement?.readModel.earningsCatalogElementId ?? '');
        setIsGroup(dataEarningsElement?.readModel.isGroup ?? false);
        setEarningsType(dataEarningsElement?.readModel?.earningsType?.toString() ?? '');
        setCalculationDate(
          dataEarningsElement?.readModel?.calculationDate
            ? new Date(dataEarningsElement.readModel.calculationDate)
            : new Date(),
        );
        setRentIndexDate(
          dataEarningsElement?.readModel?.rentIndexDate
            ? new Date(dataEarningsElement.readModel.rentIndexDate)
            : undefined,
        );
        setRentIndex(dataEarningsElement?.readModel.rentIndex ?? null);
        setMultiple(dataEarningsElement?.readModel.multiple ?? 25);
        setRelevantForValuation(dataEarningsElement?.readModel.isRelevantForValuation ?? false);
        setElementValuation(dataEarningsElement?.readModel.elementValuation ?? null);
        setCalculatedMultiple(dataEarningsElement?.readModel?.calculatedMultiple ?? null);
        const amount = getEffectiveAmount();
        if (amount && effectiveAmount !== amount) {
          setEffectiveAmount(amount);
        }
        const unitPrice = getEffectiveUnitPrice();
        if (unitPrice && effectiveUnitPrice !== unitPrice) {
          setEffectiveUnitPrice(unitPrice);
        }
        let canSetTimeline = false;

        // hotfix for setting timeline when data there is no earning element on group (should be implemented on backend)
        if (dataEarningsElement?.readModel.amount !== undefined) {
          canSetTimeline = true;
        }

        if (canSetTimeline) {
          setContractStartDate(
            dataEarningsElement?.readModel?.paymentTimeline?.effectiveStartDate
              ? new Date(dataEarningsElement?.readModel?.paymentTimeline?.effectiveStartDate)
              : undefined,
          );
          setContractEndDate(
            dataEarningsElement?.readModel?.paymentTimeline?.effectiveEndDate
              ? new Date(dataEarningsElement?.readModel?.paymentTimeline?.effectiveEndDate)
              : undefined,
          );
          setPaymentFrequency(
            dataEarningsElement?.readModel?.paymentFrequency?.toString() ?? 'Monthly',
          );
          setPaymentTimeline(dataEarningsElement?.readModel.paymentTimeline ?? null);
        }

        setInflationElement(dataEarningsElement?.readModel?.inflationElement ?? null);

        setIsInit(true);
      }
    } else {
      if (!isGroup) {
        getNextContractCode();
        setDescription(defaultEarningsElement.description ?? '');
      }

      setIsInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEarningsElement, deliveryPhases?.deliveryPhases, isEditing, defaultEarningsElement.description, getNextCode, isGroup, projectId, loadedVariantId, preselectedParent]);

  const getEffectiveAmount = () => {
    if (effectiveAmount) {
      return effectiveAmount;
    }

    let amount = 0;

    switch (dataEarningsElement?.readModel.amount.type) {
      case 'Static':
        amount = dataEarningsElement?.readModel.amount.staticValue?.value ?? 0;
        break;
      case 'Formula':
        amount = dataEarningsElement?.readModel.amount.formulaValue?.value ?? 0;
        break;
    }

    return amount;
  };

  const getEffectiveUnitPrice = () => {
    if (effectiveUnitPrice) {
      return effectiveUnitPrice;
    }

    let unitPrice = 0;
    switch (dataEarningsElement?.readModel.unitPrice.type) {
      case 'Static':
        unitPrice = dataEarningsElement?.readModel.unitPrice.staticValue?.value ?? 0;
        break;
      case 'Formula':
        unitPrice = dataEarningsElement?.readModel.unitPrice.formulaValue?.value ?? 0;
        break;
    }

    return unitPrice;
  };

  const updateSelectedCatalogElement = (id: string | null) => {
    setSelectedCatalogElementId(id);
    setSelectedCatalogElement(earningElementsFlattened.find((e) => e.categoryId === id) ?? null);
  };

  const handleOnCloseNestedModal = (deleted: boolean) => {
    setNestedModal('None');

    if (deleted) {
      onClose(true);
    }
  };

  const [earningsElementTotal, setEarningsElementTotal] = useState(dataEarningsElement?.readModel?.totalValue);

  const totalValue = useMemo(() => {
    return elementValuation?.totalRentalIncome ?? earningsElementTotal ?? 0;
  }, [elementValuation?.totalRentalIncome, earningsElementTotal]);

  const isLoading = useMemo(() => {
    return (
      isFetchingEarningsElement ||
      isFetchingDeliveryPhases ||
      isFetchingCalculationModel ||
      isFetchingCalculationModelEarnings ||
      isUpdating ||
      isCreating ||
      isLoadingEarningsCatalog ||
      isLoadingFormula ||
      isUpdatingFeatureElement
    );
  }, [
    isFetchingCalculationModelEarnings,
    isFetchingEarningsElement,
    isFetchingDeliveryPhases,
    isFetchingCalculationModel,
    isUpdating,
    isCreating,
    isLoadingEarningsCatalog,
    isLoadingFormula,
    isUpdatingFeatureElement
  ]);

  const isInflationElement = dataEarningsElement?.readModel.isInflationElement ?? false;
  const isFeatureElement = isInflationElement;

  return (
    <>
      <SlideOver.Header
        onClose={onClose}
        title={isEditing ? description : t('projectCalculate.earningsElementNewEarnings')}
        subTitle={t('projectCalculate.earningsElementNewEarningsSubTitle')}
        backgroundClassName="bg-green-700 w-full text-white p-8 "
      >
        <div className="flex flex-row pt-2 pl-8 bg-green-700 text-white">
          <button
            type="button"
            className={classNames('px-4 pb-1 text block border-l border-r', {
              'font-bold border-b-2 border-white': subarea === 'Values',
            })}
            onClick={() => setSubarea('Values')}
          >
            <div className="flex felx-row items-center">{t('common.values')}</div>
          </button>
          {isEditing && (
            <>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'Documents',
                })}
                onClick={() => setSubarea('Documents')}
              >
                <div className="flex felx-row items-center">{t('common.documents')}</div>
              </button>
              {showFakeUi && (
                <>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-l border-r', {
                      'font-bold border-b-2 border-white': subarea === 'History',
                    })}
                    onClick={() => setSubarea('History')}
                  >
                    <div className="flex felx-row items-center">{t('common.history')}</div>
                  </button>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-r', {
                      'font-bold border-b-2 border-white': subarea === 'Comments',
                    })}
                    onClick={() => setSubarea('Comments')}
                  >
                    <div className="flex felx-row items-center">{t('common.comments')}</div>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </SlideOver.Header>
      <SlideOver.Content
        isLoading={isLoading}
        onKeyEnter={() => {
          submitRef.current?.click();
        }}
      >
        <DisplayErrors translatedErrors={translatedErrors} />

        {subarea === 'Comments' && (
          <div className="p-8">
            <img className="w-full h-auto" src="/assets/fake-calculate-element-comment.svg" alt="comments" />
          </div>
        )}

        {subarea === 'History' && (
          <div className="p-8">
            {currentLanguage === 'de' ? (
              <img className="w-full h-auto" src="/assets/fake-calculate-element-history-de.svg" alt="history" />
            ) : (
              <img className="w-full h-auto" src="/assets/fake-calculate-element-history-en.svg" alt="history" />
            )}
          </div>
        )}

        {subarea === 'Documents' && (
          <div className="m-8">
            <CalculateElementDocuments
              ownerId={elementId ?? ''}
              type="EarningsElement"
              canWrite={!readOnly}
              canDelete={canDeleteEarnings}
              documents={dataEarningsElement?.readModel.documents ?? []}
            />
          </div>
        )}

        {subarea === 'Values' && (
          <>
            <EarningElementMetadata
              hasCatalog={hasCatalog}
              isGroup={isGroup}
              catalogOptions={catalogOptions}
              selectedCatalogElementId={selectedCatalogElementId}
              updateSelectedCatalogElement={updateSelectedCatalogElement}
              code={code}
              setCode={setCode}
              disabled={readOnly}
              description={description}
              setDescription={setDescription}
            />
            {!isFeatureElement && (
              <>
                <SlideOverTitle title={t('projectCalculate.earningsElementLabelEarningsElement')} className="px-8" />
                <div className="mb-2 mx-8 bg-white">
                  <div className="divide-gray-100 divide-y">
                    {!isEditing && !hasCatalog && (
                      <BaseSelect
                        label={t('projectCalculate.earningsElementLabelEarningsType')}
                        options={earningsTypes}
                        value={earningsType}
                        onChange={(value) => setEarningsType(value)}
                        icon={<EditNodeIcon />}
                        disabled={readOnly}
                      />
                    )}
                    <EarningsFormulaInput
                      value={formulaResult ?? defaultEarningsElement}
                      onChange={handleEarningElementChange}
                      disabled={readOnly}
                      unitOptions={unitOptions}
                      catalogElementId={selectedCatalogElementId ?? elementId ?? undefined}
                      setIsLoading={setIsLoadingFormula}
                    />

                    {!isSaleRevenue && (
                      <>
                        {earningsType === 'RentRevenue' && (
                          <div className="grid grid-cols-2 divide-x">
                            <NumberInput
                              label={t('projectCalculate.earningsElementLabelIndex')}
                              value={rentIndex}
                              onChange={(value) => setRentIndex(value)}
                              icon={<PositiveDynamicIcon />}
                              decimalScale={2}
                              disabled={readOnly}
                            />
                            <DatePicker
                              label={t('projectCalculate.earningsElementLabelIndexDate')}
                              icon={<DateFromIcon />}
                              value={rentIndexDate}
                              onChange={(x) => setRentIndexDate(x ?? undefined)}
                              disabled={readOnly}
                              // TODO: check min and max date. FM
                              minDate={contractStartDate}
                              maxDate={
                                variantData?.calculationModel.payload.endDate
                                  ? new Date(variantData?.calculationModel.payload.endDate)
                                  : undefined
                              }
                            />
                          </div>
                        )}
                        <div className="grid grid-cols-2 divide-x">
                          <div>
                            <BaseSelect
                              label={t('projectCalculate.earningsElementLabelPaymentFrequency')}
                              options={paymentFrequencies}
                              value={paymentFrequency}
                              onChange={(value) => setPaymentFrequency(value)}
                              icon={<DepositIcon />}
                              disabled={readOnly}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* currently we have only inflation as feature element */}
            {isFeatureElement && elementId && inflationElement && (
              <FeatureElementEdit
                elementId={elementId}
                type="inflation"
                element={inflationElement}
                isReadOnly={readOnly}
                section="earnings"
                setIsUpdating={setIsUpdatingFeatureElement}
                setElement={(featureElement: FeatureElement) => {
                  setInflationElement(featureElement as InflationElementReadModel);
                }}
                elementTiming={paymentTimeline}
                setElementTiming={setPaymentTimeline}
                formulaResult={formulaResult}
                setFormulaResult={(res) => {
                  setFormulaResult(res as EarningsElementReadModel)
                }}
              />
            )}
            {
              <>
                <SlideOverTitle title={t('projectCalculate.earningsElementLabelTimeline')} className="px-8" />
                <div className="mb-8 mx-8 bg-white">
                  <TimeLineDistribution
                    totalValue={getEffectiveAmount() * getEffectiveUnitPrice()}
                    variantId={variantId}
                    timing={paymentTimeline}
                    disabled={readOnly || (earningsType && earningsType !== 'SaleRevenue') || isInflationElement}
                    disabledDistributionTypes={disableDistributionTypes}
                    disabledGrainOptions={earningsType !== 'SaleRevenue' ? ['Week'] : []}
                    earningsType={earningsType as EarningsType}
                    index={rentIndex}
                    indexDate={rentIndexDate}
                    paymentFrequency={paymentFrequency as PaymentFrequency}
                    onChange={(value) => {
                      setPaymentTimeline(value);
                    }}
                    onClear={() => {
                      setPaymentTimeline(null);
                    }}
                  />
                </div>
              </>
            }

            <SlideOverTotalSection
              label={t('projectCalculate.earningsElementLabelNetSum')}
              value={<FormattedCurrency amount={totalValue} />}
            />

            {earningsType === 'RentRevenue' && (
              <div className="relative">
                <SlideOverTitle title={t('projectCalculate.earningsElementGroupMultiple')} className="px-8" />
                <div className="mb-2 mx-8 bg-white">
                  <div className="divide-gray-100 divide-y">
                    <BooleanInput
                      label={t('projectCalculate.earningsElementLabelRelevantForValuation')}
                      value={relevantForValuation}
                      onChange={(value) => setRelevantForValuation(value)}
                      variant="checkbox-left"
                    />
                    <div className="grid grid-cols-2 divide-x">
                      <NumberInput
                        label={t('projectCalculate.earningsElementLabelMultiple')}
                        value={isEditing ? multiple : multiple ?? selectedCatalogElement?.element.group?.multiple ?? 25}
                        onChange={(value) => setMultiple(value ?? 1)}
                        icon={<ScatterPlotIcon />}
                        decimalScale={5}
                        disabled={readOnly}
                      />
                      <div>
                        <DatePicker
                          label={t('projectCalculate.earningsElementLabelCalculationDate')}
                          icon={<TearOffCalenderIcon />}
                          value={calculationDate}
                          onChange={(x) => setCalculationDate(x ?? new Date())}
                          className="w-full"
                          disabled={readOnly}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <CalculateMultiple multiplier={calculatedMultiple} />

            {!readOnly && (
              <EditUserDefinedFields
                type="Earning"
                elementId={elementId}
                calculateElementType={isGroup ? 'Group' : 'Element'}
                setUpdatePayload={setUserDefinedFieldsPayload}
                selectedCustomFields={selectedCustomFields}
                setSelectedCustomFields={setSelectedCustomFields}
                isSubmitted={isFormSubmitted}
                updateIsValid={setCustomFieldsAreValid}
              />
            )}
            <RentRelevantForValuation
              elementValuation={elementValuation}
              isUpdatingValueCalculation={isUpdatingValueCalculation}
              relevantForValuation={relevantForValuation}
            />
          </>
        )}
      </SlideOver.Content>
      <SlideOver.Controls disabled={isLoading}>
        <div
          className={classNames('w-full flex justify-end', {
            'justify-between': isEditing && !readOnly,
          })}
        >
          {isEditing && canDeleteEarnings && (
            <Button variant="warning" onClick={() => setNestedModal('Delete')}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex">
            <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
              {t('common.cancel')}
            </Button>
            {!readOnly && (
              <Button
                variant="primary"
                onClick={() => {
                  setIsFormSubmitted(true);
                  if (isEditing) {
                    handleUpdate();
                  } else {
                    handleCreate();
                  }
                }}
                innerRef={submitRef}
              >
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>

      <Modal isOpen={nestedModal === 'Delete'} onClose={handleOnCloseNestedModal}>
        <EarningsElementDeleteModal elementId={elementId} variantId={variantId} onClose={handleOnCloseNestedModal} />
      </Modal>
    </>
  );
};
