import { SlideOver } from '@client/shared/toolkit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceEditContext, InvoiceSlideOver } from '../..';
import { ContractReadModel, InvoiceReadModel } from '@client/shared/api';

interface ContractInvoiceSlideOverProps {
  isOpen: boolean;
  onClose: () => void;
  unsavedData: boolean;
  selectedInvoice: InvoiceReadModel;
  contract: ContractReadModel;
  setIsOpenUploadInvoiceModal: (isOpen: boolean) => void;
}

export const ContractInvoiceSlideOver = ({
  isOpen,
  onClose,
  unsavedData,
  selectedInvoice,
  contract,
  setIsOpenUploadInvoiceModal,
}: ContractInvoiceSlideOverProps) => {
  const { t } = useTranslation();
  const { guideDialogData, setGuideDialogData } = useContext(InvoiceEditContext);

  const handleOnClose = () => {
    setGuideDialogData({
      ...guideDialogData,
      showDialog: false,
    });
    onClose();
  };

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={handleOnClose}
      variant="2xl"
      confirmBeforeClose={unsavedData}
      cancelConfirmTitle={t('portfolio.projecting.cancelEditingConfirmationTitle')}
      cancelConfirmDescription={t('portfolio.projecting.cancelEditingConfirmationDescription')}
      cancelButtonText={t('portfolio.projecting.continueEditing')}
      confirmButtonText={t('portfolio.projecting.cancelEditingWithoutSaving')}
    >
      <InvoiceSlideOver
        onClose={handleOnClose}
        selectedInvoiceId={selectedInvoice.id}
        contract={contract}
        startUpload={() => setIsOpenUploadInvoiceModal(true)}
      />
    </SlideOver>
  );
};
