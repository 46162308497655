import {
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';

export const DashboardWidgetWaterfall = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.waterfall.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        Waterfall widget
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
