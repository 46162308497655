import {
  CheckBox,
  ClearFiltersIcon,
  ConversionIcon,
  Button,
  SearchInput,
  DatePicker,
  TearOffCalenderIcon,
  ComboSelectOption,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { BenchmarkingFilterProject, getBenchmarkProjectAssetClassOptions } from '..';

export interface FormulaEditorBenchmarkSelectableFilter {
  searchInput: string;
  assetClasses: string[];
  start: Date | null | undefined;
  end: Date | null | undefined;
}

interface BenchmarkingProjectFilterProps {
  benchmarkProjects: BenchmarkingFilterProject[];
  selectedFilters: FormulaEditorBenchmarkSelectableFilter;
  setSelectedFilters: (filters: FormulaEditorBenchmarkSelectableFilter) => void;
  disabled?: boolean;
}

export const BenchmarkingProjectFilter = ({
  benchmarkProjects,
  selectedFilters,
  setSelectedFilters,
  disabled = false
}: BenchmarkingProjectFilterProps) => {
  const { t } = useTranslation();

  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const assetClassOptions = useMemo(() => {
    return getBenchmarkProjectAssetClassOptions(benchmarkProjects);
  }, [benchmarkProjects]);

  const resetAll = () => {
    setSelectedFilters({ searchInput: '', assetClasses: [], start: null, end: null });
  };

  const searchFiltersActive = useMemo(() => {
    return (
      selectedFilters.assetClasses.length > 0 ||
      selectedFilters.searchInput.length > 0 ||
      selectedFilters.start ||
      selectedFilters.end
    );
  }, [selectedFilters]);

  return (
    <div>
      <div className="w-full flex items-center justify-between mb-2 divide-x">
        <DatePicker
          label={t('projectCalculate.benchmarkingStartDate')}
          icon={<TearOffCalenderIcon />}
          value={selectedFilters.start}
          onChange={(date) => setSelectedFilters({ ...selectedFilters, start: date })}
          disabled={disabled}
        />
        <DatePicker
          label={t('projectCalculate.benchmarkingEndDate')}
          icon={<TearOffCalenderIcon />}
          value={selectedFilters.end}
          onChange={(date) => setSelectedFilters({ ...selectedFilters, end: date })}
          disabled={disabled}
        />
        <div className="divide-x flex justify-center h-14 bg-white">
          <SearchInput
            value={selectedFilters.searchInput}
            handleSearch={(value) => setSelectedFilters({ ...selectedFilters, searchInput: value })}
            className="w-full h-full text-slate-500 px-6"
            variant="transparent"
            placeholder={t('common.filter')}
            disabled={disabled}
          />

          <Popover>
            <>
              <PopoverButton
                ref={setTargetElement}
                className={classNames('h-full rounded-none pl-4 pr-6 focus:outline-none bg-white', {
                  'bg-sky-200 bg-opacity-100': searchFiltersActive,
                })}
                disabled={disabled}
              >
                {searchFiltersActive ? (
                  <ClearFiltersIcon className="h-5 w-5 text-gray-600" />
                ) : (
                  <ConversionIcon className="h-5 w-5 text-gray-400" />
                )}
              </PopoverButton>

              <PopoverPanel
                as="div"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="z-0 min-w-52 flex items-center justify-center bg-gray-100 border border-gray-200 shadow-lg outline-none border-t-0 rounded-lg "
              >
                <div className="w-full flex flex-col">
                  <div
                    className="flex justify-end w-full py-2 items-center cursor-pointer"
                    onClick={() => {
                      searchFiltersActive
                        ? resetAll()
                        : setSelectedFilters({
                            ...selectedFilters,
                            assetClasses: assetClassOptions.map((option) => option.value),
                          });
                    }}
                  >
                    <span className="text-gray-500 text-xs">
                      {t(searchFiltersActive ? 'common.reset' : 'common.selectAll')}
                    </span>

                    <Button className="rounded-none" variant="custom">
                      {searchFiltersActive ? (
                        <ClearFiltersIcon className="h-5 w-5" />
                      ) : (
                        <ConversionIcon className="h-5 w-5" />
                      )}
                    </Button>
                  </div>
                  <div className="h-[205px] mx-4 mb-4 bg-white rounded-md flex flex-col divide-y text-black text-base font-normal max-h-[400px] overflow-y-auto">
                    <div className="flex justify-between items-center p-2 font-bold">
                      {t('projectCalculate.benchmarkingAssetClass')}
                      <CheckBox
                        checked={assetClassOptions.every((option) =>
                          selectedFilters.assetClasses.includes(option.value),
                        )}
                        onChange={(val) => {
                          setSelectedFilters({
                            ...selectedFilters,
                            assetClasses: val ? assetClassOptions.map((option) => option.value) : [],
                          });
                        }}
                      />
                    </div>
                    {assetClassOptions.map((option) => (
                      <AssetClass
                        key={option.value}
                        assetClassOption={option}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                      />
                    ))}
                  </div>
                </div>
              </PopoverPanel>
            </>
          </Popover>
        </div>
      </div>
    </div>
  );
};

interface AssetClassProps {
  assetClassOption: ComboSelectOption;
  selectedFilters: FormulaEditorBenchmarkSelectableFilter;
  setSelectedFilters: (filters: FormulaEditorBenchmarkSelectableFilter) => void;
}

const AssetClass = ({ assetClassOption, selectedFilters, setSelectedFilters }: AssetClassProps) => {
  const addOrRemoveAssetClass = ({ add }: { add: boolean }) => {
    if (add) {
      setSelectedFilters({
        ...selectedFilters,
        assetClasses: [...selectedFilters.assetClasses, assetClassOption.value],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        assetClasses: selectedFilters.assetClasses.filter((assetClass) => assetClass !== assetClassOption.value),
      });
    }
  };

  return (
    <div className="flex flex-col divide-y">
      <div
        className="flex justify-between items-center p-2 w-full hover:cursor-pointer"
        key={assetClassOption.label}
        onClick={() => addOrRemoveAssetClass({ add: !selectedFilters.assetClasses.includes(assetClassOption.value) })}
      >
        <span className="truncate w-11/12 pr-3">{assetClassOption.label}</span>
        <CheckBox
          className="w-1/12"
          checked={selectedFilters.assetClasses.includes(assetClassOption.value)}
          onChange={(val) => addOrRemoveAssetClass({ add: val })}
        />
      </div>
      {!!assetClassOption.options?.length && (
        <div className="pl-1 flex flex-col divide-y">
          {assetClassOption.options?.map((option) => (
            <AssetClass
              key={option.label}
              assetClassOption={option}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          ))}
        </div>
      )}
    </div>
  );
};
