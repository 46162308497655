import {
  Badge,
  CardDataSet,
  ComboSelect,
  ContractNumberIcon,
  DatePicker,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  ErrorIcon,
  EstimateIcon,
  FormField,
  FormWatch,
  NumberInput,
  TextInput,
} from '@client/shared/toolkit';
import cn from 'classnames';
import { InvoiceCreateFormValidationValues, InvoiceEditContext } from '..';
import { findCostElementRestBudget, getMinDateError } from '../../../utils';
import { formatDate, formatDateOnly, formatPercentage, safeMutation } from '@client/shared/utilities';
import { formatDistanceToNow } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CatalogElementRestBudget,
  ContractReadModel,
  InvoiceReadModel,
  useApiGetCalculationModelRestBudgetMutation,
} from '@client/shared/api';
import { ProjectTaxPickerInput } from '../../ProjectTaxPickerInput';
import { InvoiceBudgetAssignment } from '../../Budgeting';
import { useFlattenCostElementRestBudgets } from '../../../hooks';
import { getMinDate } from '../../../utils';
import { FormattedCurrency } from '../..';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface InvoiceDataEditProps {
  defaultFormValues: InvoiceCreateFormValidationValues;
  allowChangeMode: boolean;
  setAllowChangeMode: (allowChangeMode: boolean) => void;
  canEdit: boolean;
  canEditWhenApproved?: boolean;
  invoice?: InvoiceReadModel;
  contract?: ContractReadModel;
  codeError?: boolean;
  first?: boolean;
  setIsEditMode?: (isEditMode: boolean) => void;
  updateUnsavedData?: (formField: string, unsaved: boolean) => void;
  budgetAssignment?: string;
  setBudgetAssignment?: (budgetAssignment?: string) => void;
  showType?: boolean;
  contractId?: string | null;
}

export const InvoiceDataEdit = (props: InvoiceDataEditProps) => {
  const {
    codeError,
    defaultFormValues,
    allowChangeMode,
    setAllowChangeMode,
    invoice,
    canEdit,
    canEditWhenApproved,
    first = false,
    setIsEditMode,
    updateUnsavedData,
    budgetAssignment,
    setBudgetAssignment,
    showType = true,
    contractId,
  } = props;
  const { t } = useTranslation();

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const [postGetRestBudget, { isLoading }] = useApiGetCalculationModelRestBudgetMutation();
  const [restBudgetElements, setRestBudgetElements] = useState<CatalogElementRestBudget[]>([]);
  const { setIsValid } = useContext(InvoiceEditContext);

  useEffect(() => {
    const getRestBudget = async () => {
      const resp = await safeMutation(
        postGetRestBudget,
        {
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? '',
          body: [],
        },
        isLoading,
      );
      setRestBudgetElements(resp?.payload.catalogElements ?? []);
    };

    try {
      getRestBudget();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flattened = useFlattenCostElementRestBudgets(restBudgetElements);

  const costElement = useMemo(() => {
    if (budgetAssignment) {
      const foundElem = findCostElementRestBudget(budgetAssignment, flattened);
      if (foundElem) {
        let code = '';
        let description = '';
        if (foundElem && 'code' in foundElem) {
          code = foundElem.code ?? '';
        }

        if (foundElem && 'name' in foundElem) {
          description = foundElem.name === '' ? t('projectCalculate.unnamedElement') : foundElem.name ?? '';
        }

        return `${code} - ${description}`;
      }
    }
    return '-';
  }, [budgetAssignment, flattened, t]);

  const invoiceTypeOptions = useMemo(() => {
    // invoice has no contract
    if (!contractId) {
      return [
        {
          value: 'Single',
          label: t('projectControl.invoiceTypeSingle'),
        },
      ];
    } else {
      return [
        {
          value: 'Single',
          label: t('projectControl.invoiceTypeSingle'),
        },
        {
          value: 'Warranty',
          label: t('projectControl.invoiceTypeWarranty'),
        },
        {
          value: 'AdvancePayment',
          label: t('projectControl.invoiceTypeAdvancePayment'),
        },
        {
          value: 'Partial',
          label: t('projectControl.invoiceTypePartial'),
        },
        {
          value: 'PartialFinal',
          label: t('projectControl.invoiceTypePartialFinal'),
        },
        {
          value: 'Final',
          label: t('projectControl.invoiceTypeFinal'),
        },
      ];
    }
  }, [t, contractId]);

  return (
    <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['dateOfReceipt']}>
      {({ dateOfReceipt }) => (
        <>
          <div className={cn('flex flex-col gap-4 divide-y-2 pb-4', first ? '' : 'pt-4')}>
            {/* CODE */}
            <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['code']}>
              {({ code }) => (
                <DocumentViewerFileDataInlineEdit
                  updateEditMode={setIsEditMode}
                  toggleContent={
                    canEdit ? (
                      <FormField name="code">
                        {(control) => (
                          <TextInput
                            label={t('projectControl.invoiceCode')}
                            icon={<ContractNumberIcon className="h-6 w-6" />}
                            disabled={!canEdit}
                            {...control}
                            onChange={(value) => {
                              if (updateUnsavedData) {
                                updateUnsavedData('code', value === defaultFormValues.code);
                              }
                              control.onChange(value);
                            }}
                            isValidationValid={!codeError && control.isValidationValid && !!control.value}
                            showValidation={codeError || control.showValidation || !control.value}
                            helperText={codeError ? t('error.invoice.code_already_exists') : control.helperText}
                          />
                        )}
                      </FormField>
                    ) : undefined
                  }
                >
                  <DocumentViewerFileDataSet label={t('projectControl.invoiceCode')}>
                    <span
                      className={cn(
                        'text-lg font-bold inline-flex',
                        code !== defaultFormValues.code ? 'text-secondary' : '',
                      )}
                    >
                      {code ? (
                        code
                      ) : (
                        <span className="flex items-center gap-1 font-normal text-xs italic mt-2 text-red-700">
                          {t('projectControl.codeRequiredMessage')}
                          <ErrorIcon className="w-4 text-red-700" />
                        </span>
                      )}
                    </span>
                    {codeError && (
                      <span className="flex items-center gap-1 font-normal text-xs italic mt-2 text-red-700">
                        {t('error.invoice.code_already_exists')}
                        <ErrorIcon className="w-4 text-red-700" />
                      </span>
                    )}
                  </DocumentViewerFileDataSet>
                </DocumentViewerFileDataInlineEdit>
              )}
            </FormWatch>

            {/* EXTERNAL CODE */}
            <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['externalCode']}>
              {({ externalCode }) => (
                <DocumentViewerFileDataInlineEdit
                  updateEditMode={setIsEditMode}
                  className="pt-4"
                  toggleContent={
                    canEdit ? (
                      <FormField name="externalCode">
                        {(control) => (
                          <TextInput
                            icon={<ContractNumberIcon />}
                            label={t('projectControl.invoiceExternalCode')}
                            disabled={!canEdit}
                            {...control}
                            onChange={(value) => {
                              if (updateUnsavedData) {
                                updateUnsavedData('externalCode', value === defaultFormValues.externalCode);
                              }
                              control.onChange(value);
                            }}
                          />
                        )}
                      </FormField>
                    ) : undefined
                  }
                >
                  <DocumentViewerFileDataSet label={t('projectControl.invoiceExternalCode')}>
                    <span className={externalCode !== defaultFormValues.externalCode ? 'text-secondary' : undefined}>
                      {externalCode ? externalCode : '-'}
                    </span>
                  </DocumentViewerFileDataSet>
                </DocumentViewerFileDataInlineEdit>
              )}
            </FormWatch>

            {/* INVOICE TYPE */}
            {showType && (
              <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['type']}>
                {({ type }) => (
                  <DocumentViewerFileDataInlineEdit
                    className="pt-4"
                    updateEditMode={setIsEditMode}
                    allowChangeMode={allowChangeMode}
                    toggleContent={
                      canEdit ? (
                        <FormField name="type">
                          {(control) => (
                            <ComboSelect
                              label={t('projectControl.auditInvoiceType')}
                              options={invoiceTypeOptions}
                              handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                              {...control}
                              onChange={(value) => {
                                if (updateUnsavedData) {
                                  updateUnsavedData('type', value === defaultFormValues.type);
                                }
                                control.onChange(value);
                              }}
                            />
                          )}
                        </FormField>
                      ) : undefined
                    }
                  >
                    <DocumentViewerFileDataSet
                      label={t('projectControl.auditInvoiceType')}
                      subtitle={
                        type ? (
                          <>
                            {t(`projectControl.invoiceType${type}`)}
                            {!contractId && type !== 'Single' && <ErrorIcon className="w-4 -mt-px ml-1 text-red-700" />}
                          </>
                        ) : (
                          '-'
                        )
                      }
                      className={type !== defaultFormValues.type ? 'text-secondary' : ''}
                    />
                  </DocumentViewerFileDataInlineEdit>
                )}
              </FormWatch>
            )}

            {/* COMMENT */}
            <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['comment']}>
              {({ comment }) => (
                <DocumentViewerFileDataInlineEdit
                  updateEditMode={setIsEditMode}
                  className="pt-4"
                  toggleContent={
                    canEdit ? (
                      <FormField name="comment">
                        {(control) => (
                          <TextInput
                            label={t('projectControl.invoiceComment')}
                            disabled={!canEdit}
                            {...control}
                            onChange={(value) => {
                              if (updateUnsavedData) {
                                updateUnsavedData('comment', value === defaultFormValues.comment);
                              }
                              control.onChange(value);
                            }}
                            inputType="textarea"
                          />
                        )}
                      </FormField>
                    ) : undefined
                  }
                >
                  <DocumentViewerFileDataSet label={t('projectControl.invoiceComment')}>
                    <span className={cn('text-xs', comment !== defaultFormValues.comment ? 'text-secondary' : '')}>
                      {comment ? comment : '-'}
                    </span>
                  </DocumentViewerFileDataSet>
                </DocumentViewerFileDataInlineEdit>
              )}
            </FormWatch>

            {/* BUDGET ASSIGNMENT */}
            {!invoice?.contractId && setBudgetAssignment && (
              <div className="pt-4 flex justify-between items-center">
                <DocumentViewerFileDataInlineEdit
                  updateEditMode={setIsEditMode}
                  allowChangeMode={allowChangeMode}
                  className="w-full pr-2"
                  toggleContent={
                    canEdit && invoice?.canBeBudgeted ? (
                      <InvoiceBudgetAssignment
                        budgetAssignment={invoice?.budgetAssignment}
                        disabled={!canEdit || (invoice ? !invoice?.canBeBudgeted : false)}
                        canBeBudgeted={invoice?.canBeBudgeted}
                        updateInvoiceBudgetAssignment={(budget) => {
                          if (updateUnsavedData) {
                            updateUnsavedData('budgetAssignment', budgetAssignment === budget);
                          }
                          setBudgetAssignment(budget);
                        }}
                        showTitle={false}
                        handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                      />
                    ) : undefined
                  }
                >
                  <DocumentViewerFileDataSet
                    label={t('projectContract.budgeting')}
                    subtitle={costElement ?? '-'}
                    className={
                      budgetAssignment !== invoice?.budgetAssignment?.costElementId ? 'text-secondary' : undefined
                    }
                  />
                </DocumentViewerFileDataInlineEdit>
              </div>
            )}

            {/* CLAIM & VAT */}
            {invoice && (
              <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['claim', 'vat']}>
                {({ claim, vat }) => (
                  <DocumentViewerFileDataInlineEdit
                    closeOnBlur={false}
                    updateEditMode={setIsEditMode}
                    className="pt-4"
                    allowChangeMode={allowChangeMode}
                    toggleContent={
                      canEdit ? (
                        <>
                          <FormField name="claim">
                            {(control) => (
                              <NumberInput
                                label={t('projectControl.invoiceClaim')}
                                icon={<EstimateIcon className="h-6 w-6" />}
                                disabled={!canEdit}
                                {...control}
                                onChange={(value) => {
                                  if (updateUnsavedData) {
                                    updateUnsavedData('claim', value === defaultFormValues.claim);
                                  }
                                  control.onChange(value);
                                }}
                              />
                            )}
                          </FormField>
                          <FormField name="vat">
                            {(control) => (
                              <ProjectTaxPickerInput
                                className="mt-1"
                                disabled={!canEdit}
                                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                {...control}
                                onChange={(value) => {
                                  if (updateUnsavedData) {
                                    updateUnsavedData('vat', value === defaultFormValues.vat);
                                  }
                                  control.onChange(value);
                                }}
                              />
                            )}
                          </FormField>
                        </>
                      ) : undefined
                    }
                  >
                    <div className={cn('grid grid-cols-2 gap-2 items-center', !canEdit ? 'pt-4' : '')}>
                      <DocumentViewerFileDataSet
                        label={t('projectControl.invoiceClaim')}
                        subtitle={
                          typeof claim !== 'undefined' && claim !== null ? <FormattedCurrency amount={claim} /> : '-'
                        }
                        className={claim !== defaultFormValues.claim ? 'text-secondary' : undefined}
                      />
                      <DocumentViewerFileDataSet
                        label={t('common.vat')}
                        subtitle={
                          typeof vat !== 'undefined' && vat !== null
                            ? formatPercentage(vat / 100, { maxDigits: 0 })
                            : '-'
                        }
                        className={vat !== defaultFormValues.vat ? 'text-secondary' : undefined}
                      />
                    </div>
                  </DocumentViewerFileDataInlineEdit>
                )}
              </FormWatch>
            )}

            {/* DATES */}
            <FormWatch<InvoiceCreateFormValidationValues>
              fieldNames={[
                'invoiceDate',
                'dueDate',
                'cashDiscountDate',
                'dateOfAudit',
                'dateOfApproval',
                'paymentDate',
                'dateOfReceipt',
                'type',
              ]}
              onChange={({ invoiceDate, dueDate, dateOfReceipt, cashDiscountDate, type }) => {
                if (
                  getMinDateError(dateOfReceipt, invoiceDate) ||
                  getMinDateError(dueDate, dateOfReceipt) ||
                  getMinDateError(cashDiscountDate, dateOfReceipt) ||
                  (!contractId && type && type !== 'Single')
                ) {
                  setIsValid(false);
                } else {
                  setIsValid(true);
                }
              }}
            >
              {({ invoiceDate, dueDate, cashDiscountDate }) => (
                <div className="flex flex-col gap-4">
                  {/* INVOICE DATE & RECEIPT OF INVOICE */}
                  <DocumentViewerFileDataInlineEdit
                    updateEditMode={setIsEditMode}
                    closeOnBlur={false}
                    className="pt-4"
                    allowChangeMode={allowChangeMode}
                    marginX="-mt-6"
                    toggleContent={
                      canEdit ? (
                        <div className="grid grid-cols-2 gap-2 items-center">
                          <FormField name="invoiceDate">
                            {(control) => (
                              <DatePicker
                                label={t('projectControl.auditInvoiceDate')}
                                disabled={!canEdit}
                                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                size="small"
                                {...control}
                                onChange={(value) => {
                                  if (updateUnsavedData) {
                                    if (value && defaultFormValues.invoiceDate) {
                                      updateUnsavedData(
                                        'invoiceDate',
                                        formatDateOnly(value) === defaultFormValues.invoiceDate,
                                      );
                                    } else {
                                      updateUnsavedData('invoiceDate', value === defaultFormValues.invoiceDate);
                                    }
                                  }
                                  control.onChange(value);
                                }}
                              />
                            )}
                          </FormField>
                          <FormField name="dateOfReceipt">
                            {(control) => (
                              <DatePicker
                                label={t('projectControl.auditReceiptOfInvoice')}
                                disabled={!canEdit}
                                minDate={getMinDate(invoiceDate)}
                                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                size="small"
                                {...control}
                                onChange={(value) => {
                                  if (updateUnsavedData) {
                                    if (value && defaultFormValues.dateOfReceipt) {
                                      updateUnsavedData(
                                        'dateOfReceipt',
                                        formatDateOnly(value) === defaultFormValues.dateOfReceipt,
                                      );
                                    } else {
                                      updateUnsavedData('dateOfReceipt', value === defaultFormValues.dateOfReceipt);
                                    }
                                  }
                                  control.onChange(value);
                                }}
                              />
                            )}
                          </FormField>
                        </div>
                      ) : undefined
                    }
                  >
                    <div className={cn('grid grid-cols-2 gap-2 items-center', !canEdit ? 'pt-4' : '')}>
                      <DocumentViewerFileDataSet
                        label={t('projectControl.auditInvoiceDate')}
                        subtitle={
                          invoiceDate ? (
                            <>
                              {formatDate(invoiceDate)}
                              {getMinDateError(dateOfReceipt, invoiceDate) && (
                                <ErrorIcon className="w-4 -mt-px ml-1 text-red-700" />
                              )}
                            </>
                          ) : (
                            '-'
                          )
                        }
                        className={
                          invoiceDate && formatDateOnly(new Date(invoiceDate)) !== defaultFormValues.invoiceDate
                            ? 'text-secondary'
                            : undefined
                        }
                      />
                      <DocumentViewerFileDataSet
                        label={t('projectControl.auditReceiptOfInvoice')}
                        subtitle={dateOfReceipt ? formatDate(dateOfReceipt) : '-'}
                        className={
                          dateOfReceipt && formatDateOnly(new Date(dateOfReceipt)) !== defaultFormValues.dateOfReceipt
                            ? 'text-secondary'
                            : undefined
                        }
                      />
                    </div>
                    {getMinDateError(dateOfReceipt, invoiceDate) && (
                      <span className="text-xs italic mt-2 text-red-700">
                        {t('projectControl.invoiceDateMustBeSmallerOrEqualDateOfReceipt')}
                      </span>
                    )}
                  </DocumentViewerFileDataInlineEdit>

                  {/* DUE DATE & CASH DISCOUNT */}
                  <DocumentViewerFileDataInlineEdit
                    updateEditMode={setIsEditMode}
                    allowChangeMode={allowChangeMode}
                    closeOnBlur={false}
                    marginX={invoice ? '-mb-[18px]' : '-mb-8'}
                    toggleContent={
                      canEdit ? (
                        <div className="grid grid-cols-2 gap-2 items-start">
                          <FormField name="dueDate">
                            {(control) => (
                              <DatePicker
                                label={t('projectControl.dueDate')}
                                minDate={getMinDate(dateOfReceipt)}
                                disabled={!canEdit}
                                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                size="small"
                                {...control}
                                onChange={(value) => {
                                  if (updateUnsavedData) {
                                    if (value && defaultFormValues.dueDate) {
                                      updateUnsavedData('dueDate', formatDateOnly(value) === defaultFormValues.dueDate);
                                    } else {
                                      updateUnsavedData('dueDate', (value ?? undefined) === defaultFormValues.dueDate);
                                    }
                                  }
                                  control.onChange(value);
                                }}
                              />
                            )}
                          </FormField>
                          <FormField name="cashDiscountDate">
                            {(control) => (
                              <DatePicker
                                label={t('projectControl.cashDiscountDate')}
                                minDate={getMinDate(dateOfReceipt)}
                                disabled={!canEdit}
                                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                size="small"
                                {...control}
                                onChange={(value) => {
                                  if (updateUnsavedData) {
                                    if (value && defaultFormValues.cashDiscountDate) {
                                      updateUnsavedData(
                                        'cashDiscountDate',
                                        formatDateOnly(value) === defaultFormValues.cashDiscountDate,
                                      );
                                    } else {
                                      updateUnsavedData(
                                        'cashDiscountDate',
                                        value === defaultFormValues.cashDiscountDate,
                                      );
                                    }
                                  }
                                  control.onChange(value);
                                }}
                              />
                            )}
                          </FormField>
                        </div>
                      ) : undefined
                    }
                  >
                    <div className="grid grid-cols-2 gap-2 items-start">
                      <DocumentViewerFileDataSet
                        label={t('projectControl.dueDate')}
                        subtitle={
                          dueDate ? (
                            <>
                              {formatDate(dueDate)}
                              {getMinDateError(dueDate, dateOfReceipt) && (
                                <ErrorIcon className="w-4 -mt-px ml-1 text-red-700" />
                              )}
                            </>
                          ) : (
                            '-'
                          )
                        }
                        text={
                          dueDate ? formatDistanceToNow(dueDate, { addSuffix: true, includeSeconds: false }) : undefined
                        }
                        className={
                          dueDate && formatDateOnly(new Date(dueDate)) !== defaultFormValues.dueDate
                            ? 'text-secondary'
                            : undefined
                        }
                      >
                        {getMinDateError(dueDate, dateOfReceipt) && (
                          <span className="text-xs italic mt-2 text-red-700">
                            {t('projectControl.dueDateMustBeGreaterOrEqualDateOfReceipt')}
                          </span>
                        )}
                      </DocumentViewerFileDataSet>
                      <DocumentViewerFileDataSet
                        label={t('projectControl.cashDiscountDate')}
                        subtitle={
                          cashDiscountDate ? (
                            <>
                              {formatDate(cashDiscountDate)}
                              {cashDiscountDate &&
                                dateOfReceipt &&
                                getMinDateError(cashDiscountDate, dateOfReceipt) && (
                                  <ErrorIcon className="w-4 -mt-px ml-1 text-red-700" />
                                )}
                            </>
                          ) : (
                            '-'
                          )
                        }
                        text={
                          cashDiscountDate ? (
                            formatDistanceToNow(cashDiscountDate, { addSuffix: true, includeSeconds: false })
                          ) : dueDate ? (
                            <>&nbsp;</>
                          ) : undefined
                        }
                        className={
                          cashDiscountDate &&
                          formatDateOnly(new Date(cashDiscountDate)) !== defaultFormValues.cashDiscountDate
                            ? 'text-secondary'
                            : undefined
                        }
                      >
                        {getMinDateError(cashDiscountDate, dateOfReceipt) && (
                          <span className="text-xs italic mt-2 text-red-700">
                            {t('projectControl.dueDateMustBeGreaterOrEqualDateOfReceipt')}
                          </span>
                        )}
                      </DocumentViewerFileDataSet>
                    </div>
                  </DocumentViewerFileDataInlineEdit>
                </div>
              )}
            </FormWatch>
          </div>
          {/* STATE AND STATE DATES */}
          {invoice && (
            <div className="flex flex-col gap-4 py-4">
              <FormWatch<InvoiceCreateFormValidationValues>
                fieldNames={['dateOfAudit', 'dateOfApproval', 'paymentDate']}
              >
                {({ dateOfAudit, dateOfApproval, paymentDate }) => (
                  <>
                    <div className="grid grid-cols-2 gap-2 items-start">
                      {/* STATE */}
                      <CardDataSet label={t('projectControl.invoiceStatus')} className="items-start">
                        {invoice.state === 'Pending' && (
                          <Badge variant="lightInfo" text={t('projectControl.statePending')} />
                        )}
                        {invoice.state === 'Audited' && (
                          <Badge
                            variant="custom"
                            className={'bg-emerald-500 text-white'}
                            text={t('projectControl.stateAudited')}
                          />
                        )}
                        {invoice.state === 'Approved' && (
                          <Badge
                            variant="custom"
                            className={'bg-emerald-500 text-white'}
                            text={t('projectControl.stateApproved')}
                          />
                        )}
                        {invoice.state === 'Paid' && (
                          <Badge
                            variant="custom"
                            className={'bg-slate-300 text-white'}
                            text={t('projectControl.statePaid')}
                          />
                        )}
                      </CardDataSet>

                      {/* DATE OF AUDIT */}
                      <DocumentViewerFileDataInlineEdit
                        updateEditMode={setIsEditMode}
                        allowChangeMode={allowChangeMode}
                        closeOnBlur={false}
                        marginX="-mt-[18px]"
                        toggleContent={
                          canEditWhenApproved && invoice.state !== 'Pending' ? (
                            <>
                              {/* DATE OF AUDIT - cannot be edited if in state pending */}
                              <FormField name="dateOfAudit">
                                {(control) => (
                                  <DatePicker
                                    label={t('projectControl.dateOfAudit')}
                                    minDate={getMinDate(dateOfReceipt)}
                                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                    size="small"
                                    disabled={invoice.state === 'Pending'}
                                    {...control}
                                    onChange={(value) => {
                                      if (updateUnsavedData) {
                                        if (value && defaultFormValues.dateOfAudit) {
                                          updateUnsavedData(
                                            'dateOfAudit',
                                            formatDateOnly(value) === defaultFormValues.dateOfAudit,
                                          );
                                        } else {
                                          updateUnsavedData('dateOfAudit', value === defaultFormValues.dateOfAudit);
                                        }
                                      }
                                      control.onChange(value);
                                    }}
                                  />
                                )}
                              </FormField>
                            </>
                          ) : undefined
                        }
                      >
                        <DocumentViewerFileDataSet
                          label={t('projectControl.dateOfAudit')}
                          subtitle={dateOfAudit ? formatDate(dateOfAudit) : '-'}
                          className={
                            dateOfAudit && formatDateOnly(new Date(dateOfAudit)) !== defaultFormValues.dateOfAudit
                              ? 'text-secondary'
                              : undefined
                          }
                        />
                      </DocumentViewerFileDataInlineEdit>
                    </div>

                    {/* DATE OF APPROVAL & PAYMENT DATE */}
                    <DocumentViewerFileDataInlineEdit
                      updateEditMode={setIsEditMode}
                      allowChangeMode={allowChangeMode}
                      closeOnBlur={false}
                      marginX="-mb-[18px]"
                      toggleContent={
                        canEditWhenApproved && (invoice.state === 'Approved' || invoice.state === 'Paid') ? (
                          <div className="grid grid-cols-2 gap-2">
                            {/* DATE OF APPROVAL - can only be edited for state approved and paid */}
                            <FormField name="dateOfApproval">
                              {(control) => (
                                <DatePicker
                                  label={t('projectControl.dateOfApproval')}
                                  minDate={getMinDate(dateOfAudit)}
                                  handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                  size="small"
                                  disabled={invoice.state !== 'Approved' && invoice.state !== 'Paid'}
                                  {...control}
                                  onChange={(value) => {
                                    if (updateUnsavedData) {
                                      if (value && defaultFormValues.dateOfApproval) {
                                        updateUnsavedData(
                                          'dateOfApproval',
                                          formatDateOnly(value) === defaultFormValues.dateOfApproval,
                                        );
                                      } else {
                                        updateUnsavedData('dateOfApproval', value === defaultFormValues.dateOfApproval);
                                      }
                                    }
                                    control.onChange(value);
                                  }}
                                />
                              )}
                            </FormField>
                            {/* PAYMENT DATE - can only be edited for state paid */}
                            <FormField name="paymentDate">
                              {(control) => (
                                <DatePicker
                                  label={t('projectControl.paymentDate')}
                                  minDate={getMinDate(dateOfApproval)}
                                  handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                  size="small"
                                  disabled={invoice.state !== 'Paid'}
                                  {...control}
                                  onChange={(value) => {
                                    if (updateUnsavedData) {
                                      if (value && defaultFormValues.paymentDate) {
                                        updateUnsavedData(
                                          'paymentDate',
                                          formatDateOnly(value) === defaultFormValues.paymentDate,
                                        );
                                      } else {
                                        updateUnsavedData('paymentDate', value === defaultFormValues.paymentDate);
                                      }
                                    }
                                    control.onChange(value);
                                  }}
                                />
                              )}
                            </FormField>
                          </div>
                        ) : undefined
                      }
                    >
                      <div className="grid grid-cols-2 gap-2">
                        <DocumentViewerFileDataSet
                          label={t('projectControl.dateOfApproval')}
                          subtitle={dateOfApproval ? formatDate(dateOfApproval) : '-'}
                          className={
                            dateOfApproval &&
                            formatDateOnly(new Date(dateOfApproval)) !== defaultFormValues.dateOfApproval
                              ? 'text-secondary'
                              : undefined
                          }
                        />
                        <DocumentViewerFileDataSet
                          label={t('projectControl.paymentDate')}
                          subtitle={paymentDate ? formatDate(paymentDate) : '-'}
                          className={
                            paymentDate && formatDateOnly(new Date(paymentDate)) !== defaultFormValues.paymentDate
                              ? 'text-secondary'
                              : undefined
                          }
                        />
                      </div>
                    </DocumentViewerFileDataInlineEdit>
                  </>
                )}
              </FormWatch>
            </div>
          )}
        </>
      )}
    </FormWatch>
  );
};
