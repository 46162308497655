import { TemplateScenarioShortReadModel, useApiGetTemplateScenariosQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SettingsHeader } from '../SettingsHeader';
import { useState } from 'react';
import { LoadingIndicator, SettingsAddButton, SlideOver } from '@client/shared/toolkit';
import { TemplateScenarioRow } from './TemplateScenarioRow';
import { TemplateScenarioSlideOver } from './TemplateScenarioSlideOver';

export const TemplateScenariosList = () => {
  const { t } = useTranslation();
  const { templateId } = useParams();

  const { data: scenarioData, isFetching } = useApiGetTemplateScenariosQuery({ templateId: templateId ?? '' });
  const scenarios: TemplateScenarioShortReadModel[] = scenarioData?.scenarios ?? [];

  const [selectedScenario, setSelectedScenario] = useState<TemplateScenarioShortReadModel | undefined>();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleAdd = () => {
    setShowAdd(true);
    setShowDetails(true);
  };

  const handleEdit = (scenario: TemplateScenarioShortReadModel) => {
    setSelectedScenario(scenario);
    setShowDetails(true);
  };

  const closeSlideOver = () => {
    setShowAdd(false);
    setShowDetails(false);
  };

  return (
    <div className="w-full p-8">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('projectVariants.historyFilterVariants')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 pl-12 mt-4 mb-2">
          <div className="w-4/12">{t('templates.catalogTemplateNameColumnTitle')}</div>
        </div>

        {isFetching === true && <LoadingIndicator text={t('project.loadingVariantData')} />}

        <div className="border-t border-gray-200">
          {scenarios
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .map((scenario, idx) => (
              <div key={`template-scenario-row-${idx}`} className="border-b border-gray-200">
                <TemplateScenarioRow scenario={scenario} handleEdit={handleEdit} searchValue={searchText} />
              </div>
            ))}
        </div>

        <SettingsAddButton onClick={handleAdd} />
      </div>

      <SlideOver isOpen={showDetails} onClose={closeSlideOver} onAfterLeave={() => setSelectedScenario(undefined)}>
        <TemplateScenarioSlideOver
          templateId={templateId ?? ''}
          scenario={selectedScenario}
          isAddMode={showAdd}
          onClose={closeSlideOver}
        />
      </SlideOver>
    </div>
  );
};
