import { BenchmarkingValuationModal } from '@client/project/shared';
import { useLoadedVariant } from '@client/project/store';
import { FormulaElementType, useApiGetBenchmarkProjectsQuery } from '@client/shared/api';
import {
  Modal,
  ModalOnCloseProps,
  WizardSelectListItemProps,
  ArtificialIntelligenceOutlinedIcon,
  KeyDbOutlinedIcon,
  EngineeringOutlinedIcon,
} from '@client/shared/toolkit';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface FormulaEditorBenchmarkModalProps extends ModalOnCloseProps {
  catalogElementType: FormulaElementType;
  catalogElementId: string;
  isOpen: boolean;
  onClose: () => void;
  addBenchmarkValueToFormula: (value: string) => void;
}

export const FormulaEditorBenchmarkModal = ({
  catalogElementType,
  catalogElementId,
  isOpen,
  onClose,
  addBenchmarkValueToFormula,
}: FormulaEditorBenchmarkModalProps) => {
  const { t } = useTranslation();

  const loadedVariant = useLoadedVariant();
  const loadedVariantPayload = loadedVariant.data?.calculationModel?.payload;

  const catalogId = useMemo(() => {
    switch (catalogElementType) {
      case 'CostElement':
        return loadedVariantPayload?.costCatalogId;
      case 'RiskElement':
        return loadedVariantPayload?.riskCatalogId;
      case 'EarningsElement':
        return loadedVariantPayload?.riskCatalogId;
      default:
        return null;
    }
  }, [catalogElementType, loadedVariantPayload]);

  const { data: benchmarkProjects } = useApiGetBenchmarkProjectsQuery(
    {
      catalogId: catalogId ?? '',
      catalogElementId: catalogElementId,
    },
    {
      skip: !catalogId || !catalogElementId || !isOpen,
      refetchOnMountOrArgChange: true,
    },
  );

  const [currentStep, setCurrentStep] = useState(0);

  const referenceMethodItems: WizardSelectListItemProps[] = [
    {
      title: 'PROBIS ML',
      subtitle: t('projectCalculate.benchmarkingProbisMlDescription'),
      icon: <ArtificialIntelligenceOutlinedIcon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
      disabled: true,
      children: <div className="absolute top-2 right-2 font-bold text-xxs text-green-400">{t('app.comingSoon')}</div>,
    },
    {
      title: t('projectCalculate.benchmarkingRegionalFactor'),
      subtitle: t('projectCalculate.benchmarkingRegionalFactorDescription'),
      icon: <EngineeringOutlinedIcon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
      handleClick: () => setCurrentStep(1),
      disabled: false,
    },
    {
      title: t('projectCalculate.benchmarkingIndividualFactors'),
      subtitle: t('projectCalculate.benchmarkingIndividualFactorsDescription'),
      icon: <KeyDbOutlinedIcon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
      disabled: true,
      children: <div className="absolute top-2 right-2 font-bold text-xxs text-green-400">{t('app.comingSoon')}</div>,
    },
  ];

  const handleOnClose = () => {
    onClose();
    setCurrentStep(0);
  };

  const handleAdd = (value: string) => {
    onClose();
    addBenchmarkValueToFormula(value);
    setCurrentStep(0);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} variant="full-height">
      <BenchmarkingValuationModal
        referenceMethodItems={referenceMethodItems}
        benchmarkProjects={benchmarkProjects?.benchmarkProjects}
        handleOnClose={handleOnClose}
        handleAdd={handleAdd}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    </Modal>
  );
};
