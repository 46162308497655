import { DocumentViewer, DocumentPositionBoundingBoxReadModel } from '@client/shared/toolkit';
import React, { Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiEvalResultReadModel, AiEvalValuesReadModel, ContractReadModel, InvoiceReadModel } from '@client/shared/api';
import { InvoiceDocumentFileData, InvoiceDocumentFileDataRef } from './InvoiceDocumentReview';
import cn from 'classnames';

export interface InvoiceDocumentViewerProps {
  invoiceDocument?: AiEvalResultReadModel;
  fileData?: AiEvalValuesReadModel | null;
  file: File | null;
  setFile: (file: File) => void;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  pdfFile: string | null;
  imageWidth: number;
  imageHeight: number;
  allBoundingBoxes?: {
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null;
  contract?: ContractReadModel | null;
  onUpload: () => void;
  invoice?: InvoiceReadModel | null;
  loadedProjectId?: string;
  additionalMenu?: boolean;
  invoiceDocumentFileDataRef?: Ref<InvoiceDocumentFileDataRef>
  setIsFormValid?: (isValid: boolean) => void
  onClose: () => void
  className?: string
  showForm?: boolean
  setIsLoading?: (isLoading: boolean) => void
}

export const InvoiceDocumentViewer = (props: InvoiceDocumentViewerProps) => {
  const {
    invoiceDocument,
    fileData,
    file,
    pdfFile,
    boxes,
    imageHeight,
    imageWidth,
    allBoundingBoxes,
    invoiceDocumentFileDataRef,
    setIsFormValid,
    onClose,
    className,
    showForm = true,
    setIsLoading,
  } = props;
  const { t } = useTranslation();

  const [hoveredBox, setHoveredBox] = useState<number | null>(null);
  const [showAllBoxes, setShowAllBoxes] = useState(false);

  return (
    <div className={cn('flex flex-wrap', className)}>
      <div className={cn('w-full relative pb-8', showForm ? 'xl:w-2/3' : '')}>
        <div className="sticky top-0">
          <DocumentViewer
            menuOptions={{
              fullscreen: false,
            }}
            title={t('projectControl.invoice')}
            file={file}
            pdfFile={pdfFile}
            fileLoaded={fileData !== null}
            loadingMessage={t('projectControl.uploadInvoiceLoadingIndicator')}
            uploadDescription={t('projectControl.invoiceUploadMessage')}
            boxes={boxes}
            hoveredBox={hoveredBox}
            setHoveredBox={setHoveredBox}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            allBoundingBoxes={allBoundingBoxes}
            showAllBoxes={showAllBoxes}
          />
        </div>
      </div>
      {showForm && (
        <div className="flex-none w-full xl:w-1/3 pt-8 xl:pl-8 xl:pt-0 xl:min-w-[352px] flex flex-col gap-7">
          <InvoiceDocumentFileData
            invoiceDocument={invoiceDocument}
            fileData={fileData}
            allBoundingBoxes={allBoundingBoxes}
            hoveredBox={hoveredBox}
            boxes={boxes}
            setShowAllBoxes={() => setShowAllBoxes((prev) => !prev)}
            showAllBoxes={showAllBoxes}
            setHoveredBox={setHoveredBox}
            ref={invoiceDocumentFileDataRef}
            setIsFormValid={setIsFormValid}
            onClose={onClose}
            setIsLoading={setIsLoading}
          />
        </div>
      )}
    </div>
  );
};
