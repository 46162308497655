import { CalculationModelMetadata, CalculationModelReadModel, EarningsType } from '@client/shared/api';
import { DepositIcon, Modal, SlideOver } from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId, useReadOnly } from '@client/project/store';
import { EarningsElementSlideOver, PreselectedEarningsParent } from './EarningsElementSlideOver';
import { useTranslation } from 'react-i18next';
import { DecoratedElement, EarningElement } from '../hooks';
import { EarningsElementDeleteModal } from './EarningsElementDeleteModal';
import { useValidateCatalogHasRestrictions, useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from './Timeline';
import { OptionalColumn, SectionTitle } from './CalculateSectionHeader';
import { CalculateSectionHeaders } from './CalculateSectionHeaders';
import { CalculateSection } from './CalculateSection';
import { TimeLineDataContext } from './Timeline';
import { EditEarningsTimeLineMondeyDistributionModal, InflationCalcModal } from './Distribution';
import { CalculateEarningsRenderElements } from './Earnings';
import { getCalculateSectionHeight } from '../utils';
import { FeatureElementType, NewEarningsFeatureElementModal } from './FeatureElement';

interface CalculateEarningsProps {
  view: TimeLineView;
  variant?: CalculationModelReadModel;
  selectedVersionElements?: DecoratedElement<EarningElement>[];
  archivedVersions?: CalculationModelMetadata[];
  selectedVersionId: string;
  setSelectedVersionId: (VersionId: string) => void;
  optionalColumn: OptionalColumn;
  setOptionalColumn: (column: OptionalColumn) => void;
  obligoColumn: OptionalColumn;
  setObligoColumn: (column: OptionalColumn) => void;
  elements?: DecoratedElement<EarningElement>[];
  open?: boolean;
  onExpand: (catalogIds: string[]) => void;
  onCollapse: (level: number) => void;
  onCollapseContainer: (state: boolean) => void;
  expandedCatalogIds?: string[];
  barChartData?: boolean
  searchValue?: string
}

export const CalculateEarnings = (props: CalculateEarningsProps) => {
  const {
    view,
    variant,
    archivedVersions,
    selectedVersionId,
    setSelectedVersionId,
    optionalColumn,
    setOptionalColumn,
    obligoColumn,
    setObligoColumn,
    elements = [],
    onCollapse,
    expandedCatalogIds = [],
    open = true,
    onCollapseContainer,
    barChartData = false,
    searchValue,
  } = props;
  const { t } = useTranslation();

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  const catalogHasRestrictions = useValidateCatalogHasRestrictions(loadedProjectId ?? '', variant?.modelMetadata.earningsCatalogId);
  const disableEarnings = !useValidateProjectPermission(['EARNINGS_WRITE'], loadedProjectId ?? '');
  const isReadOnly = useReadOnly() || disableEarnings;

  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedElement, setSelectedElement] = useState<DecoratedElement<EarningElement> | undefined>();
  const [deleteElementId, setDeleteElementId] = useState<string | undefined>();
  const [preselectedParent, setPreselectedParent] = useState<PreselectedEarningsParent | undefined>();
  const [preselectedGroupId, setPreselectedGroupId] = useState<string | undefined | null>();
  const [preselectedEarningType, setPreselectedEarningType] = useState<EarningsType | undefined>();

  const [isMoneyDistributionModalOpen, setIsMoneyDistributionModalOpen] = useState(false)
  const [isOpenSelectNewElementModal, setIsOpenSelectNewElementModal] = useState(false);
  const [openCreateFeatureElementModal, setOpenCreateFeatureElementModal] = useState<FeatureElementType | null>(null);

  const { setSelectedTimelineElement, selectedTimelineElement } = useContext(TimeLineDataContext);

  const handleOpenSlideOver = (
    element: DecoratedElement<EarningElement> | undefined,
    preselectedParent: PreselectedEarningsParent | undefined
  ) => {
    if (element) {
      setSelectedElement(element);
    } else {
      setSelectedElement(undefined);
    }

    setPreselectedParent(preselectedParent);

    setIsOpenSlideOver(true);
  };

  const handleCloseDeleteElement = () => {
    setIsOpenDeleteModal(false);
    setDeleteElementId(undefined);
  };

  const handleOpenDeleteElement = (deleteElementId?: string | null) => {
    if (!deleteElementId) {
      return;
    }

    setDeleteElementId(deleteElementId);
    setIsOpenDeleteModal(true);
  };

  const handleCloseSlideOver = () => {
    setIsOpenSlideOver(false);
  };

  useEffect(() => {
    if (selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'earningElement') {
      setIsMoneyDistributionModalOpen(true)
    }
  }, [selectedTimelineElement, setSelectedTimelineElement]);

  useEffect(() => {
    if (!isMoneyDistributionModalOpen && setSelectedTimelineElement) {
      setSelectedTimelineElement(null)
    }
  }, [isMoneyDistributionModalOpen, setSelectedTimelineElement]);

  const handleOpenNewFeatureElementModal = (groupId?: string | null, earningType?: EarningsType) => {
    setPreselectedGroupId(groupId);
    setIsOpenSelectNewElementModal(true);
    setPreselectedEarningType(earningType);
  }

  const height = useMemo(() => {
    return getCalculateSectionHeight(elements, 0, 0, expandedCatalogIds);
  }, [expandedCatalogIds, elements]);

  return (
    <div className="relative flex cursor-default transition-[margin] duration-300">
      <div className="w-full" ref={containerRef}>
        <CalculateSectionHeaders
          view={view}
          archivedVersions={archivedVersions}
          selectedVersionId={selectedVersionId}
          setSelectedVersionId={setSelectedVersionId}
          optionalColumn={optionalColumn}
          setOptionalColumn={setOptionalColumn}
          obligoColumn={obligoColumn}
          setObligoColumn={setObligoColumn}
          title={SectionTitle.EARNINGS}
          icon={<DepositIcon className="w-7 h-7" />}
          onCollapse={onCollapse}
          onCollapseContainer={() => onCollapseContainer(!open)}
          showLevelToggles={elements.length > 0 && !searchValue}
        />
        <CalculateSection
          catalogHasRestrictions={catalogHasRestrictions}
          open={open}
          view={view}
          totalCost={variant?.modelMetadata.totalEarnings.value}
          bgColor="bg-earnings"
          totalColor="text-earnings"
          totalText={t('projectCalculate.calculateEarningsTotalEarnings')}
          hasBarChart={barChartData}
          height={elements.length > 0 ? height : undefined}
        >
          {elements.length > 0 ? (
            <CalculateEarningsRenderElements
              containerRef={containerRef}
              elements={elements}
              isReadOnly={isReadOnly}
              handleOpenSlideOver={handleOpenSlideOver}
              handleOpenDeleteElement={handleOpenDeleteElement}
              handleOpenNewFeatureElementModal={handleOpenNewFeatureElementModal}
              {...props}
            />
          ) : (
            <div className="text-lg font-bold p-4 h-12">{t('projectCalculate.NoElements')}</div>
          )}
        </CalculateSection>
      </div>
      <SlideOver
        isOpen={isOpenSlideOver}
        onClose={handleCloseSlideOver}
        onAfterLeave={() => setSelectedElement(undefined)}
      >
        <EarningsElementSlideOver
          onClose={handleCloseSlideOver}
          variantId={loadedVariantId}
          earningsCatalogId={variant?.payload.earningsCatalogId ?? undefined}
          disabled={isReadOnly}
          decoratedElement={selectedElement}
          preselectedParent={preselectedParent}
        />
      </SlideOver>

      <Modal isOpen={isOpenDeleteModal} onClose={handleCloseDeleteElement}>
        <EarningsElementDeleteModal
          elementId={deleteElementId}
          variantId={loadedVariantId}
          onClose={handleCloseDeleteElement}
        />
      </Modal>

      {selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'earningElement' && (
        <Modal isOpen={isMoneyDistributionModalOpen} onClose={() => setIsMoneyDistributionModalOpen(false)}>
          <EditEarningsTimeLineMondeyDistributionModal
            onClose={() => setIsMoneyDistributionModalOpen(false)}
            onSave={() => setIsMoneyDistributionModalOpen(false)}
            selectedElement={selectedTimelineElement.elem}
            disabled={isReadOnly}
            selectedDate={selectedTimelineElement.date}
          />
        </Modal>
      )}

      {/* Select New Feature Element */}
      <Modal
        isOpen={isOpenSelectNewElementModal}
        onClose={() => setIsOpenSelectNewElementModal(false)}
      >
        <NewEarningsFeatureElementModal
          onClose={() => {
            setIsOpenSelectNewElementModal(false);
            setPreselectedGroupId(undefined);
          }}
          onOpenSlideOver={(val: FeatureElementType) => {
            setIsOpenSelectNewElementModal(false);
            setOpenCreateFeatureElementModal(val);
          }}
        />
      </Modal>
      {/* Create New Feature Element */}
      <Modal
        isOpen={openCreateFeatureElementModal === 'inflation'}
        onClose={() => setOpenCreateFeatureElementModal(null)}
        onAfterLeave={() => setPreselectedGroupId(undefined)}
        variant="max"
      >
        {preselectedGroupId && (
          <InflationCalcModal
            catalogId={preselectedGroupId}
            onClose={() => setOpenCreateFeatureElementModal(null)}
            onSave={() => setOpenCreateFeatureElementModal(null)}
            type="earnings"
            earningsType={preselectedEarningType}
          />
        )}
      </Modal>
    </div>
  );
};
