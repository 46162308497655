import {formatNumber, isCurrencySymbol, isEmpty} from '@client/shared/utilities';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DecoratedElement, EarningElement} from '../hooks';
import {getUnitLabel} from '../utils';
import {CalculateElementGroupSum} from './CalculateElementGroupSum';
import { ContextMenuItem, PencilIcon, TrashIcon, AddIcon } from '@client/shared/toolkit';
import {ElementRowMenu} from './ElementRowMenu';
import {useLoadedProjectId} from '@client/project/store';
import {useValidateProjectPermission} from '@client/shared/permissions';
import {CalculateEarningsMultiple} from './CalculateEarningsMultiple';
import {TimeLineView} from './Timeline';
import {Column} from './CalculateContainer';
import {OptionalColumn} from './CalculateSectionHeader';
import {CalculateColumns} from "./CalculateColumns";
import {
  CalculateGroupElementInformationColumn,
  CalculateInformationColumnContent
} from "./CalculateGroupElementInformationColumn";
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';
import { CalculationModelEarningsGroupElement } from '@client/shared/api';

interface EarningsGroupElementProps {
  item: DecoratedElement<EarningElement>;
  selectedVersionId: string;
  expanded?: boolean;
  onClick?: () => void;
  onCodeClick?: () => void;
  onRemoveBudget?: () => void;
  onNewElement?: (type: 'earnings' | 'feature') => void;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  column?: Column;
  view: TimeLineView;
  isReadOnly:boolean;
}

export const EarningsGroupElement = ({
  isReadOnly,
  expanded = false,
  item: {
    children,
    element: { group, showError },
    level,
  },
  onClick,
  onCodeClick,
  onNewElement,
  onRemoveBudget,
  view,
  optionalColumn,
  obligoColumn
}: EarningsGroupElementProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWriteEarnings = useValidateProjectPermission(['EARNINGS_WRITE'], loadedProjectId ?? '');
  const canDeleteEarnings = useValidateProjectPermission(['EARNINGS_DELETE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const handleOnCodeClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onCodeClick?.();
  };

  const childrenErrors = (children: DecoratedElement<EarningElement>[]): boolean => {
    return children.some((child) => {
      if (child.element.earningElement && child.element.earningElement?.timelineErrors?.length > 0) {
        return true;
      } else if (
        child.element.group &&
        (child.element.group.calculationState === 'Overflow' ||
          (child.element.group.timelineElement && child.element.group.timelineElement?.errors.length > 0))
      ) {
        return true;
      } else if (child.children.length > 0) {
        return childrenErrors(child.children);
      } else {
        return false;
      }
    });
  };

  const getEarningsCode = (group : CalculationModelEarningsGroupElement | undefined) => {
    if (!group){
      return undefined;
    }

    if (!isEmpty(group.groupId)){
      return group.code;
    }

    switch (group.earningsType){
      case 'SaleRevenue': return t('projectCalculate.earningsTypes.SaleRevenueCode');
      case 'RentRevenue': return t('projectCalculate.earningsTypes.RentRevenueCode');
      case 'OperatingRevenue': return t('projectCalculate.earningsTypes.OperatingRevenueCode');
      case 'ServiceRevenue': return t('projectCalculate.earningsTypes.ServiceRevenueCode');
      default: return undefined;
    }
  };

  const getEarningsTitle = (group : CalculationModelEarningsGroupElement | undefined) => {
    if (!group){
      return undefined;
    }

    if (!isEmpty(group.groupId)){
      return group.description;
    }

    switch (group.earningsType){
      case 'SaleRevenue': return t('projectCalculate.earningsTypes.SaleRevenue');
      case 'RentRevenue': return t('projectCalculate.earningsTypes.RentRevenue');
      case 'OperatingRevenue': return t('projectCalculate.earningsTypes.OperatingRevenue');
      case 'ServiceRevenue': return t('projectCalculate.earningsTypes.ServiceRevenue');
      default: return undefined;
    }
  };

  const showExpanded = useMemo(() => {
    return expanded && children.some((e) => !!e.element.earningElement);
  }, [expanded, children]);

  const groupMenuItems = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (onNewElement) {
      items.push({
        isDisabled: !canWriteEarnings,
        label: t('projectCalculate.rowMenu.newEarningElementTitle'),
        subtitle: t('projectCalculate.rowMenu.newEarningElementSubTitle'),
        onClick: () => onNewElement('earnings'),
        icon: <AddIcon />,
      });
      items.push({
        isDisabled: !canWriteEarnings,
        label: t('projectCalculate.featureElement'),
        subtitle: t('projectCalculate.featureElementSubtitle'),
        onClick: () => onNewElement('feature'),
        icon: <AddIcon />,
      });
    }
    if (onClick) {
      items.push({
        isDisabled: !canWriteEarnings,
        label: t('projectCalculate.rowMenu.updateEarningsGroupElementTitle'),
        subtitle: t('projectCalculate.rowMenu.updateEarningsGroupElementSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
    }
    if (onRemoveBudget) {
      items.push({
        isDisabled: !canDeleteEarnings,
        label: t('projectCalculate.rowMenu.removeEarningsGroupBudgetTitle'),
        subtitle: t('projectCalculate.rowMenu.removeEarningsGroupBudgetSubTitle'),
        onClick: onRemoveBudget,
        icon: <TrashIcon />,
      });
    }
    return items;
  }, [t, onNewElement, onClick, onRemoveBudget, canWriteEarnings, canDeleteEarnings]);

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={(
        <CalculateGroupElementInformationColumn
          level={level}
          view={view}
          handleOnClick={onClick ? handleOnClick : undefined}
          leftColumn={(
            <CalculateInformationColumnContent
              showCode={!!getEarningsCode(group)}
              code={getEarningsCode(group)}
              level={level}
              handleOnCodeClick={handleOnCodeClick}
              showExpanded={showExpanded}
              showError={showError || childrenErrors(children)}
              title={getEarningsTitle(group)}
            >
              {(group?.hasFormula) && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          )}
          rightColumn={(
            <>
              <div className="hidden md:flex w-2/12 text-right flex-row justify-end items-center leading-tight">
                <div>
                  {isCurrencySymbol(group?.unit) ? <FormattedCurrency amount={group?.amount} /> : formatNumber(group?.amount)}
                  {group?.unit && !isCurrencySymbol(group.unit) && (
                    <div className="text-xs font-light">{getUnitLabel(group.unit)}</div>
                  )}
                </div>
              </div>
              <div className="hidden w-3/12 md:flex text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={group?.unitPrice} options={{ maxDigits: 2 }} />
                  {group?.unit && !isCurrencySymbol(group.unit) && group.unit !== '%' && (
                    <div className="text-xs font-light">
                      {t('projectCalculate.earningsElementLabelPer')} {getUnitLabel(group?.unit)}
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden w-2/12 md:flex text-right flex-row justify-end items-center leading-tight">
                {group?.multipleCalculationState ? (
                  <CalculateEarningsMultiple
                    calculationState={group?.multipleCalculationState}
                    multiple={group?.multiple}
                    calculatedMultiple={group?.calculatedMultiple}
                    effectiveMultiple={group?.effectiveMultiple}
                    multipleDifference={group?.multipleDifference}
                  />
                ) : (
                  <div> - </div>
                )}
              </div>
              {group && (
                <CalculateElementGroupSum
                  classNames={`w-5/12`}
                  calculationState={group?.calculationState}
                  calculatedValue={group?.calculatedValue}
                  effectiveValue={group?.effectiveValue}
                  difference={group?.difference}
                  total={group?.total}
                  overflow={group?.overflow}
                  remainder={group?.remainder}
                  column={Column.INFORMATION}
                />
              )}
            </>
          )}
          menu={!isReadOnly && (
            <ElementRowMenu items={groupMenuItems} />
          )}
        />
      )}
      currentColumnContent={(
        <CalculateElementRowFinanceColumn
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          {group?.calculationState === 'Empty' ? (
            <div>&nbsp;</div>
          ) : (
            <>
              {group?.calculationState === 'JustTotal' && !!group?.total && (
                <FormattedCurrency amount={group?.total} />
              )}
              {group?.calculationState === 'JustCalculated' && !!group.calculatedValue && (
                <FormattedCurrency amount={group?.calculatedValue} />
              )}
              {group?.calculationState !== 'JustCalculated' && group?.calculationState !== 'JustTotal' && !!group?.effectiveValue && (
                <FormattedCurrency amount={group?.effectiveValue} />
              )}
            </>
          )}
        </CalculateElementRowFinanceColumn>
      )}
      obligoColumnContent={(
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={group}
          type="earningsGroup"
        />
      )}
      optionalColumnContent={(
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={group}
          type="earningsGroup"
        />
      )}
    />
  )
};
