import {
  ShortCommitmentReadModel,
  ShortContractReadModel,
  useApiPostUpdateCommitmentMutation,
  useApiGetCommitmentQuery,
  useApiGetContractsQuery,
  CommitmentReadModel,
} from '@client/shared/api';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import {
  Button,
  SlideOver,
  LoadingIndicator,
  FormRefHandle,
  ListTitle,
  DecoratedCard,
  BrokenLinkIcon,
  AddButton,
  Card,
  CardDataSet,
  CardActionButton,
  PencilIcon,
  Modal,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CommitmentAddContractSlideOver, CommitmentEditSlideOver, CommitmentFormValidationValues } from '.';
import { ContractBudgeting, ContractEditSlideOver, TimeLineDistributionsModal } from '../..';

interface CommitmentTabProps {
  shortCommitment?: ShortCommitmentReadModel | null;
  canWrite: boolean;
  commitment?: CommitmentReadModel | null;
  contracts?: ShortContractReadModel[] | null;
  commitmentId?: string;
  setChildSlideOverIsOpen: (val: boolean) => void;
}

export const CommitmentTab = ({
  setChildSlideOverIsOpen,
  canWrite,
  shortCommitment,
  contracts,
  commitmentId,
}: CommitmentTabProps) => {
  const { t } = useTranslation();
  const [isOpenAddContractSlideOver, setIsOpenAddContractSlideOver] = useState(false);

  const formRef = useRef<FormRefHandle<CommitmentFormValidationValues>>();

  const [postUpdateCommitment, { isLoading: isUpdating }] = useApiPostUpdateCommitmentMutation();

  const [isOpenEditCommitmentSlideOver, setIsOpenEditCommitmentSlideOver] = useState(false);
  const [isOpenEditContractSlideOver, setIsOpenEditContractSlideOver] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState<string | null>(null);
  const [isOpenCalculatedDistribution, setIsOpenCalculatedDistribution] = useState(false);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: commitment, isFetching: isLoadingCommitment } = useApiGetCommitmentQuery(
    {
      commitmentId: commitmentId ?? shortCommitment?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || (!shortCommitment?.id && !commitmentId),
    },
  );

  const { data: loadedContracts, isFetching: isFetchingContracts } = useApiGetContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || typeof contracts !== 'undefined',
    },
  );

  const fetchedContracts = useMemo(() => {
    return loadedContracts?.contracts ?? contracts ?? [];
  }, [loadedContracts?.contracts, contracts]);

  const handleDeleteContract = async (contract: string) => {
    if (loadedVariantId && loadedProjectId && commitment) {
      try {
        await safeMutation(
          postUpdateCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: commitment.code,
              name: commitment.name,
              description: commitment.description,
              addedContracts: [],
              deletedContracts: commitment.contracts.map((c) => c.id).filter((c) => c === contract),
              addedBudgetAssignments: [],
              updatedBudgetAssignments: [],
              deletedBudgetAssignments: [],
            },
          },
          isUpdating,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const addContract = () => {
    setChildSlideOverIsOpen(true);
    setIsOpenAddContractSlideOver(true);
  };

  const defaultFormValues = useMemo(() => {
    return {
      id: commitment?.id ?? '',
      name: commitment?.name ?? '',
      code: commitment?.code ?? '',
      description: commitment?.description ?? '',
    };
  }, [commitment]);

  useEffect(() => {
    formRef.current?.resetForm(defaultFormValues);
  }, [defaultFormValues]);

  return (
    <>
      {(isLoadingCommitment || isUpdating || isFetchingContracts) && (
        <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
      )}

      <div className="mb-4 w-full flex flex-col flex-grow min-h-0 gap-9">
        <Card className="relative flex flex-col gap-8" divider={false}>
          <div className="flex">
            <CardDataSet label={t('common.code')} className="w-1/2">
              <span className="font-bold text-lg">{commitment?.code}</span>
            </CardDataSet>
            <CardDataSet label={t('common.name')} className="w-1/2">
              <span className="font-bold text-lg">{commitment?.name}</span>
            </CardDataSet>
          </div>
          <CardDataSet label={t('common.description')}>
            <span className="font-bold text-lg">{commitment?.description ?? '-'}</span>
          </CardDataSet>

          <CardActionButton
            icon={<PencilIcon />}
            onClick={() => {
              setChildSlideOverIsOpen(true);
              setIsOpenEditCommitmentSlideOver(true);
            }}
          />
        </Card>
        <div>
          <DecoratedCard shadowVariant="normal">
            <DecoratedCard.Content>
              <ListTitle title={t('projectContract.commitmentContracts')} />
              <div className="flex flex-col mt-2 bg-white divide-gray-100 divide-y px-4 mb-6">
                {commitment && commitment?.contracts?.length > 0 ? (
                  commitment?.contracts?.map((contract) => {
                    return (
                      <div
                        className="flex justify-between items-center h-10 bg-white px-4 text-sm hover:bg-gray-50 cursor-pointer"
                        key={contract.id}
                        onClick={() => {
                          setSelectedContractId(contract.id);
                          setIsOpenEditContractSlideOver(true);
                        }}
                      >
                        <div>
                          <span className="font-medium mr-2">{contract?.code}</span>
                          <span className="text-gray-500">{contract?.name}</span>
                        </div>
                        {canWrite && (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleDeleteContract(contract.id);
                            }}
                            className="w-6 cursor-pointer hover:opacity-60"
                          >
                            <BrokenLinkIcon className="w-full h-full" />
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <span className="text-gray-500">{t('projectContract.noContracts')}</span>
                )}
                {commitment?.calculatedTimeline && (
                  <div className="flex flex-row-reverse">
                    <Button
                      variant="text"
                      className="text-sm mt-2"
                      onClick={() => setIsOpenCalculatedDistribution(!isOpenCalculatedDistribution)}
                    >
                      {t('projectCalculate.ShowCalculatedTimelineButton')}
                    </Button>
                  </div>
                )}
              </div>
            </DecoratedCard.Content>
          </DecoratedCard>
          {commitment && (
            <div className="flex w-full items-center relative">
              <AddButton onClick={addContract} className="absolute right-3 top-auto" />
              <span className="text-sky-700 hover:text-sky-500 cursor-pointer font-bold" onClick={addContract} />
            </div>
          )}
          {!isLoadingCommitment && <ContractBudgeting commitment={commitment} />}
        </div>
      </div>

      {commitment && (
        <>
          <SlideOver
            isOpen={isOpenAddContractSlideOver}
            onClose={() => {
              setChildSlideOverIsOpen(false);
              setIsOpenAddContractSlideOver(false);
            }}
          >
            <CommitmentAddContractSlideOver
              commitment={commitment}
              contracts={fetchedContracts}
              onClose={() => {
                setChildSlideOverIsOpen(false);
                setIsOpenAddContractSlideOver(false);
              }}
            />
          </SlideOver>
          <SlideOver
            isOpen={isOpenEditCommitmentSlideOver}
            onClose={() => {
              setIsOpenEditCommitmentSlideOver(false);
              setChildSlideOverIsOpen(false);
            }}
          >
            <CommitmentEditSlideOver
              commitment={commitment}
              onClose={() => {
                setIsOpenEditCommitmentSlideOver(false);
                setChildSlideOverIsOpen(false);
              }}
            />
          </SlideOver>
          {commitment.calculatedTimeline && (
            <Modal isOpen={isOpenCalculatedDistribution} onClose={() => setIsOpenCalculatedDistribution(false)}>
              <TimeLineDistributionsModal
                useExistingEffectiveDistributionValues={true}
                timeline={commitment.calculatedTimeline}
                onClose={() => setIsOpenCalculatedDistribution(false)}
              />
            </Modal>
          )}
          {/* Edit contract */}
          <SlideOver
            isOpen={isOpenEditContractSlideOver}
            onClose={() => {
              setIsOpenEditContractSlideOver(false);
            }}
          >
            <ContractEditSlideOver
              contractId={selectedContractId ?? ''}
              onClose={() => {
                setIsOpenEditContractSlideOver(false);
              }}
            />
          </SlideOver>
        </>
      )}
    </>
  );
};
