import { FormattedCurrency } from '@client/project/shared';
import { Collapsible, ToggleButton } from '@client/shared/toolkit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CashFlowGroupReportReadModel, CashFlowGroupReportSectionResponse } from '@client/shared/api';
import { useMemo } from 'react';

interface ReportCashflowTableFixedProps {
  data: CashFlowGroupReportSectionResponse | undefined;
  expandedElements: string[];
  title: string;
  titleColor: string;
  sectionExpanded: boolean;
  toggleSection: () => void;
  onExpand: (ids: string[]) => void;
}

export const ReportCashflowTableFixed = ({
  data,
  expandedElements,
  onExpand,
  sectionExpanded,
  toggleSection,
  title,
  titleColor,
}: ReportCashflowTableFixedProps) => {
  const { t } = useTranslation();

  const columns: Column[] = useMemo(
    () => [
      {
        label: t('reporting.tableBudget'),
        total: data?.budget,
      },
      {
        label: t('reporting.tableActual'),
        total: data?.actual,
      },
      {
        label: t('reporting.tableDelta'),
        total: data?.delta,
      },
      {
        label: t('reporting.tableSum'),
        total: data?.sum,
      },
    ],
    [t, data],
  );

  const elements = data?.data ?? [];
  return (
    <>
      <div className="border-b border-b-slate-300 h-20">
        <HeaderFooter
          columns={columns}
          title={title}
          titleColor={titleColor}
          toggle={toggleSection}
          sectionExpanded={sectionExpanded}
        />
      </div>
      <Collapsible open={sectionExpanded}>
        {elements.map((element, i) => (
          <ReportCashflowCollapsingRow
            rowData={element}
            key={`report-cashflow-${element.code}-${i}`}
            expandedElements={expandedElements}
            onExpand={onExpand}
          />
        ))}
      </Collapsible>
      <div className="bg-white">
        <HeaderFooter columns={columns} isFooter />
      </div>
    </>
  );
};

interface Column {
  label: string;
  total: number | undefined | null;
}

interface HeaderFooterProps {
  columns: Column[];
  isFooter?: boolean;
  toggle?: () => void;
  title?: string;
  titleColor?: string;
  sectionExpanded?: boolean;
}

const HeaderFooter = ({ columns, isFooter = false, toggle, title, titleColor, sectionExpanded }: HeaderFooterProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full h-full">
      <div className="flex">
        <div
          className={cn('h-full flex text-[11px] text-slate-500', {
            'items-start flex-grow ': isFooter,
          })}
        >
          {isFooter ? (
            <div className="flex-grow">
              <div className="flex items-center h-11 text-[18px] font-bold text-red-700 truncate border-b border-slate-200 border-dotted pl-4">
                <div className="w-8 flex-none">&nbsp;</div>
                <div className="w-[422px] flex-none">{t('reporting.cashflowReport.totalInvestitionCost')}</div>
              </div>
            </div>
          ) : (
            <div className="flex items-end pl-4 pr-8">
              <div className="flex flex-col justify-between">
                <div className="text-[22px] font-bold flex truncate">
                  <ToggleButton onClick={toggle} open={sectionExpanded ?? false} />

                  <div className="w-2 flex-none">&nbsp;</div>
                  <div className={titleColor}>{title}</div>
                </div>
                <div className="pl-8 w-[422px] flex-none flex gap-2">
                  <div className="flex-none">&nbsp;</div>
                  <div>{t('reporting.tableColumnId')}</div>
                  <div className="truncate">{t('reporting.tableColumnDescription')}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={cn('h-full flex-1', {
          'pt-8': !isFooter,
        })}
      >
        <div className="grid grid-cols-4 items-start justify-end pb-1 h-full">
          {columns?.map((column, index) => (
            <div
              className={cn('flex justify-end text-right font-bold text-[15px] truncate border-r border-slate-300', {
                'items-end border-dashed': isFooter,
                'h-full px-4 ': !isFooter,
              })}
              key={`report-detail-column-${isFooter ? 'footer' : 'header'}-${index}`}
            >
              {isFooter ? (
                <div className="flex-grow">
                  <div className="flex items-center justify-end px-4 h-11 text-[14px] font-bold truncate border-b border-slate-200 border-dotted">
                    <FormattedCurrency amount={column.total} />
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-end">{column.label}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface ReportCashflowCollapsingRowProps {
  rowData: CashFlowGroupReportReadModel;
  onExpand: (ids: string[]) => void;
  level?: number;
  expandedElements: string[];
}

const ReportCashflowCollapsingRow = ({
  level = 0,
  rowData,
  onExpand,
  expandedElements,
}: ReportCashflowCollapsingRowProps) => {
  const hasChildren = rowData?.children && rowData.children.length > 0;
  const expanded = expandedElements.includes(rowData?.uid ?? '');

  return (
    <>
      <div
        className={cn('flex items-center hover:bg-slate-50 transition-colors pl-4', {
          'bg-gray-50 font-semibold border-b border-slate-300 text-[14px]': level === 0,
          'bg-white border-b border-dotted border-slate-300 text-[12px]': level > 0,
        })}
      >
        {hasChildren ? (
          <ToggleButton
            className="flex-none"
            open={expanded}
            onClick={() =>
              onExpand(
                expandedElements.includes(rowData?.uid ?? '')
                  ? expandedElements.filter((x) => x !== rowData?.uid)
                  : [...expandedElements, rowData?.uid],
              )
            }
          />
        ) : (
          <div className="w-8 flex-none">&nbsp;</div>
        )}
        <div className="flex flex-grow h-10 items-center">
          <div
            className="w-[422px] flex-none flex gap-2 overflow-hidden"
            style={{ paddingLeft: level * 10 }}
            title={`${rowData.code} - ${rowData.name}`}
          >
            {rowData.code && (
              <div className="h-full flex items-center justify-end py-2 text-[12px] flex-none max-w-full">
                <span className="truncate">{rowData.code}</span>
              </div>
            )}
            <div className="h-full flex items-center justify-start py-2 text-[12px] truncate bg flex-1">
              <span className="overflow-hidden text-ellipsis">{rowData.name}</span>
            </div>
          </div>
          <div className="grid grid-cols-4 flex-1">
            <div className="h-full flex items-center justify-end py-2 text-right px-4 border-r border-dashed border-slate-300">
              <FormattedCurrency amount={rowData.budget} />
            </div>
            <div className="h-full flex items-center justify-end py-2 text-right px-4 border-r border-dashed border-slate-300">
              <FormattedCurrency amount={rowData.actual} />
            </div>
            <div className="h-full flex items-center justify-end py-2 text-right px-4 border-r border-dashed border-slate-300">
              <FormattedCurrency amount={rowData.delta} />
            </div>
            <div className="h-full flex items-center justify-end py-2 text-right px-4 border-r border-dashed border-slate-300">
              <FormattedCurrency amount={rowData.sum} />
            </div>
          </div>
        </div>
      </div>

      {hasChildren && (
        <Collapsible open={expanded}>
          {rowData.children?.map((child, index) => (
            <ReportCashflowCollapsingRow
              key={index}
              rowData={child}
              level={level + 1}
              onExpand={onExpand}
              expandedElements={expandedElements}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};
