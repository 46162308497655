import {
  Button,
  DocumentPositionBoundingBoxReadModel,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabOptions,
  SlideOverWithTabs,
} from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDocumentFileDataRef, InvoiceDocumentViewer, InvoiceEditContext } from '..';
import {
  AiEvalValuesReadModel,
  ContractReadModel,
  InvoiceReadModel,
  useApiGetAiEvalDocumentPdfFileQuery,
  useApiGetAiEvalResultQuery,
  useApiPostReviewAiEvalDocumentWorkflowTaskMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { safeMutation } from '@client/shared/utilities';

export interface InvoiceDocumentReviewProps extends SlideOverOnCloseProps {
  contract?: ContractReadModel | null;
  invoice?: InvoiceReadModel;
}

export const InvoiceDocumentReview = (props: InvoiceDocumentReviewProps) => {
  const { onClose, contract, invoice } = props;
  const { t } = useTranslation();
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [fileData, setFileData] = useState<AiEvalValuesReadModel | null | undefined>(null);
  const [boxes] = useState<(DocumentPositionBoundingBoxReadModel | null)[]>([]);
  const [imageWidth] = useState(0);
  const [imageHeight] = useState(0);
  const [allData] = useState<{
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null>(null);

  const { unsavedData, isValid, setIsValid } = useContext(InvoiceEditContext);


  const invoiceDocumentFileDataRef = useRef<InvoiceDocumentFileDataRef>(null);


  const [isLoading, setIsLoading] = useState(false);

  const createInvoice = async () => {
    try {
      setIsValid(false);
      const isValid = await invoiceDocumentFileDataRef.current?.createInvoice();
      return isValid;
    } catch (e) {
      console.error(e);
    }
  };

  const saveInvoice = async () => {
    try {
      setIsValid(false);
      const isValid = await invoiceDocumentFileDataRef.current?.saveInvoice();
      return isValid;
    } catch (e) {
      console.error(e);
    }
  };

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  if (!documentId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
  }

  const { data: fetchedFile, isFetching: isFetchingFile } = useApiGetAiEvalDocumentPdfFileQuery(
    {
      documentId: documentId ?? '',
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: !documentId || !loadedProjectId,
    },
  );

  const {
    data: processedData,
    isFetching: isFetchingProcessedData,
    isError,
  } = useApiGetAiEvalResultQuery(
    {
      documentId: documentId ?? '',
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !documentId || !loadedProjectId,
    },
  );

  const [reviewWorkflow, { isLoading: reviewingWorkflow }] = useApiPostReviewAiEvalDocumentWorkflowTaskMutation();

  const handleReviewWorkflow = async (comment: string | null) => {
    if (processedData?.document?.hasWorkflow) {
      try {
        const validMetadata = await saveInvoice();
        if (validMetadata) {
          const isValid = await createInvoice();
          if (isValid) {
            await safeMutation(
              reviewWorkflow,
              {
                projectId: loadedProjectId ?? '',
                calculationModelId: loadedVariantId ?? '',
                body: {
                  documentId: documentId ?? '',
                  taskCompletionStatus: 'Accepted',
                  comment: comment !== '' ? comment : null,
                },
              },
              reviewingWorkflow,
            );
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (fetchedFile) {
      setPdfFile(fetchedFile);
    }
  }, [fetchedFile]);

  useEffect(() => {
    if (isError || (processedData && processedData?.document.state !== 'Succeeded')) {
      navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
    }
    if (processedData?.result) {
      setFileData(processedData.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedData, isError]);

  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    return [
      {
        header: documentId ? t('projectControl.invoiceTabAudit') : t('common.upload'),
        name: 'audit',
        panel: (
          <InvoiceDocumentViewer
            invoiceDocument={processedData}
            fileData={fileData}
            file={file}
            pdfFile={pdfFile}
            setFile={setFile}
            boxes={boxes}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            allBoundingBoxes={allData}
            contract={contract}
            onUpload={() => onClose(false)}
            invoice={invoice}
            loadedProjectId={loadedProjectId ?? ''}
            invoiceDocumentFileDataRef={invoiceDocumentFileDataRef}
            setIsFormValid={setIsValid}
            onClose={() => onClose(true)}
            setIsLoading={setIsLoading}
          />
        ),
      },
    ];
  }, [
    documentId,
    t,
    file,
    pdfFile,
    boxes,
    imageWidth,
    imageHeight,
    allData,
    contract,
    onClose,
    invoice,
    loadedProjectId,
    fileData,
    processedData,
    setIsValid,
  ]);

  const slideOverTitle = useMemo(() => {
    if (!fileData) {
      return t('projectControl.uploadInvoiceSubTitle');
    }
    return (
      <>
        <div className="flex text-2xl">
          {fileData.contractorName && <span className="whitespace-nowrap truncate">{fileData.contractorName}</span>}
        </div>
        {(fileData.invoiceDetails.number || fileData.contractorName) && (
          <div className="text-gray-500 text-[13px]">
            {fileData.invoiceDetails.number && (
              <span className="font-bold">{fileData.invoiceDetails.number}&nbsp;</span>
            )}
            {fileData.customerName && (
              <span className="whitespace-nowrap truncate">&nbsp;•&nbsp;{fileData.customerName}</span>
            )}
          </div>
        )}
      </>
    );
  }, [fileData, t]);

  return (
    <SlideOverWithTabs
      tabOptions={tabOptions}
      onClose={onClose}
      title={slideOverTitle}
      subtitle={!documentId ? t('projectControl.createNewInvoiceDocument') : ''}
    >
      {(isLoading || isFetchingFile || isFetchingProcessedData) && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay" />
      )}
      <SlideOver.Controls className="items-center">
        {!isValid && (
          <span className="text-sm italic mt-2 text-red-700 mr-4">
            {t('projectControl.createInvoiceInvalidFormMessage')}
          </span>
        )}
        <div className="flex items-center flex-wrap lg:flex-nowrap justify-end">
          {(unsavedData.length > 0) && (
            <div className="flex-none md:flex-1 flex-wrap w-full md:w-auto order-1 md:order-0 text-right mr-4 mt-2 md:mt-0 flex justify-end gap-2">
              {unsavedData.length > 0 && (
                <span className="text-sm italic text-secondary leading-none">
                  {t('projectControl.editInvoiceUnsavedDataMessage')}
                </span>
              )}
            </div>
          )}
        </div>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="primary"
          className="mr-2"
          disabled={unsavedData.length === 0 || !isValid}
          onClick={() => {
            saveInvoice();
          }}
        >
          {t('common.save')}
        </Button>
        <Button
          variant={processedData?.document.hasWorkflow ? 'success' : 'primary'}
          disabled={!isValid}
          onClick={() => {
            processedData?.document.hasWorkflow ? handleReviewWorkflow(null) : createInvoice();
          }}
        >
          {processedData?.document.hasWorkflow
            ? t('projectControl.createInvoiceAndWorkflowFinalize')
            : t('projectControl.createInvoice')}
        </Button>
      </SlideOver.Controls>
    </SlideOverWithTabs>
  );
};
