import { ContractTitleDocumentReadModel, ContractTitleReadModel } from '@client/shared/api';
import { useState } from 'react';
import {
  Modal,
  DocumentListItem
} from '@client/shared/toolkit';
import { ContractTitleDocumentDeleteModal } from './ContractTitleDocumentDeleteModal';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';

interface ContractTitleDocumentRowProps {
  title: ContractTitleReadModel;
  elementDocument: ContractTitleDocumentReadModel;
  canDelete: boolean;
}

export const ContractTitleDocumentRow = ({ title, elementDocument, canDelete }: ContractTitleDocumentRowProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = `/api/projects/${loadedProjectId}/calculation-models/${loadedCalculationModelId}/contracts/${title.contractId}/contract-titles/${title.id}/documents/${elementDocument.id}`;

  return (
    <>
      <DocumentListItem
        fileName={elementDocument.name}
        fileSize={elementDocument.size}
        created={elementDocument.created}
        downloadUrl={elementDocument.isEmbedLinkDocument ? elementDocument.storagePath : downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu
        documentViewerTitle={t('projectContract.contractTitle')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} variant="small">
        <ContractTitleDocumentDeleteModal
          title={title}
          document={elementDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
