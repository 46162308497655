import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { InvoiceDocumentReadModel } from '@client/shared/api';
import { useState } from 'react';
import { Modal, DocumentListItem } from '@client/shared/toolkit';
import { InvoiceDocumentDeleteModal } from './InvoiceDocumentDeleteModal';
import { useTranslation } from 'react-i18next';

interface DocumentRowProps {
  invoiceId: string;
  invoiceDocument: InvoiceDocumentReadModel;
  canDelete: boolean;
}

export const InvoiceDocumentRow = ({ invoiceId, invoiceDocument, canDelete }: DocumentRowProps) => {
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = `/api/projects/${loadedProjectId}/calculation-models/${loadedCalculationModelId}/invoices/${invoiceId}/documents/${invoiceDocument.id}`;

  return (
    <>
      <DocumentListItem
        fileName={invoiceDocument.fileName}
        title={`${invoiceDocument.fileName}${invoiceDocument.isInvoiceSource ? ` • ${t('projectControl.isInvoiceSource')}` : ''}`}
        fileSize={invoiceDocument.fileSize}
        created={invoiceDocument.created}
        downloadUrl={invoiceDocument.isEmbedLinkDocument ? invoiceDocument.storagePath : downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu
        isEmbedUrl={invoiceDocument.isEmbedLinkDocument}
        documentViewerTitle={invoiceDocument.isInvoiceSource ? t('projectControl.isInvoiceSource') : t('common.document')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <InvoiceDocumentDeleteModal
          invoiceId={invoiceId}
          document={invoiceDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
