import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import cn from 'classnames';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  BudgetStatusWidgetColumnType,
  CostCatalogElementDto,
  useApiGetCalculationModelCostsFilteredQuery,
} from '@client/shared/api';
import { i18n } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedProjectValueTypeIsNet, useLoadedVariantId } from '@client/project/store';
import { FormattedCurrency } from '../../FormattedCurrency';

export const getLabelForColumnType = (type: BudgetStatusWidgetColumnType) => {
  switch (type) {
    case 'CurrentBudget':
      return i18n.t('dashboard.widget.budgetStatus.columnType.currentBudget');
    case 'Obligo':
      return i18n.t('dashboard.widget.budgetStatus.columnType.obligo');
    case 'Forecast':
      return i18n.t('dashboard.widget.budgetStatus.columnType.forecast');
    case 'AbsolutePayment':
      return i18n.t('dashboard.widget.budgetStatus.columnType.absolutePayment');
    case 'Payment':
      return i18n.t('dashboard.widget.budgetStatus.columnType.payment');
    case 'MainContract':
      return i18n.t('dashboard.widget.budgetStatus.columnType.mainContract');
    case 'Invoice':
      return i18n.t('dashboard.widget.budgetStatus.columnType.invoice');
    case 'Supplement':
      return i18n.t('dashboard.widget.budgetStatus.columnType.supplement');
    case 'TotalContract':
      return i18n.t('dashboard.widget.budgetStatus.columnType.totalContract');
    default:
      return '';
  }
};

const getCostElementValueForColumnType = (type: BudgetStatusWidgetColumnType, data: CostCatalogElementDto) => {
  switch (type) {
    case 'CurrentBudget': {
      let totalValue = data.modelValues.effectiveValue;
      if (data.modelValues.calculationState === 'JustTotal') {
        totalValue = data.modelValues.total;
      } else if (data.modelValues.calculationState === 'JustCalculated') {
        totalValue = data.modelValues.calculatedValue;
      }
      return <FormattedCurrency amount={totalValue} />;
    }
    case 'Obligo':
      return <FormattedCurrency amount={data.modelValues.obligo} />;
    case 'Forecast':
      return <FormattedCurrency amount={data.modelValues.forecastValue} />;
    case 'AbsolutePayment':
      return <FormattedCurrency amount={data.modelValues.absolutePaymentValue} />;
    case 'Payment':
      return <FormattedCurrency amount={data.modelValues.paymentValue} />;
    case 'MainContract':
      return <FormattedCurrency amount={data.modelValues.contractValue} />;
    case 'Invoice':
      return <FormattedCurrency amount={data.modelValues.invoiceValue} />;
    case 'Supplement':
      return <FormattedCurrency amount={data.modelValues.supplementValue} />;
    case 'TotalContract':
      return (
        <FormattedCurrency amount={(data.modelValues.contractValue ?? 0) + (data.modelValues.supplementValue ?? 0)} />
      );
    default:
      return '';
  }
};

export const DashboardWidgetBudgetStatus = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const isNet = useLoadedProjectValueTypeIsNet();

  const { data: costsData, isFetching: isLoadingCostData } = useApiGetCalculationModelCostsFilteredQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
      ids: widget?.additionalConfig?.BudgetStatus?.costCatalogElements
        ? widget.additionalConfig.BudgetStatus.costCatalogElements.join(',')
        : '',
    },
    { skip: !loadedProjectId || !loadedVariantId || !widget?.additionalConfig?.BudgetStatus?.costCatalogElements },
  );

  const gridCols = useMemo(() => {
    return widget?.additionalConfig?.BudgetStatus?.columns?.length === 3
      ? 'grid-cols-4'
      : widget?.additionalConfig?.BudgetStatus?.columns?.length === 2
        ? 'grid-cols-3'
        : 'grid-cols-2';
  }, [widget?.additionalConfig?.BudgetStatus?.columns?.length]);

  const getSumValueForColumnType = useCallback(
    (type: BudgetStatusWidgetColumnType) => {
      if (costsData?.payload) {
        const data = costsData.payload;
        switch (type) {
          case 'CurrentBudget':
            return data.costElementsTotals ? <FormattedCurrency amount={data.costElementsTotals.totalPlanned} /> : '-';
          case 'Obligo':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.obligo} /> : '-';
          case 'Forecast':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.forecast} /> : '-';
          case 'AbsolutePayment':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.payment} /> : '-';
          case 'Payment':
            return data.costElementsTotals ? (
              <FormattedCurrency
                amount={isNet ? data.costElementsTotals.totalPaymentNet : data.costElementsTotals.totalPaymentGross}
              />
            ) : (
              '-'
            );
          case 'MainContract':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.actualContracts} /> : '-';
          case 'Invoice':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.invoice} /> : '-';
          case 'Supplement':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.supplement} /> : '-';
          case 'TotalContract':
            return data.totalValues ? <FormattedCurrency amount={data.totalValues.totalContracts} /> : '-';
          default:
            return '';
        }
      }
      return '';
    },
    [costsData?.payload, isNet],
  );

  const catalogElements = useMemo(() => {
    return costsData?.payload?.catalogElements
      ? costsData.payload.catalogElements.filter((elem) => elem.id !== '00000000-0000-0000-0000-000000000000')
      : [];
  }, [costsData?.payload?.catalogElements]);

  const getValuesForElement = useCallback(
    (element: CostCatalogElementDto) => {
      const values: (string | ReactNode)[] = [];
      widget?.additionalConfig?.BudgetStatus?.columns?.forEach((value) => {
        values.push(getCostElementValueForColumnType(value, element));
      });
      return values;
    },
    [widget?.additionalConfig?.BudgetStatus?.columns],
  );

  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.budgetStatus.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        {isLoadingCostData && <LoadingIndicator mode="overlay" />}
        <div className={cn('grid text-slate-400 text-[11px] gap-1 border-b pb-1 px-3', gridCols)}>
          <div>{t('dashboard.widget.budgetStatus.row.element')}</div>
          {widget?.additionalConfig?.BudgetStatus?.columns?.map((value, i) => (
            <div className="text-right" key={`budget-status-header-row-column-${i}`}>
              {getLabelForColumnType(value)}
            </div>
          ))}
        </div>
        {catalogElements.length > 0 && (
          <>
            <div className="flex flex-col gap-1 divide-y">
              {catalogElements.map((catalogElement, i) => (
                <DashboardWidgetBudgetStatusRow
                  key={`budget-status-widget-${widget.id}-${catalogElement.id}`}
                  name={`${catalogElement.code} ${catalogElement.description}`}
                  values={getValuesForElement(catalogElement)}
                  className={gridCols}
                  row={i}
                />
              ))}
            </div>
            <div className={cn('grid gap-1 border-t-4 border-double py-2 px-3 items-center', gridCols)}>
              <div className="font-bold text-[13px] leading-none truncate">
                {t('dashboard.widget.budgetStatus.row.sum')}
              </div>
              {widget?.additionalConfig?.BudgetStatus?.columns?.map((value, i) => (
                <div className="font-bold text-lg leading-none text-right" key={`budget-status-footer-row-column-${i}`}>
                  {getSumValueForColumnType(value)}
                </div>
              ))}
            </div>
          </>
        )}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};

interface DashboardWidgetBudgetStatusRowProps {
  className: string;
  name: string;
  values: (string | ReactNode)[];
  row: number;
}

const DashboardWidgetBudgetStatusRow = (props: DashboardWidgetBudgetStatusRowProps) => {
  const { className, name, values, row } = props;
  return (
    <div className={cn('grid text-[13px] py-1 px-3 items-center gap-1', className)}>
      <div className="font-medium text-ellipsis overflow-hidden" title={name}>
        {name}
      </div>
      {values.map((value, i) => (
        <div className="text-right" key={`budget-status-row-${row}-value-${i}`}>
          {value}
        </div>
      ))}
    </div>
  );
};
