import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator,
  SummaryCellData,
  WidgetContainer,
  WidgetContainerContent,
  WidgetSummary,
} from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import React, { Fragment, useCallback, useMemo } from 'react';
import { getKPILabelByType } from '../WidgetEditForms';
import { DataFeed, FontLight, ProjectStartEndFeed } from './components';
import cn from 'classnames';
import { ProjectReadModel, useApiGetCalculationModelFinancingQuery, useApiGetTaxonomyQuery } from '@client/shared/api';
import { formatLargeNumber, formatPercentage, formatUnit, monthDiff } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { FormattedCurrency } from '../../FormattedCurrency';

export interface DashboardWidgetKPIsProps extends DashboardWidgetProps {
  variant?: 'default' | 'compact';
  projectData: ProjectReadModel;
}

export const DashboardWidgetKPIs = (props: DashboardWidgetKPIsProps) => {
  const { widget, layout, variant = 'default', projectData } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const includesBuildingsDataKpi = useMemo(() => {
    return !!widget?.additionalConfig?.KPIs?.find((kpi) => kpi === 'Taxonomy_NGF');
  }, [widget?.additionalConfig?.KPIs]);

  const includesFinanceDataKpi = useMemo(() => {
    return !!widget?.additionalConfig?.KPIs?.find((kpi) => kpi === 'Taxonomy_NGF' || kpi === 'Calculate_EquityRatio');
  }, [widget?.additionalConfig?.KPIs]);

  const { data: buildingsData, isFetching: isFetchingBuildingsData } = useApiGetTaxonomyQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedVariantId || !loadedProjectId || !includesBuildingsDataKpi },
  );

  const { data: financeData, isFetching: isFetchingFinanceData } = useApiGetCalculationModelFinancingQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedVariantId || !loadedProjectId || !includesFinanceDataKpi },
  );

  const getKPIValueByKPI = useCallback(
    (kpi: string) => {
      const activeVariant = projectData?.calculationModels?.find((c) => c.id === (loadedVariantId ?? ''));
      const label = getKPILabelByType(kpi);
      switch (kpi) {
        case 'Taxonomy_GFA': // BGF / GFA
          return {
            label,
            value: activeVariant?.grossFloorValue.value
              ? formatUnit(activeVariant.grossFloorValue.value, activeVariant?.grossFloorValue.unit ?? '')
              : '-',
            icon: '',
          };
        case 'Taxonomy_NGF':
          return {
            label,
            value: buildingsData?.sizes?.ngf?.value
              ? formatUnit(buildingsData.sizes.ngf.value, buildingsData.sizes.ngf.unit ?? '')
              : '-',
            icon: '',
          };
        case 'Taxonomy_RentalSpace': // MF (Mietfläche)
          return {
            label,
            value: buildingsData?.sizes.effectiveRentalSpaceValue
              ? formatUnit(
                  buildingsData.sizes.effectiveRentalSpaceValue.value,
                  buildingsData.sizes.effectiveRentalSpaceValue.unit,
                )
              : '-',
            icon: '',
          };
        case 'Taxonomy_Plot': // Grundstück
          return {
            label,
            value: activeVariant?.plot.value
              ? formatUnit(activeVariant?.plot.value, activeVariant?.plot.unit ?? '')
              : '-',
            icon: '',
          };
        case 'Taxonomy_FAR': // GFZ
          return {
            label,
            value: activeVariant?.gfz ?? 0,
            icon: '',
          };
        case 'Taxonomy_SOI': // GRZ
          return {
            label,
            value: activeVariant?.grz ?? 0,
            icon: '',
          };
        case 'Project_Address': {
          const addressLine1 =
            projectData?.payload?.street || projectData?.payload?.number
              ? `${projectData?.payload?.street ?? ''}${
                  projectData?.payload?.number ? ` ${projectData?.payload?.number}` : ''
                }`
              : '';
          const addressLine2 =
            projectData?.payload?.postalCode || projectData?.payload?.city
              ? `${projectData?.payload?.postalCode ?? ''}${
                  projectData?.payload?.city ? ` ${projectData?.payload?.city}` : ''
                }`
              : '';
          return {
            label,
            value:
              addressLine1 || addressLine2 ? (
                <div className="flex flex-col">
                  <FontLight>{addressLine1 ?? '-'}</FontLight>
                  <FontLight>{addressLine2 ?? '-'}</FontLight>
                </div>
              ) : (
                '-'
              ),
            icon: '',
          };
        }
        case 'Project_Runtime': {
          // Laufzeit & Phasen
          const months =
            activeVariant?.calculationModelStartDate && activeVariant?.calculationModelEndDate
              ? monthDiff(
                  new Date(activeVariant?.calculationModelStartDate),
                  new Date(activeVariant?.calculationModelEndDate),
                )
              : null;
          return {
            label,
            value: months ? `${months} ${t('common.month', { count: months })}` : '-',
            icon: '',
            subtitle: <FontLight>{`${activeVariant?.deliveryPhasesCount ?? '-'} ${t('project.phases')}`}</FontLight>,
          };
        }
        case 'Project_StartEnd':
          return {
            label,
            value: '-',
            icon: '',
            content: (
              <ProjectStartEndFeed
                className="border-t"
                startDate={activeVariant?.calculationModelStartDate || ''}
                endDate={activeVariant?.calculationModelEndDate || ''}
              />
            ),
          };
        case 'Calculate_CostsPerSquareMeter': // Kosten pro m² BGF (= Gesamtkosten / Bruttogrundfläche (BGF)) / Costs per m2 GFA
          return {
            label,
            value: activeVariant?.averageCostPerSquareMeter.value
              ? formatUnit(
                  activeVariant?.averageCostPerSquareMeter.value || 0,
                  activeVariant?.averageCostPerSquareMeter.unit ?? '',
                )
              : '-',
            icon: '',
          };
        case 'Calculate_RevenuesPerSquareMeter': // Erlöse pro m² BGF (= Gesamterlöse / Bruttogrundfläche (BGF)) / Revenues per m2 GFA
          return {
            label,
            value: activeVariant?.averageEarningPerSquareMeter
              ? formatUnit(
                  activeVariant?.averageEarningPerSquareMeter.value || 0,
                  activeVariant?.averageEarningPerSquareMeter.unit ?? '',
                )
              : '-',
            icon: '',
          };
        case 'Calculate_PlannedCosts': // Geplante Kosten inkl. Risiken / Planned Costs incl. Risks
          return {
            label,
            value: activeVariant?.totalCostAndRisk.value
              ? (formatLargeNumber(activeVariant?.totalCostAndRisk.value ?? 0) as string)
              : '-',
            icon: '',
          };
        case 'Calculate_Deviation': // Abweichung / Deviation // TODO the deviation is calculated wrong
          return {
            label,
            value: activeVariant?.deviation ? (formatLargeNumber(activeVariant?.deviation ?? 0) as string) : '-',
            icon: '',
          };
        case 'Calculate_ROI': // Kapitalrendite / ROI
          return {
            label,
            value: formatPercentage(activeVariant?.roi.value || 0),
            icon: '',
          };
        case 'Calculate_ROE': // ROE / ROE
          return {
            label,
            value: formatPercentage(activeVariant?.roe.value ?? 0),
            icon: '',
          };
        case 'Calculate_PlannedRevenues': // Geplante Erlöse / Planned Revenues
          return {
            label,
            value: formatLargeNumber(activeVariant?.totalEarnings.value ?? 0) as string,
            icon: '',
          };
        case 'Calculate_ProjectIRR': // (= alle Cashflows des Projekts (Kosten und Erlöse), ohne zwischen EK und FK zu unterscheiden) / Project IRR
          return {
            label,
            value: formatUnit(activeVariant?.calculatedIrr.value || 0, activeVariant?.calculatedIrr.unit ?? ''),
            icon: '',
          };
        case 'Calculate_EquityIRR': // (= Eigenkapital-bezogenen Cashflows (z. B. EK-Einsatz und Nettorückflüsse nach FK-Kosten)) / Equity IRR
          return {
            label,
            value: formatUnit(activeVariant?.plannedIrr.value ?? 0, '% p.a.'),
            icon: '',
          };
        case 'Calculate_Equity': { // Eigenkapital EK / Equity
          const totalEquity = financeData?.payload.totalEquity ?? 0;
          const totalProfit = activeVariant?.totalProfit.value ?? 0;
          const netReturn = totalProfit + totalEquity;
          const equityMultiplier = totalEquity === 0 ? 0 : netReturn / totalEquity;
          return {
            label,
            value: formatUnit(equityMultiplier, 'x'),
            icon: '',
          };
        }
        case 'Calculate_DebtCapital': // Fremdkapital FK / Debt capital // TODO??
          return {
            label,
            value: '-',
            icon: '',
          };
        case 'Calculate_Subsidies': // Zuschüsse / Subsidies // TODO coming later
          return {
            label,
            value: '-',
            icon: '',
          };
        case 'Calculate_Funds': // Fonds / Funds // TODO coming later
          return {
            label,
            value: '-',
            icon: '',
          };
        case 'Calculate_EquityRatio': { // EK-Quote / Equity ratio
          const totalEquity = financeData?.payload.totalEquity ?? 0;
          return {
            label,
            value: formatLargeNumber(totalEquity, {maxDigits: 1}),
            icon: '',
          };
        }
        case 'Calculate_TotalProfit': // Gesamtgewinn / Total Profit (in €)
          return {
            label,
            value: <FormattedCurrency amount={activeVariant?.totalProfit.value ?? 0} />,
            icon: '',
          };
        default:
          return {
            label,
            value: '-',
            icon: '',
          };
      }
    },
    [projectData, loadedVariantId, buildingsData, t, financeData],
  );

  const kpis = useMemo(() => {
    return widget?.additionalConfig?.KPIs?.length ? widget?.additionalConfig?.KPIs.map((kpi) => getKPIValueByKPI(kpi)) : [];
  }, [widget?.additionalConfig?.KPIs, getKPIValueByKPI]);

  if (variant === 'compact') {
    return (
      <WidgetSummary
        title={widget.title ? widget.title : t('dashboard.widget.kpis.title')}
        isLoading={false}
        data={kpis as SummaryCellData[]}
      />
    );
  }

  return (
    <WidgetContainer>
      <WidgetContainerContent className="overflow-y-auto px-3 py-0">
        {(isFetchingBuildingsData || isFetchingFinanceData) && <LoadingIndicator mode="overlay" />}
        <div
          className={cn('-mt-px', {
            'columns-2': layout?.w && layout.w === 2,
          })}
        >
          {kpis.map((kpi, i) => {
            if (kpi.content) {
              return <Fragment key={`widget-${widget.id}-kpi-${i}`}>{kpi.content}</Fragment>;
            }
            return (
              <DataFeed
                className={cn('border-t', {
                  'break-inside-avoid': layout?.w && layout.w === 2,
                })}
                key={`widget-${widget.id}-kpi-${i}`}
                header={<FontLight classNames="text-slate-500">{kpi.label}</FontLight>}
                title={kpi.value}
                icon={kpi.icon}
                footer={kpi.subtitle}
              />
            );
          })}
        </div>
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
