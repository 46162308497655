import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import { useTranslation } from 'react-i18next';
import { useMemo, useRef } from 'react';
import {
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  TextInput,
} from '@client/shared/toolkit';

export const WidgetDashboardEditFormWaterfallValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required')
});

export type WidgetDashboardEditFormWaterfallValidationValues = InferType<
  typeof WidgetDashboardEditFormWaterfallValidationSchema
>;

export const WidgetEditFormWaterfall = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormWaterfallValidationValues>>();

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.waterfall.title'),
      size: selectedSize
    };
  }, [t, widget?.widget.title, selectedSize]);

  return (
    <Form<WidgetDashboardEditFormWaterfallValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormWaterfallValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
