import {
  DecoratedElement,
  EarningElement,
} from '../../hooks';
import {
  addOrRemoveDecoratedElementFromExpandedIds,
  getRenderElementIsVisibleAndTopStyle
} from '../../utils';
import { CalculateProps } from '../CalculateContainer';
import {
  EarningsElementRow,
  EarningsGroupElement,
  PreselectedEarningsParent
} from '..';
import React, { Fragment, RefObject } from 'react';
import { ToggleButton } from '@client/shared/toolkit';
import classNames from 'classnames';
import { EarningsType } from '@client/shared/api';

interface CalculateEarningsRenderElementsProps extends CalculateProps {
  containerRef: RefObject<HTMLDivElement>;
  elements: DecoratedElement<EarningElement>[];
  handleOpenSlideOver: (
    element: DecoratedElement<EarningElement> | undefined,
    preselectedParent: PreselectedEarningsParent | undefined
  ) => void;
  preselectedGroupId?: string;
  handleOpenDeleteElement: (deleteElementId?: string | null) => void;
  isReadOnly: boolean;
  handleOpenNewFeatureElementModal: (catalog: string | undefined | null, earningType: EarningsType | undefined) => void;
}

export const CalculateEarningsRenderElements = (props: CalculateEarningsRenderElementsProps) => {
  const {
    containerRef,
    view,
    elements,
    expandedCatalogIds = [],
    onExpand,
    selectedVersionId,
    optionalColumn,
    obligoColumn,
    handleOpenSlideOver,
    handleOpenDeleteElement,
    isReadOnly,
    handleOpenNewFeatureElementModal
  } = props;

  let counter = -1;
  let top = 0;
  const renderElements = (elements: DecoratedElement<EarningElement>[]) => {
    return elements.map((element) => {
      const open = expandedCatalogIds.includes(element.categoryId);
      top = top + (element.level === 0 ? 32 : 0);
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;

      return (
        <Fragment key={element.element.key}>
          {isVisible && (
            <div
              className={classNames(
                'w-full flex group bg-transparent hover:bg-neutral-100 transition-colors duration-200 h-[38px] absolute',
              )}
              data-level={element.level}
              style={{ top: topStyle }}
            >
              <ToggleButton
                className={classNames('absolute h-full z-[5]', {
                  invisible: element.children.length === 0,
                })}
                open={open}
                onClick={() => {
                  onExpand(
                    addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add')
                  );
                }}
              />
              {element.element.type === 'group' ? (
                <EarningsGroupElement
                  isReadOnly={isReadOnly}
                  view={view}
                  key={`earning_group_${element.element.key}`}
                  item={element}
                  expanded={open}
                  optionalColumn={optionalColumn}
                  obligoColumn={obligoColumn}
                  onCodeClick={() => {
                    onExpand(
                      addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add')
                    );
                  }}
                  onNewElement={(type) => {
                    if (type === 'earnings') {
                      handleOpenSlideOver(undefined, {
                        preselectedGroupType:
                          element.element.group && element.element.group?.earningsType
                            ? element.element.group.earningsType
                            : undefined,
                        preselectedGroupId: element.element.group?.groupId ?? undefined,
                      });
                    } else {
                      handleOpenNewFeatureElementModal(element.element.group?.groupId, element.element.group?.earningsType);
                    }
                  }}
                  onRemoveBudget={
                    element.element.group?.earningsElementId && element.element.group?.calculationState !== 'JustCalculated'
                      ? () => handleOpenDeleteElement(element.element.group?.earningsElementId)
                      : undefined
                  }
                  onClick={element.disableClick ? undefined : () => handleOpenSlideOver(element, undefined)}
                  selectedVersionId={selectedVersionId}
                />
              ) : (
                <EarningsElementRow
                  isReadOnly={isReadOnly}
                  key={`earning_element_${element.element.key}`}
                  item={element}
                  optionalColumn={optionalColumn}
                  obligoColumn={obligoColumn}
                  onClick={() => handleOpenSlideOver(element, undefined)}
                  onRemove={
                    element.element.earningElement?.id
                      ? () => handleOpenDeleteElement(element.element.earningElement?.id)
                      : undefined
                  }
                  selectedVersionId={selectedVersionId}
                  view={view}
                />
              )}
            </div>
          )}
          {open && renderElements(element.children)}
        </Fragment>
      );
    });
  };

  return renderElements(elements);
};
