import {
  Button,
  DownloadToExcelCustomIcon,
  DownloadToPdfCustomIcon,
  DownloadToPngCustomIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { settings } from '@client/shared/store';
import {
  ApiAdminExportReportFileApiResponse,
  useApiPostHtmlToImageExportFileMutation,
  useApiPostHtmlToPdfExportFileMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export interface ExportButtonsProps {
  setIsLoading: (isLoading: boolean) => void;
  showPdfDownload: boolean;
  showExcelDownload: boolean;
  onExcelDownload?: () => void;
  reportName: string;
  reportSize?: number | null; // in mm
}

async function convertChartsToImages(pdfContent: HTMLElement) {
  const allCharts = pdfContent?.querySelectorAll('canvas.chart-export');
  if (allCharts?.length) {
    const conversionPromises = Array.from(allCharts).map(async (chartToExport) => {
      const chartElement = chartToExport as HTMLElement;
      const width = chartElement.clientWidth;
      const height = chartElement.clientHeight;
      const canvas = await html2canvas(chartElement);
      const img = canvas.toDataURL('image/png');
      const image = new Image();
      image.src = img;
      image.style.width = `${width}px`;
      image.style.height = `${height}px`;
      if (chartToExport.parentNode) {
        // chartToExport.parentNode.replaceChild(image, chartToExport);
        chartToExport.setAttribute('style', 'display: none; height: 0; width: 0;');
        chartToExport.parentNode.prepend(image);
      }
    });
    await Promise.all(conversionPromises);
  }
}

export const ExportButtons = (props: ExportButtonsProps) => {
  const { setIsLoading, showPdfDownload, showExcelDownload, onExcelDownload, reportName, reportSize } = props;
  const { t } = useTranslation();

  const [exportPDF, { isLoading: isExportingPdf }] = useApiPostHtmlToPdfExportFileMutation();
  const [exportImage, { isLoading: isExportingImage }] = useApiPostHtmlToImageExportFileMutation();

  const onExport = async (type: 'pdf' | 'image') => {
    setIsLoading(true);

    const chartToExport = document.getElementById('export-chart');
    if (chartToExport) {
      try {
        html2canvas(chartToExport, {
          scale: 2,
        })
          .then((canvas) => {
            const height = chartToExport.clientHeight ? chartToExport.clientHeight : 0;
            const width = chartToExport.clientWidth;
            const imgData = canvas.toDataURL('image/png');
            // PDF download
            if (type === 'pdf') {
              const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'pt',
              });
              pdf.internal.pageSize.height = height;
              pdf.internal.pageSize.width = width;
              pdf.addImage(imgData, 'image/png', 0, 0, width, height);
              pdf.save(`${reportName}-${Date.now()}.pdf`);
            } else {
              // PNG Download
              const a = document.createElement('a');
              a.href = imgData;
              a.download = `${reportName}.png`;
              a.click();
            }
          })
          .then(() => {
            setIsLoading(false);
          });
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    } else {
      // iron pdf
      const pdfContent = document.getElementById('pdf-content');
      if (pdfContent) {
        // const pdfContentClone = pdfContent.cloneNode(true) as HTMLElement;

        // all charts need to be converted to images first
        await convertChartsToImages(pdfContent);

        const exportFunction = type === 'pdf' ? exportPDF : exportImage;
        const isExporting = type === 'pdf' ? isExportingPdf : isExportingImage;
        let styleSheets = '';
        document.querySelectorAll('head link[rel="stylesheet"]').forEach((cssFile) => {
          const href = cssFile?.getAttribute('href');
          if (href) {
            styleSheets += `<link rel="stylesheet" href="${href}" />`;
          }
        });
        if (settings.mainDomain === 'probis.test') {
          document.querySelectorAll('head style[type="text/css"]').forEach((styleElement) => {
            if (styleElement.getAttribute('data-vite-dev-id')) {
              styleSheets += styleElement.outerHTML;
            }
          });
        }
        const regex = /\/assets\//g;
        // styleSheets = styleSheets.replace(regex, `https://${settings.mainDomain}/assets/`);
        styleSheets = styleSheets.replace(
          regex,
          `https://${settings.mainDomain === 'probis.test' ? `probis.dev` : settings.mainDomain}/assets/`,
        );

        const html = `<html><head><title>PROBIS - Calculate</title><base href="/" /><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1" />${styleSheets}<style>body > div:last-child img { display: inline-block; }</style></head><body class="pdf-export">${pdfContent.outerHTML}</body></html>`;

        try {
          const exportResponse: ApiAdminExportReportFileApiResponse | undefined = await safeMutation(
            exportFunction,
            {
              body: {
                html: html,
                settings: {
                  fileName: reportName,
                  size: reportSize,
                },
              },
            },
            isExporting,
          );
          if (exportResponse && exportResponse.data && exportResponse.fileName) {
            const link = document.createElement('a');
            link.setAttribute('download', exportResponse.fileName);
            link.href = exportResponse.data;
            document.body.appendChild(link);
            link.style.visibility = 'hidden';
            link.click();
            link.remove();
          }
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }

        const allCharts = pdfContent?.querySelectorAll('canvas.chart-export');
        allCharts?.forEach((chartToExport) => {
          if (chartToExport.parentNode) {
            chartToExport.removeAttribute('style');
            const image = chartToExport.parentNode.querySelector('img');
            if (image) {
              chartToExport.parentNode.removeChild(image);
            }
          }
        });
      } else {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {/* Export to png and pdf does not work currently with IronPDF or html2canvas and jsPDF */}
      {settings.devMode && showPdfDownload && (
        <>
          <Button
            variant="custom"
            className="focus:outline-none active:outline-none flex flex-col items-center font-bold text-[11px] gap-1 text-slate-500 hover:text-slate-700 transition-colors duration-300"
            onClick={() => onExport('image')}
          >
            <DownloadToPngCustomIcon className="w-7" />
            {t('reporting.reportDownloadToPng')}
          </Button>

          <Button
            variant="custom"
            className="focus:outline-none active:outline-none flex flex-col items-center font-bold text-[11px] gap-1 text-slate-500 hover:text-slate-700 transition-colors duration-300"
            onClick={() => onExport('pdf')}
          >
            <DownloadToPdfCustomIcon className="w-7" />
            {t('reporting.reportDownloadToPDF')}
          </Button>
        </>
      )}
      {showExcelDownload && onExcelDownload && (
        <Button
          variant="custom"
          className="focus:outline-none active:outline-none flex flex-col items-center font-bold text-[11px] gap-1 text-slate-500 hover:text-slate-700 transition-colors duration-300"
          onClick={onExcelDownload}
        >
          <DownloadToExcelCustomIcon className="w-7" />
          {t('reporting.reportDownloadToExcel')}
        </Button>
      )}
    </>
  );
};
