import {
  Button,
  ComboSelect,
  ComboSelectAdditionalOption,
  ContactIcon,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField,
  FormRefHandle,
  GuideDialog,
  GuideDialogControls,
  LoadingIndicator,
} from '@client/shared/toolkit';
import React, { RefObject, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiEvalValuesReadModel,
  SelectCompanyBranchReadModel,
  ShortContractReadModel,
  useApiGetProjectSelectCompanyBranchesQuery,
} from '@client/shared/api';
import { getCompanyBranches, useLoadedProjectId } from '@client/project/store';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { BranchData } from '.';
import { AddCompanyModal, InvoiceEditContext } from '../..';

export const findBranchByIbanOrName = (branches: SelectCompanyBranchReadModel[], name?: string, iban?: string) => {
  return branches?.find((branch) => {
    if (name && branch.name.toLowerCase().split(' ').join('') === name.toLowerCase().split(' ').join('')) {
      return true;
    }
    if (branch.bankAccounts && iban) {
      const foundBankAccount = branch.bankAccounts.find((bankAccount) => {
        return bankAccount.iban?.toLowerCase().split(' ').join('') === iban.toLowerCase().split(' ').join('');
      });
      if (foundBankAccount) {
        return true;
      }
    }
    return false;
  });
};

interface InvoiceDocumentReviewContractorProps {
  fileData?: AiEvalValuesReadModel | null;
  formRef?: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>>;
  selectedContract?: ShortContractReadModel | null;
  defaultFormValues?: InvoiceCreateFormValidationValues;
}

export const InvoiceDocumentReviewContractorAndClient = (props: InvoiceDocumentReviewContractorProps) => {
  const { fileData, formRef, selectedContract, defaultFormValues } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  
  const [contractorElement, setContractorElement] = useState<HTMLElement | null>(null);
  const [clientElement, setClientElement] = useState<HTMLElement | null>(null);

  const { guideDialogData, setGuideDialogData, slideOverOpen, updateUnsavedData } = useContext(InvoiceEditContext);

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  // selected contractor from branches list by user
  const [selectedContractor, setSelectedContractor] = useState<SelectCompanyBranchReadModel | null>(null);
  // selected client from branches list by user
  const [selectedClient, setSelectedClient] = useState<SelectCompanyBranchReadModel | null>(null);

  const [createCompanyField, setCreateCompanyField] = useState<'contractor' | 'client'>('contractor');
  const [createdCompany, setCreatedCompany] = useState<string | undefined>(undefined);
  const [isOpenCreateCompanyModal, setIsOpenCreateCompanyModal] = useState(false);
  const [allowChangeMode, setAllowChangeMode] = useState(true);
  const labelClient = t('projectControl.auditInvoiceClient');
  const labelContractor = t('projectControl.auditInvoiceContractor');

  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  const companiesOptions = useMemo(() => {
    return getCompanyBranches(branches);
  }, [branches]);

  useEffect(() => {
    if (companiesOptions.length && fileData) {
      const currentValues = formRef?.current?.getValues();
      if (!currentValues?.invoicingPartyId && fileData?.contractorName && branches) {
        // search for the name or iban in branch
        const foundBranch = findBranchByIbanOrName(
          branches,
          fileData?.contractorName,
          fileData?.contractorBankDetails?.iban,
        );

        if (foundBranch) {
          setSelectedContractor(foundBranch);
          if (!selectedContract) {
            const foundCompany = companiesOptions.find((option) => option.value === foundBranch.id);
            if (foundCompany) {
              formRef?.current?.setValue('invoicingPartyId', foundCompany.value);
            }
          }
        }
      } else if (currentValues?.invoicingPartyId) {
        const foundBranch = branches?.find((branch) => branch.id === currentValues?.invoicingPartyId);
        if (foundBranch) {
          setSelectedContractor(foundBranch);
        }
      } else if(!currentValues?.invoicingPartyId && !fileData?.contractorName) {
        setSelectedClient(null);
      }

      if (!currentValues?.invoiceRecipientId && fileData?.customerName && branches) {
        const foundBranch = findBranchByIbanOrName(branches, fileData?.customerName);
        if (foundBranch) {
          setSelectedClient(foundBranch);
          if (!selectedContract) {
            const foundCompany = companiesOptions.find((option) => option.value === foundBranch.id);
            if (foundCompany) {
              formRef?.current?.setValue('invoiceRecipientId', foundCompany.value);
            }
          }
        }
      } else if (currentValues?.invoiceRecipientId) {
        const foundBranch = branches?.find((branch) => branch.id === currentValues?.invoiceRecipientId);
        if (foundBranch) {
          setSelectedClient(foundBranch);
        }
      } else if(!currentValues?.invoiceRecipientId && !fileData?.customerName) {
        setSelectedClient(null);
      }
    }
  }, [formRef, companiesOptions, fileData, branches, selectedContract]);

  useEffect(() => {
    if (createdCompany && formRef?.current && branches?.length) {
      const foundBranch = branches?.find(
        (branch) => branch.id === createdCompany || branch.companyId === createdCompany,
      );
      if (foundBranch) {
        if (createCompanyField === 'contractor') {
          setSelectedContractor(foundBranch);
          formRef?.current?.setValue('invoicingPartyId', foundBranch.id);
        } else {
          setSelectedClient(foundBranch);
          formRef?.current?.setValue('invoiceRecipientId', foundBranch.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCompany, branches]);

  useEffect(() => {
    const updatedGuideDialogData = { ...guideDialogData };
    const elements = [];

    if (!selectedContractor && !selectedContract) {
      elements.push('contractor');
    }
    if (!selectedClient && !selectedContract) {
      elements.push('client');
    }
    if (!selectedContract && !selectedContractor && !selectedClient) {
      elements.push('contract');
    }

    if(guideDialogData.elements.length !== elements.length) {
      updatedGuideDialogData.currentId = 0;
    }
    updatedGuideDialogData.elements = elements;
    setGuideDialogData(updatedGuideDialogData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractor, selectedClient, selectedContract]);

  return isLoadingBranches ? (
    <LoadingIndicator text={t('common.loading')} />
  ) : (
    <>
      {/*--------- CONTRACTOR -------*/}
      <GuideDialog
          show={
            guideDialogData?.showDialog &&
            guideDialogData?.elements[guideDialogData?.currentId] === 'contractor' &&
            slideOverOpen
          }
          guideDialogData={guideDialogData}
          setGuideDialogData={setGuideDialogData}
          showCount
          showCloseButton
          watermark="info"
          title={t('projectInvoice.warningNoContractor')}
          description={t('projectInvoice.warningNoContractorDescription')}
          controls={<GuideDialog.Controls guideDialogData={guideDialogData} setGuideDialogData={setGuideDialogData} />}
          targetElement={contractorElement}
       />
      <div className="py-4" ref={setContractorElement}>
        <DocumentViewerFileDataInlineEdit
          allowChangeMode={allowChangeMode}
          noHover={selectedContractor ? false : true}
          toggleContent={
            canWrite ? (
              <FormField name="invoicingPartyId">
                {(control) => (
                  <ComboSelect
                    label={labelContractor}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}
                    options={companiesOptions}
                    additionalOptionOnClick={() => {
                      setIsOpenCreateCompanyModal(true);
                      setCreateCompanyField('contractor');
                    }}
                    additionalOption={<ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />}
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      const foundContractor = branches?.find((branch) => branch.id === branchId);
                      setSelectedContractor(foundContractor ?? null);
                      control.onChange(branchId);
                      if(branchId && selectedContract?.contractorId !== branchId) {
                        formRef?.current?.setValue('contractId', null);
                      }
                      if (updateUnsavedData) {
                        updateUnsavedData(
                          'invoicingPartyId',
                          defaultFormValues?.invoicingPartyId === foundContractor?.id,
                        );
                      }
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedContractor ? (
              <BranchData
                branch={selectedContractor}
                label={labelContractor}
                branchNameClassName={
                  defaultFormValues?.invoicingPartyId !== selectedContractor?.id ? 'text-secondary' : ''
                }
                // notMatched
              />
            ) : (
              <DocumentViewerFileDataSet label={labelContractor}>
                <div className="w-full flex justify-center">
                  <Button variant="secondary">{t('common.add')}</Button>
                </div>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </div>

      {/*--------- CLIENT -------*/}
      <GuideDialog
          show={
            guideDialogData?.showDialog &&
            guideDialogData?.elements[guideDialogData?.currentId] === 'client' &&
            slideOverOpen
          }
          guideDialogData={guideDialogData}
          setGuideDialogData={setGuideDialogData}
          showCount
          showCloseButton
          watermark="info"
          title={t('projectInvoice.warningNoClient')}
          description={t('projectInvoice.warningNoClientDescription')}
          controls={<GuideDialogControls guideDialogData={guideDialogData} setGuideDialogData={setGuideDialogData} />}
          targetElement={clientElement}
        />
      <div className="py-4" ref={setClientElement}>
        <DocumentViewerFileDataInlineEdit
          allowChangeMode={allowChangeMode}
          noHover={selectedClient ? false : true}
          toggleContent={
            canWrite ? (
              <FormField name="invoiceRecipientId">
                {(control) => (
                  <ComboSelect
                    label={labelClient}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}
                    options={companiesOptions}
                    additionalOptionOnClick={() => {
                      setIsOpenCreateCompanyModal(true);
                      setCreateCompanyField('client');
                    }}
                    additionalOption={<ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />}
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      const foundClient = branches?.find((branch) => branch.id === branchId);
                      setSelectedClient(foundClient ?? null);
                      control.onChange(branchId);
                      if(branchId && selectedContract?.contractorId !== branchId) {
                        formRef?.current?.setValue('contractId', null);
                      }
                      if (updateUnsavedData) {
                        updateUnsavedData(
                          'invoiceRecipientId',
                          defaultFormValues?.invoiceRecipientId === foundClient?.id,
                        );
                      }
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedClient ? (
              <BranchData
                branch={selectedClient}
                label={labelClient}
                branchNameClassName={
                  defaultFormValues?.invoiceRecipientId !== selectedClient?.id ? 'text-secondary' : undefined
                }
              />
            ) : (
              <DocumentViewerFileDataSet label={labelClient}>
                <div className="w-full flex justify-center">
                  <Button variant="secondary">{t('common.add')}</Button>
                </div>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </div>

      <AddCompanyModal
        isOpen={isOpenCreateCompanyModal}
        onClose={(branchId) => {
          setCreatedCompany(branchId);
          setIsOpenCreateCompanyModal(false);
        }}
        field={createCompanyField}
        invoiceDocumentFileData={fileData}
      />
    </>
  );
};
