import {
  BudgetOverrunWarningReadModel,
  ContractReadModel,
  ContractTitleType,
  InvoiceReadModel,
  InvoiceTitleDistributionReadModel,
  InvoiceTitleReadModel,
  InvoiceValueDistributionType,
  useApiPostCalculateInvoiceValueDistributionMutation,
  useApiPostCreateInvoiceTitleMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  AddButton,
  AddIcon,
  BaseSelect,
  BaseSelectOption,
  Button,
  CheckmarkIcon,
  ContextMenu,
  ContextMenuItem,
  EstimateIcon,
  FormField,
  FormWatch,
  LoadingIndicator,
  NumberInput,
  SlideOver,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '../../FormattedCurrency';
import { InvoiceEditTitleSlideOver } from '../InvoiceEditTitleSlideOver';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { ContractEditTitleSlideOver } from '../../ContractTitle';
import cn from 'classnames';
import { InvoiceCalculation } from './InvoiceCalculation';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';

interface InvoiceTitlesListProps {
  className?: string;
  invoice: InvoiceReadModel;
  contract?: ContractReadModel | null;
  isLastInvoice?: boolean;
  authorizedToReview?: boolean;
  saveDistribution: () => void;
  budgetOverrun: BudgetOverrunWarningReadModel | undefined;
  budgetAssigned: boolean;
}

export const InvoiceTitlesList = (props: InvoiceTitlesListProps) => {
  const { className, invoice, contract, isLastInvoice, saveDistribution, budgetOverrun, budgetAssigned } = props;

  const { t } = useTranslation();

  const loadedProject = useLoadedProject().currentData?.project.payload;
  const defaultVat = invoice.vat ?? loadedProject?.vat ?? 0;

  const loadedProjectId = loadedProject?.id;
  const loadedVariantId = useLoadedVariantId();

  const canWriteContract =
    useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '') && !invoice.isPxInvoice;
  const canWriteInvoice =
    useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '') && !invoice.isPxInvoice;

  const [create, { isLoading: isCreating }] = useApiPostCreateInvoiceTitleMutation();
  const [getDistribution, { isLoading: isGettingDistribution }] = useApiPostCalculateInvoiceValueDistributionMutation();

  const [titles, setTitles] = useState<InvoiceTitleReadModel[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<InvoiceTitleReadModel | null>(null);
  const [openSlideOver, setOpenSlideOver] = useState<'invoiceTitle' | 'contractTitle' | null>(null);
  const [contractTitleType, setContractTitleType] = useState<ContractTitleType>('MainContract');

  const [distributedValues, setDistributedValues] = useState<InvoiceTitleDistributionReadModel[] | null>(null);

  useEffect(() => {
    // reset
    if (invoice) {
      setTitles(invoice.invoiceTitles);
      setDistributedValues(null);
    }
  }, [invoice]);

  const handleCreateInvoiceTitle = useCallback(async () => {
    if (invoice?.id && loadedProjectId && loadedVariantId) {
      const nextTitleNumber = titles.length + 1;
      const nextTitleNumberPadded = String(nextTitleNumber).padStart(2, '0');
      await safeMutation(
        create,
        {
          invoiceId: invoice.id,
          projectId: loadedProjectId,
          calculationModelId: loadedVariantId,
          body: {
            code: nextTitleNumberPadded,
            vat: invoice.vat ?? defaultVat,
            invoiceValue: 0,
            checked: false,
          },
        },
        isCreating,
      );
    }
  }, [invoice.id, loadedProjectId, loadedVariantId, titles.length, create, invoice.vat, isCreating, defaultVat]);

  const contextMenuItems: ContextMenuItem[] = useMemo(() => {
    if (contract) {
      return [
        {
          label: t('projectContract.createMainContractTitle'),
          subtitle: t('projectContract.createNewTitle'),
          icon: <AddIcon />,
          isDisabled: !canWriteInvoice || !canWriteContract,
          onClick: () => {
            setContractTitleType('MainContract');
            setOpenSlideOver('contractTitle');
          },
        },
        {
          label: t('projectContract.createSupplementTitle'),
          subtitle: t('projectContract.createNewTitle'),
          icon: <AddIcon />,
          isDisabled: !canWriteInvoice || !canWriteContract,
          onClick: () => {
            setContractTitleType('Supplement');
            setOpenSlideOver('contractTitle');
          },
        },
      ];
    }
    return [
      {
        label: t('projectControl.createInvoiceTitle'),
        subtitle: t('projectControl.createNewInvoiceTitle'),
        icon: <AddIcon />,
        isDisabled: !!invoice.contractId && !canWriteInvoice,
        onClick: () => {
          handleCreateInvoiceTitle();
        },
      },
    ];
  }, [contract, t, canWriteContract, canWriteInvoice, handleCreateInvoiceTitle, invoice.contractId]);

  const handleDistributionChange = async (
    distributionType: InvoiceValueDistributionType,
    invoiceValue?: number | null,
  ) => {
    if (
      invoice?.id &&
      loadedProjectId &&
      loadedVariantId &&
      invoiceValue !== null &&
      typeof invoiceValue !== 'undefined' &&
      invoice.invoiceTitles.length &&
      distributionType !== 'Manual'
    ) {
      const result = await safeMutation(
        getDistribution,
        {
          invoiceId: invoice.id,
          projectId: loadedProjectId,
          calculationModelId: loadedVariantId,
          body: {
            distributionType: distributionType,
            invoiceValue: invoiceValue,
          },
        },
        isGettingDistribution,
      );
      setDistributedValues(result as InvoiceTitleDistributionReadModel[]);
    }
  };

  const distributionTypeOptions: BaseSelectOption[] = useMemo(() => {
    const types: BaseSelectOption[] = [
      {
        value: 'Manual',
        label: t('projectControl.invoiceTitleDistributionTypeManual'),
      },
    ];
    if (invoice.contractId) {
      types.push({
        value: 'Equal',
        label: t('projectControl.invoiceTitleDistributionTypeEqual'),
      });
      if (invoice.canBeDistributedByContractTitleForecast) {
        types.push({
          value: 'ByTitleForecast',
          label: t('projectControl.invoiceTitleDistributionTypeByTitleForecast'),
        });
      }
      if (invoice.canBeDistributedByPreviousInvoiceTitleValue) {
        types.push({
          value: 'ByPreviousInvoice',
          label: t('projectControl.invoiceTitleDistributionTypeByPreviousInvoice'),
        });
      }
    }
    return types;
  }, [
    t,
    invoice.contractId,
    invoice.canBeDistributedByContractTitleForecast,
    invoice.canBeDistributedByPreviousInvoiceTitleValue,
  ]);

  return (
    <div className={className}>
      {(isCreating || isGettingDistribution) && (
        <LoadingIndicator text={t('projectControl.saveInvoiceTitleLoadingIndicator')} mode="overlay-window" />
      )}
      {titles.length > 0 && invoice.state !== 'Paid' && !invoice.isPxInvoice && invoice.contractId && (
        <div className="my-4">
          <span className="font-light text-gray-500">{t('projectControl.distributeInvoiceValue')}</span>
          <div className="mt-2 bg-gray-100 p-2 flex flex-wrap gap-2 items-center justify-between">
            <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['distributionType', 'invoiceValue']}>
              {({ distributionType, invoiceValue }) => (
                <>
                  <FormField name="distributionType">
                    {(control) => (
                      <BaseSelect
                        className="w-full"
                        label={t('projectControl.invoiceValueDistributionType')}
                        options={distributionTypeOptions}
                        {...control}
                        onChange={(val: string) => {
                          handleDistributionChange(val as InvoiceValueDistributionType, invoiceValue);
                          control.onChange(val);
                        }}
                        disabled={!titles.length}
                      />
                    )}
                  </FormField>
                  {distributionType !== 'Manual' && (
                    <FormField name="invoiceValue">
                      {(control) => (
                        <NumberInput
                          label={t('projectControl.invoiceValue')}
                          icon={<EstimateIcon className="h-6 w-6" />}
                          className="w-full"
                          {...control}
                          disabled={!titles.length}
                          onBlur={() => {
                            handleDistributionChange(distributionType as InvoiceValueDistributionType, invoiceValue);
                          }}
                        />
                      )}
                    </FormField>
                  )}
                </>
              )}
            </FormWatch>

            {distributedValues !== null && (
              <Button variant="primary" className="px-4 py-1 ml-auto" onClick={saveDistribution} hasPadding={false}>
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      )}
      <div className="divide-y-2 mt-2">
        {titles.length > 0 && (
          <div className={invoice.state !== 'Paid' && !invoice.isPxInvoice ? 'pb-4' : ''}>
            {titles.map((title, i) => (
              <InvoiceListTitle
                key={`invoice-title-${title.id}-${i}`}
                title={title}
                distributedValues={distributedValues}
                onClick={() => {
                  setSelectedTitle(title);
                  setOpenSlideOver('invoiceTitle');
                }}
                invoice={invoice}
              />
            ))}
          </div>
        )}
        <div>
          {invoice.state !== 'Paid' && !invoice.isPxInvoice && invoice.state === 'Pending' && (
            <div className="w-full flex">
              <ContextMenu items={contextMenuItems} button={<AddButton />} className="-mt-6 ml-auto mr-0" />
            </div>
          )}
          {titles.length > 0 && invoice.invoiceCalculationRuleGroups.length === 0 && (
            <InvoiceTitleSum net={invoice.invoiceValueNet} gross={invoice.invoiceValueGross} />
          )}
          {invoice.invoiceCalculationRuleGroups.length > 0 && (
            <InvoiceCalculation invoice={invoice} isLastInvoice={isLastInvoice} budgetOverrun={budgetOverrun} budgetAssigned={budgetAssigned} />
          )}
        </div>
      </div>

      <SlideOver isOpen={openSlideOver === 'invoiceTitle'} onClose={() => setOpenSlideOver(null)}>
        {selectedTitle && (
          <InvoiceEditTitleSlideOver title={selectedTitle} invoice={invoice} onClose={() => setOpenSlideOver(null)} />
        )}
      </SlideOver>

      <SlideOver
        isOpen={openSlideOver === 'contractTitle'}
        onClose={() => {
          setOpenSlideOver(null);
        }}
      >
        {contract && (
          <ContractEditTitleSlideOver
            contract={contract}
            type={contractTitleType}
            onClose={() => {
              setOpenSlideOver(null);
            }}
            state="Commissioned"
          />
        )}
      </SlideOver>
    </div>
  );
};

interface InvoiceListTitleProps {
  title: InvoiceTitleReadModel;
  distributedValues: InvoiceTitleDistributionReadModel[] | null;
  onClick: () => void;
  invoice: InvoiceReadModel;
}

export const InvoiceListTitle = (props: InvoiceListTitleProps) => {
  const { title, distributedValues, onClick, invoice } = props;

  const titleInvoiceValue = useMemo(() => {
    let distributedInvoiceValue: number | null = null;
    let value: string | ReactNode = <FormattedCurrency amount={title.valueNet} />;
    if (distributedValues) {
      const foundDistVal = distributedValues.find(
        (val) => val.id === title.contractTitleId || val.id === title.invoiceId,
      );
      distributedInvoiceValue = foundDistVal?.value ?? null;
      if (distributedInvoiceValue !== null && distributedInvoiceValue !== title.valueNet) {
        value = (
          <>
            <div className="text-red-400 font-normal">
              <FormattedCurrency amount={distributedInvoiceValue} />
            </div>
            <div className="line-through">
              <FormattedCurrency amount={title.valueNet} />
            </div>
          </>
        );
      }
    }
    return value;
  }, [title.valueNet, distributedValues, title.contractTitleId, title.invoiceId]);

  return (
    <InvoiceTitle
      code={title.code}
      onClick={invoice.state !== 'Paid' ? onClick : undefined}
      title={title.contractTitleName}
      value={titleInvoiceValue}
      checked={title.checked}
      showCheckbox
    />
  );
};

interface InvoiceTitleProps {
  code: string;
  value: string | ReactNode;
  onClick?: () => void;
  title?: string | null;
  checked?: boolean;
  showCheckbox?: boolean;
}

export const InvoiceTitle = (props: InvoiceTitleProps) => {
  const { code, title, onClick, value, checked, showCheckbox = false } = props;
  return (
    <div
      className={cn('flex justify-between border-b border-dashed last:border-b-0 py-2', {
        'cursor-pointer hover:bg-slate-100 transition-colors duration-300': onClick,
      })}
      onClick={onClick}
    >
      <div className="flex">
        {showCheckbox &&
          (checked ? (
            <CheckmarkIcon className="h-6 w-6 mr-2 flex-none" />
          ) : (
            <div className="rounded-full w-5 h-5 border-2 border-black mr-2 flex-none mt-px ml-0.5" />
          ))}
        <span className="text-lg leading-none font-bold truncate flex-none mt-0.5 max-w-[100px]">{code}</span>
        {title && (
          <span
            className={cn(
              'text-[15px] font-light text-gray-500 ml-2 mr-4 flex-1 text-ellipsis overflow-hidden',
              {
                'max-w-[115px]': showCheckbox,
                'max-w-[145px]': !showCheckbox
              },
            )}
          >
            {title}
          </span>
        )}
      </div>

      <div className="flex flex-wrap gap-2 justify-end font-bold">{value}</div>
    </div>
  );
};

interface InvoiceTotalSumProps {
  net: number;
  gross: number;
  className?: string;
}

export const InvoiceTitleSum = (props: InvoiceTotalSumProps) => {
  const { net, gross, className } = props;
  const { t } = useTranslation();
  return (
    <div className={cn('mt-4', className)}>
      <div className="flex justify-between items-center text-[13px] font-bold">
        <span className="truncate">{t('common.net')}</span>
        <FormattedCurrency amount={net} />
      </div>
      <div className="flex justify-between items-center text-[13px]">
        <span className="truncate">{t('common.vat')}</span>
        <FormattedCurrency amount={gross - net} />
      </div>
      <div className="flex justify-between items-center text-lg font-bold">
        <span className="truncate text-[15px]">{t('common.gross')}</span>
        <FormattedCurrency amount={gross} />
      </div>
    </div>
  );
};
