import { BaseSelectOption } from '@client/shared/toolkit';
import { getAssetClassLabel } from '@client/project/store';
import { BenchmarkProjectReadModel } from '@client/shared/api';

export interface BenchmarkingFilterProject
  extends Omit<BenchmarkProjectReadModel, 'catalogElementValue' | 'adjustedCatalogElementValue' | 'regionalFactor'> {
  catalogElementValue?: number;
  adjustedCatalogElementValue?: number;
  regionalFactor?: number;
}

export const getBenchmarkProjectAssetClassOptions = (projects: BenchmarkingFilterProject[]) => {
  const uniqueAssetClassOptions: BaseSelectOption[] = [];
  projects.forEach((project) =>
    project.assetClasses.forEach((assetClass) => {
      if (!uniqueAssetClassOptions.find((option) => option.value === assetClass.id)) {
        uniqueAssetClassOptions.push({
          label: getAssetClassLabel(assetClass),
          value: assetClass.id,
        });
      }
    }),
  );
  return uniqueAssetClassOptions;
};
