import {
  CostElementReadmodel,
  EarningsElementReadModel,
  ElementTimelineReadModel,
  InflationElementReadModel,
  VatElementReadModel,
} from '@client/shared/api';
import { useCallback, useMemo, useRef } from 'react';
import { VatElementEditSection } from './VatElementEditSection';
import { InflationElementEditSection } from './InflationElementEditSection';
import { Button } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

export interface FeatureElementUpdateResponse {
  updatedTimeline: ElementTimelineReadModel;
  updatedUnitPrice: number;
}

export type FeatureElement = InflationElementReadModel | VatElementReadModel;

export interface FeatureElementRef {
  updateData: (element: FeatureElement) => Promise<FeatureElementUpdateResponse | undefined>;
}

export type FeatureElementFormulaResult = CostElementReadmodel | EarningsElementReadModel;

export interface FeatureElementEditProps {
  elementId: string;
  type: 'vat' | 'inflation';
  element: VatElementReadModel | InflationElementReadModel | null;
  isReadOnly?: boolean;
  section: 'costs' | 'earnings';
  setElement: (element: VatElementReadModel | InflationElementReadModel) => void;
  setIsUpdating: (isUpdating: boolean) => void;
  setElementTiming: (value: ElementTimelineReadModel | null) => void;
  setFormulaResult: (result: FeatureElementFormulaResult) => void;
  formulaResult?: FeatureElementFormulaResult | null;
  elementTiming?: ElementTimelineReadModel | null;
}

export const FeatureElementEdit = (props: FeatureElementEditProps) => {
  const { type, element, elementTiming, setElementTiming, formulaResult, setFormulaResult, isReadOnly } = props;
  const { t } = useTranslation();
  const featureElementRef = useRef<FeatureElementRef>(null);

  const onHandleUpdateData = useCallback(
    async (checkElement: FeatureElement) => {
      if (featureElementRef.current) {
        const result = await featureElementRef.current.updateData(checkElement);
        if (result) {
          const cleanUpdatedTimeline = {
            ...result.updatedTimeline,
            distribution: result.updatedTimeline.distribution
              ? {
                  ...result.updatedTimeline.distribution,
                  id: elementTiming?.distribution?.id ?? '',
                  distributionPattern: result.updatedTimeline.distribution
                    ? {
                        ...result.updatedTimeline.distribution,
                        distributionValues: result.updatedTimeline.distribution?.manualDistributionValues.map((v) => {
                          return {
                            ...v,
                            id: null,
                          };
                        }),
                        id: null,
                      }
                    : null,
                }
              : null,
            id: elementTiming?.id ?? '',
          };

          setElementTiming(cleanUpdatedTimeline);
          if (formulaResult) {
            const updatedFormulaResult: FeatureElementFormulaResult = {
              ...formulaResult,
              calculatedValue: result.updatedUnitPrice,
              unitPrice: {
                type: 'Static',
                staticValue: {
                  value: result.updatedUnitPrice,
                  unit: 'piece',
                },
              },
            };
            setFormulaResult(updatedFormulaResult);
          }
        }
      }
    },
    [elementTiming?.distribution?.id, elementTiming?.id, setElementTiming, formulaResult, setFormulaResult],
  );

  const editSection = useMemo(() => {
    switch (type) {
      case 'vat':
        return (
          <VatElementEditSection
            {...props}
            element={element as VatElementReadModel}
            ref={featureElementRef}
            handleChangeElement={onHandleUpdateData}
          />
        );
      case 'inflation':
        return (
          <InflationElementEditSection
            {...props}
            element={element as InflationElementReadModel}
            ref={featureElementRef}
            handleChangeElement={onHandleUpdateData}
          />
        );
      default:
        return '';
    }
  }, [type, element, props, onHandleUpdateData]);

  if (!element) return null;

  return (
    <>
      {editSection}
      {/*
        updating the data needs to be done manually if a referenced cost element in this feature element was updated
        this does not save, just fetches!
      */}
      <div className="w-full flex justify-end">
        <Button variant="secondary" className="mx-8" onClick={() => onHandleUpdateData(element)} disabled={isReadOnly}>
          {t('projectCalculate.costElementUpdateData')}
        </Button>
      </div>
    </>
  );
};
