import {
  CountrySpecificIncreasesPayload,
  CountrySpecificIncreaseValuesReadModel,
  Currency,
  CurrencySpecificConversionsPayload,
  CurrencySpecificConversionValuesReadModel,
  DefaultValuesReadModel,
  UnitSystem,
  useApiGetDefaultValuesQuery,
  useApiPostUpdateDefaultValuesMutation,
  ValueType,
} from '@client/shared/api';
import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  CURRENCIES,
  Form,
  FormRefHandle,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
} from '@client/shared/toolkit';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEdit } from './DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEdit';
import { DefaultValuesBenchmarkingCurrencyConversionValuesEdit } from './DefaultValuesBenchmarkingCurrencyConversionValuesEdit';
import { DefaultValueBenchmarkingValueEditForm } from './DefaultValueBenchmarkingValueEditForm';

export type BenchmarkingEditValues = {
  code: string;
  year: number;
  factor?: number;
};

export const EditBenchmarkingDefaultFormValidationSchema = yup.object({
  dueDateDeadline: yup.number().optional().integer(),
  cashDiscountDeadline: yup.number().optional().integer(),
  currency: yup.mixed<Currency>().oneOf(CURRENCIES).required('validation.required'),
  valueType: yup.mixed<ValueType>().oneOf(['Net', 'Gross']).required('validation.required'),
  unitSystem: yup.mixed<UnitSystem>().oneOf(['Metric', 'Imperial']).required('validation.required')
});
export type EditBenchmarkingDefaultFormValidationValues = InferType<typeof EditBenchmarkingDefaultFormValidationSchema>;

export interface DefaultValuesBenchmarkingEditSlideOverProps extends SlideOverOnCloseProps {
  defaultValues?: DefaultValuesReadModel;
}

export const EditBenchmarkingDefaultValueValidationSchema = yup.object({
  code: yup.string().required(),
  year: yup.string().required(),
  factor: yup.number().required(),
});
export type EditBenchmarkingDefaultValueValidationValues = InferType<
  typeof EditBenchmarkingDefaultValueValidationSchema
>;

export interface DefaultValuesBenchmarkingEditSlideOverProps extends SlideOverOnCloseProps {
  defaultValues?: DefaultValuesReadModel;
}

export type BenchmarkingValueType = 'constructionCosts' | 'rental' | 'sales' | 'currency';
export type UsedYearsType = {
  [key: string]: number[];
};

export const DefaultValuesBenchmarkingEditSlideOver = ({
  defaultValues,
  onClose,
}: DefaultValuesBenchmarkingEditSlideOverProps) => {
  const { t } = useTranslation();
  const formRef = useRef<FormRefHandle<EditBenchmarkingDefaultFormValidationValues>>();

  const [addMode, setAddMode] = useState<BenchmarkingValueType | null>(null);
  const [preselectedCountryCode, setPreselectedCountryCode] = useState<string | undefined>();
  const [preselectedCurrency, setPreselectedCurrency] = useState<string | undefined>();

  const { data: response, isFetching } = useApiGetDefaultValuesQuery(undefined, {
    skip: typeof defaultValues !== 'undefined',
  });
  const [updateDefaultValues, { isLoading: isUpdating }] = useApiPostUpdateDefaultValuesMutation();

  const defaultBenchmarkingValues = useMemo(() => {
    return defaultValues ?? response;
  }, [defaultValues, response]);

  const defaultFormValues = {
    cashDiscountDeadline: defaultBenchmarkingValues?.cashDiscountDeadline ?? undefined,
    dueDateDeadline: defaultBenchmarkingValues?.dueDateDeadline ?? undefined,
    currency: defaultBenchmarkingValues?.currency ?? 'Eur',
    valueType: defaultBenchmarkingValues?.valueType ?? 'Net',
    unitSystem: defaultBenchmarkingValues?.unitSystem ?? 'Metric',
  };

  const handleBenchmarkingFormSubmit = async (
    data: EditBenchmarkingDefaultFormValidationValues,
    benchmarkingData?: {
      [key: string]: CountrySpecificIncreasesPayload[] | CurrencySpecificConversionsPayload[];
    },
  ) => {
    if (defaultBenchmarkingValues) {
      try {
        await safeMutation(
          updateDefaultValues,
          {
            defaultValuesId: defaultBenchmarkingValues.id,
            body: {
              dueDateDeadline: data.dueDateDeadline,
              cashDiscountDeadline: data.cashDiscountDeadline,
              currency: data.currency,
              valueType: data.valueType,
              unitSystem: data.unitSystem,
              constructionCostIncreases:
                benchmarkingData && benchmarkingData['constructionCosts']
                  ? (benchmarkingData['constructionCosts'] as CountrySpecificIncreasesPayload[])
                  : (defaultBenchmarkingValues.constructionCostIncreases as CountrySpecificIncreasesPayload[]) ?? [],
              rentalIncreases:
                benchmarkingData && benchmarkingData['rental']
                  ? (benchmarkingData['rental'] as CountrySpecificIncreasesPayload[])
                  : (defaultBenchmarkingValues.rentalIncreases as CountrySpecificIncreasesPayload[]) ?? [],
              salesIncreases:
                benchmarkingData && benchmarkingData['sales']
                  ? (benchmarkingData['sales'] as CountrySpecificIncreasesPayload[])
                  : (defaultBenchmarkingValues.salesIncreases as CountrySpecificIncreasesPayload[]) ?? [],
              currencyConversions:
                benchmarkingData && benchmarkingData['currency']
                  ? (benchmarkingData['currency'] as CurrencySpecificConversionsPayload[])
                  : (defaultBenchmarkingValues.currencyConversions as CurrencySpecificConversionsPayload[]) ?? [],
            },
          },
          isUpdating,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getValuesForType = (type: BenchmarkingValueType) => {
    if (defaultBenchmarkingValues && formRef.current) {
      const updateData: {
        [key: string]: CountrySpecificIncreasesPayload[] | CurrencySpecificConversionsPayload[];
      } = {
        constructionCosts: [...defaultBenchmarkingValues.constructionCostIncreases],
        rental: [...defaultBenchmarkingValues.rentalIncreases],
        sales: [...defaultBenchmarkingValues.salesIncreases],
        currency: [...defaultBenchmarkingValues.currencyConversions],
      };

      let yearlyData: CountrySpecificIncreaseValuesReadModel[] | CurrencySpecificConversionValuesReadModel[] = [];
      switch (type) {
        case 'constructionCosts':
          yearlyData = [...defaultBenchmarkingValues.constructionCostIncreases];
          break;
        case 'rental':
          yearlyData = [...defaultBenchmarkingValues.rentalIncreases];
          break;
        case 'sales':
          yearlyData = [...defaultBenchmarkingValues.salesIncreases];
          break;
        case 'currency':
          yearlyData = [...defaultBenchmarkingValues.currencyConversions];
          break;
        default:
          break;
      }

      return {
        updateData: updateData,
        yearlyData: yearlyData,
      };
    }

    return {
      updateData: {
        constructionCosts: [],
        rental: [],
        sales: [],
      },
      yearlyData: [],
    };
  };

  const addOrUpdateCountrySpecificValue = async (data: BenchmarkingEditValues, type: BenchmarkingValueType) => {
    if (defaultBenchmarkingValues && formRef.current) {
      const { updateData, yearlyData } = getValuesForType(type);

      const mappedYearlyData =
        type === 'currency'
          ? (yearlyData as CurrencySpecificConversionValuesReadModel[])
          : (yearlyData as CountrySpecificIncreaseValuesReadModel[]);

      // find country code in existing data
      const foundIndex =
        type === 'currency'
          ? mappedYearlyData.findIndex(
              (currencyYearlyData) =>
                (currencyYearlyData as CurrencySpecificConversionValuesReadModel).currency === data.code,
            )
          : mappedYearlyData.findIndex(
              (specificData) => (specificData as CountrySpecificIncreaseValuesReadModel).countryCode === data.code,
            );

      if (foundIndex >= 0) {
        const specificData: CountrySpecificIncreasesPayload | CurrencySpecificConversionsPayload | undefined = {
          ...yearlyData[foundIndex],
        };
        const yearlyValues = [...specificData.yearlyValues];

        // check if year already exists
        const foundYearIndex = yearlyValues.findIndex((yearValue) => yearValue.year === data.year);
        if (foundYearIndex >= 0) {
          const yearlyValueCopy = { ...yearlyValues[foundYearIndex] };

          // update the value
          if (data.factor) {
            yearlyValueCopy.factor = data.factor;
            yearlyValues[foundYearIndex] = yearlyValueCopy;
          } else {
            // delete the value
            yearlyValues.splice(foundYearIndex, 1);
          }
        } else if (data.factor) {
          // otherwise create a new one
          yearlyValues.push({
            year: data.year,
            factor: data.factor,
          });
        }

        // update the update data
        if (yearlyValues.length) {
          specificData.yearlyValues = yearlyValues;
          yearlyData[foundIndex] = specificData;
        } else {
          // delete country code if empty
          yearlyData.splice(foundIndex, 1);
        }
        updateData[type] = yearlyData;
        await handleBenchmarkingFormSubmit(formRef.current.getValues(), updateData);
      } else if (data.factor) {
        if (type === 'currency') {
          const newDataSet = {
            currency: data.code,
            yearlyValues: [
              {
                year: data.year,
                factor: data.factor,
              },
            ],
          } as CurrencySpecificConversionValuesReadModel;
          (yearlyData as CurrencySpecificConversionValuesReadModel[]).push(newDataSet);
        } else {
          const newDataSet = {
            countryCode: data.code,
            yearlyValues: [
              {
                year: data.year,
                factor: data.factor,
              },
            ],
          } as CountrySpecificIncreaseValuesReadModel;
          (yearlyData as CountrySpecificIncreaseValuesReadModel[]).push(newDataSet);
        }
        updateData[type] = yearlyData;
        await handleBenchmarkingFormSubmit(formRef.current.getValues(), updateData);
      }
    }
    setAddMode(null);
  };

  const addSlideOverTitle = useMemo(() => {
    switch (addMode) {
      case 'constructionCosts':
        return t('projectSettings.benchmarking.increaseConstructionCosts');
      case 'rental':
        return t('projectSettings.benchmarking.rentIncrease');
      case 'sales':
        return t('projectSettings.benchmarking.salesIncreases');
      case 'currency':
        return t('projectSettings.benchmarking.currencyConversion');
      default:
        return '';
    }
  }, [addMode, t]);

  const usedYears = useMemo(() => {
    let yearlyData: CountrySpecificIncreaseValuesReadModel[] | CurrencySpecificConversionValuesReadModel[] = [];
    switch (addMode) {
      case 'constructionCosts':
        yearlyData = defaultBenchmarkingValues?.constructionCostIncreases ?? [];
        break;
      case 'rental':
        yearlyData = defaultBenchmarkingValues?.rentalIncreases ?? [];
        break;
      case 'sales':
        yearlyData = defaultBenchmarkingValues?.salesIncreases ?? [];
        break;
      case 'currency':
        yearlyData = defaultBenchmarkingValues?.currencyConversions ?? [];
        break;
      default:
        break;
    }

    const groupedYears: UsedYearsType = {};
    yearlyData.forEach((valueData) => {
      const key =
        addMode === 'currency'
          ? (valueData as CurrencySpecificConversionValuesReadModel).currency
          : (valueData as CountrySpecificIncreaseValuesReadModel).countryCode;
      groupedYears[key] = valueData.yearlyValues.map((yearlyValue) => yearlyValue.year);
    });
    return groupedYears;
  }, [addMode, defaultBenchmarkingValues]);

  const updateAddMode = (type: BenchmarkingValueType, countryCode?: string, currency?: string) => {
    setPreselectedCountryCode(countryCode);
    setPreselectedCurrency(currency);
    setAddMode(type);
  };

  const onDeleteValues = async (type: BenchmarkingValueType, countryCode?: string, currency?: string) => {
    const { updateData, yearlyData } = getValuesForType(type);
    const mappedYearlyData= [...(type === 'currency'
        ? (yearlyData as CurrencySpecificConversionValuesReadModel[])
        : (yearlyData as CountrySpecificIncreaseValuesReadModel[]))]

    // find country code in existing data
    const foundIndex =
      type === 'currency' && currency
        ? mappedYearlyData.findIndex(
            (currencyYearlyData) =>
              (currencyYearlyData as CurrencySpecificConversionValuesReadModel).currency === currency,
          )
        : countryCode
          ? mappedYearlyData.findIndex(
              (specificData) => (specificData as CountrySpecificIncreaseValuesReadModel).countryCode === countryCode,
            )
          : -1;

    if (formRef.current && foundIndex >= 0) {
      mappedYearlyData.splice(foundIndex, 1);
      const updateDataCopy = {...updateData};
      updateDataCopy[type] = type === 'currency' ? mappedYearlyData as CurrencySpecificConversionsPayload[] : mappedYearlyData as CountrySpecificIncreasesPayload[];
      await handleBenchmarkingFormSubmit(formRef.current.getValues(), updateDataCopy);
    }
  };

  const minMaxYear = useMemo(() => {
    const min = defaultBenchmarkingValues?.minProjectStart
      ? new Date(defaultBenchmarkingValues.minProjectStart).getFullYear()
      : undefined;
    const max = defaultBenchmarkingValues?.maxProjectEnd
      ? new Date(defaultBenchmarkingValues.maxProjectEnd).getFullYear()
      : undefined;

    return {
      min: min,
      max: max,
    };
  }, [defaultBenchmarkingValues]);

  if (!defaultBenchmarkingValues) {
    return null;
  }

  return (
    <>
      <SlideOver.Header
        onClose={() => onClose(false)}
        title={`${t('projectSetting.benchmarking.title')} - ${t('app.masterDataDefaultValues.title')}`}
        backgroundClassName="bg-sky-900"
      />
      <Form<EditBenchmarkingDefaultFormValidationValues>
        onSubmit={(data) => handleBenchmarkingFormSubmit(data)}
        validationSchema={EditBenchmarkingDefaultFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
        ref={formRef}
      >
        <SlideOver.Content className="p-8 divide-y">
          {isFetching && <LoadingIndicator text={t('app.masterDataDefaultValues.Loading')} />}
          <DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEdit
            title={t('projectSettings.benchmarking.increaseConstructionCosts')}
            data={defaultBenchmarkingValues.constructionCostIncreases}
            type="constructionCosts"
            setAddMode={updateAddMode}
            onChange={(data) => addOrUpdateCountrySpecificValue(data, 'constructionCosts')}
            onDelete={onDeleteValues}
          />
          <DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEdit
            title={t('projectSettings.benchmarking.rentIncrease')}
            data={defaultBenchmarkingValues.rentalIncreases}
            type="rental"
            setAddMode={updateAddMode}
            onChange={(data) => addOrUpdateCountrySpecificValue(data, 'rental')}
            onDelete={onDeleteValues}
          />
          <DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEdit
            title={t('projectSettings.benchmarking.salesIncreases')}
            data={defaultBenchmarkingValues.salesIncreases}
            type="sales"
            setAddMode={updateAddMode}
            onChange={(data) => addOrUpdateCountrySpecificValue(data, 'sales')}
            onDelete={onDeleteValues}
          />
          <DefaultValuesBenchmarkingCurrencyConversionValuesEdit
            title={t('projectSettings.benchmarking.currencyConversion')}
            data={defaultBenchmarkingValues.currencyConversions}
            setAddMode={(currency) => updateAddMode('currency', undefined, currency)}
            onChange={(data) => addOrUpdateCountrySpecificValue(data, 'currency')}
            onDelete={(currency) => onDeleteValues('currency', undefined, currency)}
            defaultCurrency={defaultBenchmarkingValues?.currency ?? 'Eur'}
          />
        </SlideOver.Content>

        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
        </SlideOver.Controls>
      </Form>
      <SlideOver isOpen={addMode != null} onClose={() => setAddMode(null)}>
        {addMode && (
          <DefaultValueBenchmarkingValueEditForm
            onSubmit={(data) => addOrUpdateCountrySpecificValue(data, addMode)}
            onCancel={() => setAddMode(null)}
            title={addSlideOverTitle}
            usedYears={usedYears}
            code={preselectedCountryCode ?? preselectedCurrency}
            minProjectYear={minMaxYear.min}
            maxProjectYear={minMaxYear.max}
            type={addMode}
            defaultCurrency={defaultBenchmarkingValues.currency}
          />
        )}
      </SlideOver>
    </>
  );
};
