import { Avatar } from '.';
import md5 from 'blueimp-md5';

interface IProps {
  tenantId: string;
  tenantName: string;
  hasAvatar: boolean;
  lastUpdate: string;
  classNames?: string;
}

export const CompanyAvatar = ({ tenantId, tenantName, hasAvatar, lastUpdate, classNames }: IProps) => {
  const updateHash = md5(lastUpdate);
  const avatarPath = hasAvatar ? `/api/tenant/avatar?updated=${updateHash}` : undefined;

  const classes = classNames ? classNames : 'w-12 h-12';

  return <Avatar name={tenantName} className={classes} rounded={false} randomBgColor={false} imagePath={avatarPath} />;
};
