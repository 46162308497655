import { TemplateScenarioShortReadModel } from '@client/shared/api';
import { ContextMenu, Highlighted, PencilIcon } from '@client/shared/toolkit';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface TemplateScenarioRowProps {
  scenario: TemplateScenarioShortReadModel;
  handleEdit: (scenario: TemplateScenarioShortReadModel) => void;
  searchValue?: string;
}

export const TemplateScenarioRow = ({ scenario, handleEdit, searchValue }: TemplateScenarioRowProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row">
      <div className="w-full flex flex-row py-2 text-gray-500 hover:text-black hover:bg-gray-100 cursor-pointer">
        <div className="flex flex-row w-4/12">
          <div className="flex mx-3">
            <RectangleGroupIcon className="w-6 " />
          </div>

          <div className="">
            <div className="font-bold text-base text-gray-900">{searchValue ? <Highlighted text={scenario.name} highlight={searchValue} /> : scenario.name}</div>
          </div>
        </div>
      </div>
      <div className="py-2">
        <div className="">
          <ContextMenu
            items={[
              {
                label: t('common.edit'),
                subtitle: t('templates.editScenario'),
                icon: <PencilIcon />,
                onClick: () => {
                  handleEdit(scenario);
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
