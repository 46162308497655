import {
  LicenseFeature,
  LicenseFeatureReadModel,
  LicenseFeatureType,
  ProbisPermission,
  useApiGetAuthPermissionDefinitionsQuery,
  useApiGetLicenseFeaturesQuery,
} from '@client/shared/api';
import { useUi } from '@client/shared/store';
import { RouteConfig } from '../routes';
import { useMemo } from 'react';

export function useValidateProjectPermission(permissions: ProbisPermission[], projectId: string): boolean {
  const ui = useUi();
  const user = ui.appUser;

  if (!user.permissions) {
    return false;
  }

  return permissions.every(
    (permission) =>
      user.permissions?.permissions.some(
        (y) =>
          (y.permission === permission && !y.isProjectSpecific) ||
          (y.permission === permission && y.isProjectSpecific && y.effectedProjects.includes(projectId)),
      ),
  );
}

export function useValidateTenantPermission(permissions: ProbisPermission[]): boolean {
  const ui = useUi();
  const user = ui.appUser;

  if (!user.permissions) {
    return false;
  }

  return permissions.every(
    (permission) => user.permissions?.permissions.some((y) => y.permission === permission && !y.isProjectSpecific),
  );
}

export function useValidateCatalogHasRestrictions(projectId: string, catalogId: string | undefined | null): boolean {
  const ui = useUi();
  const user = ui.appUser;

  if (!user.permissions || !catalogId) {
    return false;
  }

  return user.permissions.catalogTemplates.some(
    (x) => x.catalogId === catalogId && !x.canSeeAllElements && (!x.effectedProject || x.effectedProject === projectId),
  );
}

export function useValidateCanReadCatalogElement(
  projectId: string,
  catalogId: string | undefined | null,
  catalogElementId: string | undefined | null,
): boolean {
  const ui = useUi();
  const user = ui.appUser;

  if (!user.permissions) {
    return false;
  }

  if (!catalogId || !catalogElementId) {
    return true;
  }

  const template =
    user.permissions.catalogTemplates.find((x) => x.effectedProject === projectId && x.catalogId === catalogId) ??
    user.permissions.catalogTemplates.find((x) => !x.effectedProject && x.catalogId === catalogId);

  if (!template) {
    return true;
  }

  return (
    template.catalogElementPermissions.find((x) => x.catalogElementId === catalogElementId)?.canReadElement ?? false
  );
}

export function useValidateCanWriteCatalogElement(
  projectId: string,
  catalogId: string | undefined | null,
  catalogElementId: string | undefined | null,
): boolean {
  const ui = useUi();
  const user = ui.appUser;

  if (!user.permissions) {
    return false;
  }

  if (!catalogId || !catalogElementId) {
    return true;
  }

  const template =
    user.permissions.catalogTemplates.find((x) => x.effectedProject === projectId && x.catalogId === catalogId) ??
    user.permissions.catalogTemplates.find((x) => !x.effectedProject && x.catalogId === catalogId);

  if (!template) {
    return true;
  }

  return (
    template.catalogElementPermissions.find((x) => x.catalogElementId === catalogElementId)?.canWriteElement ?? false
  );
}

export function useValidateLicenses(licenses: LicenseFeature[], licenseFeatures: LicenseFeatureReadModel[]): {
  valid: boolean;
  licenseTypes: LicenseFeatureType[];
} {
  const ui = useUi();
  const user = ui.appUser;

  if (!user.permissions || !user.permissions?.licenses) {
    return { valid: false, licenseTypes: [] };
  }

  return {
    valid: licenses.every((license) => user.permissions?.licenses.some((y) => y.feature === license)),
    licenseTypes: licenseFeatures.filter((x) => licenses.includes(x.feature)).map((x) => x.featureType),
  };
}

export function useValidatePermissionsAndLicenses(routeConfig: RouteConfig, projectId?: string) {
  const { data: licenseData } = useApiGetLicenseFeaturesQuery(undefined, {
    skip: !routeConfig.licenses.length || routeConfig.disabled,
  });

  const hasTenantPermissions = useValidateTenantPermission(routeConfig.permissions);
  const hasProjectPermissions = useValidateProjectPermission(routeConfig.permissions, projectId ?? '');
  const { valid: hasTenantLicense, licenseTypes } = useValidateLicenses(routeConfig.licenses, licenseData ?? []);

  const { data: permissionsData, isFetching = false } = useApiGetAuthPermissionDefinitionsQuery(undefined, {
    skip: (!routeConfig.permissions.length && !routeConfig.licenses.length) || routeConfig.disabled,
  });

  const hasPermission = useMemo(() => {
    // no permissions and licenses check necessary
    if (!routeConfig.permissions.length) {
      return true;
    }

    // check permissions
    let hasPermission = false;
    if (routeConfig.permissions.length) {
      if (permissionsData) {
        const routePermissions = routeConfig.permissions;
        const permissionData = permissionsData.filter((data) => {
          return routePermissions.includes(data.name);
        });

        const tenantScope = permissionData.find((data) => {
          return data.scope === 'Tenant';
        });

        // only if tenant scope we check tenant permissions
        hasPermission = tenantScope ? hasTenantPermissions : projectId ? hasProjectPermissions : true;
      } else {
        return false;
      }
    } else {
      hasPermission = true;
    }

    return hasPermission;
  }, [hasProjectPermissions, hasTenantPermissions, permissionsData, projectId, routeConfig.permissions]);

  const hasLicense = useMemo(() => {
    // no licenses check necessary
    if (!routeConfig.licenses.length) {
      return true;
    }

    // check licenses
    const hasLicense = routeConfig.licenses.length ? hasTenantLicense : true;
    return hasLicense;
  }, [hasTenantLicense, routeConfig.licenses.length]);

  return useMemo(() => {
    return {
      hasPermissionsAndLicense: hasPermission && hasLicense,
      licenseTypes: licenseTypes,
      permissionsData: permissionsData,
      isLoading: isFetching,
    };
  }, [hasPermission, hasLicense, licenseTypes, permissionsData, isFetching]);
}

export function useHasUploadLicense() {
  const ui = useUi();
  const user = ui.appUser;

  return useMemo(() => {
    return !!user.permissions?.licenses.find((license) => license.feature === 'UPLOAD');
  }, [user.permissions?.licenses]);
}
