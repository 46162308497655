import {
  AddButton,
  BaseSelect,
  ComboSelect,
  ComboSelectOption,
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import * as yup from 'yup';
import { InferType } from 'yup';
import cn from 'classnames';
import { KpisWidgetKpiType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const WidgetEditFormKPIsValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  kpis: yup.array().of(yup.string().required('validation.required')).min(1).max(10).required('validation.required'),
});

export type WidgetDashboardEditFormKpiValidationValues = InferType<typeof WidgetEditFormKPIsValidationSchema>;

type KpiSection = 'taxonomy' | 'project' | 'calculate';

export type KpiTypesBySection = {
  [key in KpiSection]: KpisWidgetKpiType[];
};

const ALL_KPI_TYPES_BY_SECTION: KpiTypesBySection = {
  taxonomy: ['Taxonomy_GFA', 'Taxonomy_NGF', 'Taxonomy_RentalSpace', 'Taxonomy_Plot', 'Taxonomy_FAR', 'Taxonomy_SOI'],
  project: ['Project_Address', 'Project_Runtime', 'Project_StartEnd'],
  calculate: [
    'Calculate_CostsPerSquareMeter',
    'Calculate_RevenuesPerSquareMeter',
    'Calculate_PlannedCosts',
    'Calculate_Deviation',
    'Calculate_ROI',
    'Calculate_ROE',
    'Calculate_PlannedRevenues',
    'Calculate_ProjectIRR',
    'Calculate_EquityIRR',
    'Calculate_Equity',
    'Calculate_DebtCapital',
    'Calculate_Subsidies',
    'Calculate_Funds',
    'Calculate_EquityRatio',
    'Calculate_TotalProfit',
  ],
};

export const getKPILabelByType = (type: string) => {
  switch (type) {
    case 'Taxonomy_GFA':
      return i18n.t('dashboard.widget.kpis.type.taxonomy.gfa');
    case 'Taxonomy_NGF':
      return i18n.t('dashboard.widget.kpis.type.taxonomy.ngf');
    case 'Taxonomy_RentalSpace':
      return i18n.t('dashboard.widget.kpis.type.taxonomy.rentalSpace');
    case 'Taxonomy_Plot':
      return i18n.t('dashboard.widget.kpis.type.taxonomy.plot');
    case 'Taxonomy_FAR':
      return i18n.t('dashboard.widget.kpis.type.taxonomy.far');
    case 'Taxonomy_SOI':
      return i18n.t('dashboard.widget.kpis.type.taxonomy.soi');
    case 'Project_Address':
      return i18n.t('dashboard.widget.kpis.type.project.address');
    case 'Project_Runtime':
      return i18n.t('dashboard.widget.kpis.type.project.runtime');
    case 'Project_StartEnd':
      return i18n.t('dashboard.widget.kpis.type.project.startEnd');
    case 'Calculate_CostsPerSquareMeter':
      return i18n.t('dashboard.widget.kpis.type.calculate.costsPerSquareMeter');
    case 'Calculate_RevenuesPerSquareMeter':
      return i18n.t('dashboard.widget.kpis.type.calculate.revenuesPerSquareMeter');
    case 'Calculate_PlannedCosts':
      return i18n.t('dashboard.widget.kpis.type.calculate.plannedCosts');
    case 'Calculate_Deviation':
      return i18n.t('dashboard.widget.kpis.type.calculate.deviation');
    case 'Calculate_ROI':
      return i18n.t('dashboard.widget.kpis.type.calculate.roi');
    case 'Calculate_ROE':
      return i18n.t('dashboard.widget.kpis.type.calculate.roe');
    case 'Calculate_PlannedRevenues':
      return i18n.t('dashboard.widget.kpis.type.calculate.plannedRevenues');
    case 'Calculate_ProjectIRR':
      return i18n.t('dashboard.widget.kpis.type.calculate.projectIRR');
    case 'Calculate_EquityIRR':
      return i18n.t('dashboard.widget.kpis.type.calculate.equityIRR');
    case 'Calculate_Equity':
      return i18n.t('dashboard.widget.kpis.type.calculate.equity');
    case 'Calculate_DebtCapital':
      return i18n.t('dashboard.widget.kpis.type.calculate.deptCapital');
    case 'Calculate_Subsidies':
      return i18n.t('dashboard.widget.kpis.type.calculate.subsidies');
    case 'Calculate_Funds':
      return i18n.t('dashboard.widget.kpis.type.calculate.funds');
    case 'Calculate_EquityRatio':
      return i18n.t('dashboard.widget.kpis.type.calculate.equityRatio');
    case 'Calculate_TotalProfit':
      return i18n.t('dashboard.widget.kpis.type.calculate.totalProfit');
    default:
      return '';
  }
};

const DEFAULT_SELECTED_KPI = 'Taxonomy_GFA';

export const WidgetEditFormKPIs = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize, dashboardType } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormKpiValidationValues>>();
  const [kpis, setKpis] = useState<string[]>([DEFAULT_SELECTED_KPI]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setKpis(widget?.widget.additionalConfig?.KPIs ?? [DEFAULT_SELECTED_KPI]);
  }, [widget?.widget.additionalConfig?.KPIs]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.kpis.title'),
      size: selectedSize,
      kpis: widget?.widget.additionalConfig?.KPIs ?? [DEFAULT_SELECTED_KPI],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.KPIs]);

  const onAdd = () => {
    if (kpis.length < 10) {
      const copy = [...kpis];
      copy.push(DEFAULT_SELECTED_KPI);
      setKpis(copy);
      formRef.current?.setValue('kpis', copy);
    }
  };

  const onUpdateKPI = (kpi: string | null, index: number) => {
    if (kpis[index] && kpi) {
      const copy = [...kpis];
      copy[index] = kpi;
      setKpis(copy);
      formRef.current?.setValue('kpis', copy);
    }
  };

  const onDeleteKPI = (index: number) => {
    if (kpis[index]) {
      const copy = [...kpis];
      copy.splice(index, 1);
      setKpis(copy);
      formRef.current?.setValue('kpis', copy);
    }
  };

  const kpiOptions: ComboSelectOption[] = useMemo(() => {
    const options: ComboSelectOption[] = [];
    Object.keys(ALL_KPI_TYPES_BY_SECTION).forEach((key) => {
      const sectionTypes = ALL_KPI_TYPES_BY_SECTION[key as KpiSection];
      const sectionOptions = sectionTypes.map((type) => {
        return {
          label: getKPILabelByType(type),
          value: type,
        };
      });
      options.push({
        label: t(`dashboard.widget.kpis.section.${key}`),
        value: key,
        disabled: true,
        options: sectionOptions,
      });
    });
    return options;
  }, [t]);

  const kpisLimit = useMemo(() => {
    return dashboardType === 'report' ? 8 : 10;
  }, [dashboardType]);

  return (
    <Form<WidgetDashboardEditFormKpiValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetEditFormKPIsValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <div className="relative z-40">
          <FormField name="title">
            {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
          </FormField>
          <FormField name="size">
            {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
          </FormField>

          <SlideOverTitle
            title={t('dashboard.widget.kpis.possibleKpisTitle')}
            className="w-full flex gap-2 flex-wrap items-center justify-between"
          >
            <span className="text-xs">{`${kpis.length} / ${kpisLimit}`}</span>
          </SlideOverTitle>
          {kpis.map((kpi, index) => (
            <div key={`kpi-${index}`} className="flex gap-2 items-center">
              <ComboSelect
                className="flex-1"
                onChange={(val) => onUpdateKPI(val, index)}
                nullable={false}
                label={t('dashboard.widget.kpis.title')}
                value={kpi}
                options={kpiOptions}
                handlePopoverVisibility={(isOpen) => setIsDropdownOpen(isOpen)}
              />
              <TrashIcon
                className={cn('w-5 h-5 flex-none transition-colors duration-300', {
                  'cursor-pointer text-gray-500 hover:text-gray-700': kpis.length > 1,
                  'cursor-not-allowed text-gray-300': kpis.length === 1,
                })}
                onClick={() => {
                  if (kpis.length > 1) {
                    setIsDropdownOpen(false);
                    onDeleteKPI(index);
                  }
                }}
              />
            </div>
          ))}
        </div>
        {kpis.length < kpisLimit && !isDropdownOpen && (
          <div className="flex w-full justify-end items-center z-50 relative">
            <AddButton onClick={onAdd} className="-mt-4 mr-10" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
