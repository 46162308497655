import { ColumnsIcon, DecoratedCard, LoadingIndicator, Modal, RowsIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  BenchmarkReportColumnConfigurationReadModel,
  BenchmarkReportColumnPayload,
  BenchmarkReportColumnReadModel,
  BenchmarkReportConfigurationReadModel,
  BenchmarkReportProjectAssignmentPayload,
  BenchmarkReportRowReadModel,
  SelectProjectReadModel,
  ShortProjectReadModel,
  useApiDeleteBenchmarkReportColumnMutation,
  useApiGetBenchmarkReportQuery,
  useApiPostUpdateBenchmarkReportColumnMutation,
  useApiPostUpdateBenchmarkReportConfigurationMutation,
} from '@client/shared/api';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import { BenchmarkingUserTypeFilter } from './BenchmarkingUserTypeFilter';
import { getBenchmarkProjectAssetClassOptions } from '@client/project/shared';
import {
  BenchmarkAddParameterWizard,
  BenchmarkAddProjectWizard,
  BenchmarkingColumnsSection,
  BenchmarkingFixedSection,
} from '..';
import { BENCHMARK_DRAG_TYPE } from './BenchmarkingHeader';

export interface BenchmarkingSectionProps {
  layout: BenchmarkingReportLayout;
  setIsAddWizardOpen: () => void;
  projects: (ShortProjectReadModel | SelectProjectReadModel)[];
  parameters: BenchmarkReportColumnConfigurationReadModel[];
  removeProject: (id: string) => void;
  removeParameter: (id: string) => void;
  addProjectsEnabled: boolean;
  rows: BenchmarkReportRowReadModel[];
  columns: BenchmarkReportColumnReadModel[];
  dropItem?: (id: string, toIndex: number, type: BENCHMARK_DRAG_TYPE) => void;
  hoverItem?: (index: number | null, type: BENCHMARK_DRAG_TYPE) => void;
  columnsSectionRef: React.RefObject<HTMLDivElement>;
}

export interface BenchmarkingReportProps {
  projects?: SelectProjectReadModel[];
  config?: BenchmarkReportConfigurationReadModel;
  addProjectsEnabled?: boolean;
}

export enum BenchmarkingReportLayout {
  BENCHMARK_COLUMNS,
  PROJECT_COLUMNS,
}

export const BenchmarkingReport = (props: BenchmarkingReportProps) => {
  const { projects, config, addProjectsEnabled = true } = props;
  const { t } = useTranslation();

  const columnsSectionRef = useRef<HTMLDivElement>(null);

  const [isAddColumnWizardOpen, setIsAddColumnWizardOpen] = useState(false);
  const [isAddProjectWizardOpen, setIsAddProjectWizardOpen] = useState(false);
  const [selectedUsageTypeFilter, setSelectedUsageTypeFilter] = useState<string | undefined>();

  const [layout, setLayout] = useState<BenchmarkingReportLayout>(BenchmarkingReportLayout.BENCHMARK_COLUMNS);

  const [updateConfig, { isLoading: isUpdating }] = useApiPostUpdateBenchmarkReportConfigurationMutation();
  const [removeColumn, { isLoading: isRemovingColumn }] = useApiDeleteBenchmarkReportColumnMutation();
  const [updateColumn, { isLoading: isUpdatingColumn }] = useApiPostUpdateBenchmarkReportColumnMutation();

  const { data: report, isFetching } = useApiGetBenchmarkReportQuery(
    {
      reportConfigurationId: config?.id ?? '',
      assetClassFilter: selectedUsageTypeFilter,
    },
    {
      skip: !config?.id,
    },
  );

  const selectedProjects: SelectProjectReadModel[] = useMemo(() => {
    if (addProjectsEnabled) {
      if (config?.projects && projects) {
        const sortedProjects: SelectProjectReadModel[] = [];
        config.projects.forEach((configProject) => {
          const foundProject = projects.find((project) => project.id === configProject.projectId);
          if (foundProject) {
            sortedProjects.push(foundProject);
          }
        });
        return sortedProjects;
      }
      return [];
    }
    return projects ?? [];
  }, [addProjectsEnabled, config?.projects, projects]);

  const handleConfigChange = useCallback(
    async (projects: BenchmarkReportProjectAssignmentPayload[]) => {
      if (config) {
        try {
          await safeMutation(
            updateConfig,
            {
              reportConfigurationId: config.id,
              body: {
                name: config.name,
                projects: projects,
              },
            },
            isUpdating,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [config, updateConfig, isUpdating],
  );

  const handleDeleteProject = useCallback(
    async (id: string) => {
      const selectedProjects = config?.projects.filter((element) => element.projectId !== id);
      await handleConfigChange(selectedProjects ?? []);
    },
    [config?.projects, handleConfigChange],
  );

  const handleDeleteColumn = useCallback(
    async (id: string) => {
      if (config) {
        try {
          await safeMutation(
            removeColumn,
            {
              reportConfigurationId: config.id,
              columnId: id,
            },
            isRemovingColumn,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [config, removeColumn, isRemovingColumn],
  );

  const handleUpdateColumn = useCallback(
    async (id: string, column: BenchmarkReportColumnPayload) => {
      if (config) {
        try {
          await safeMutation(
            updateColumn,
            {
              reportConfigurationId: config.id,
              columnId: id,
              body: column
            },
            isUpdatingColumn,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [config, updateColumn, isUpdatingColumn],
  );

  const assetClassOptions = useMemo(() => {
    return getBenchmarkProjectAssetClassOptions(projects ?? []);
  }, [projects]);

  const dropItem = async (id: string, toIndex: number, type: BENCHMARK_DRAG_TYPE) => {
    if (
      (layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS && type === BENCHMARK_DRAG_TYPE.COLUMN) ||
      (layout === BenchmarkingReportLayout.PROJECT_COLUMNS && type === BENCHMARK_DRAG_TYPE.ROW)
    ) {
      // Benchmarks update
      const columnToUpdate = config?.columns.find((column) => column.id === id);
      if (columnToUpdate && columnToUpdate.orderNumber !== toIndex) {
        const updatedColumn = {...columnToUpdate};
        if (updatedColumn.orderNumber < toIndex) {
          updatedColumn.orderNumber = toIndex + 1;
        } else {
          updatedColumn.orderNumber = toIndex - 1;
        }
        await handleUpdateColumn(id, updatedColumn)
      }
    } else {
      // Projects update
      if (config?.projects) {
        let updatedList = [...config.projects];
        const fromIndex = updatedList.findIndex((project) => project.projectId === id);
        if (fromIndex >= 0 && updatedList[fromIndex].orderNumber !== toIndex) {
          const [movedItem] = updatedList.splice(fromIndex, 1);
          updatedList.splice(toIndex, 0, movedItem);
          // we need now to update all order numbers...
          updatedList = updatedList.map((project, index) => {
            return {
              orderNumber: index,
              projectId: project.projectId,
            };
          });
          await handleConfigChange(updatedList);
        }
      }
    }
  };

  return (
    <>
      <DecoratedCard className="pb-0 min-h-full">
        <DecoratedCard.Header showActionButton={false}>
          <div className="flex flex-row justify-between items-center w-full">
            <div>{t('reporting.benchmarking.title')}</div>
            <div className="flex items-center mr-6 gap-3">
              <div className="font-bold text-[15px]">{t('reporting.filterBy')}</div>
              <BenchmarkingUserTypeFilter
                assetClasses={assetClassOptions}
                handleSelect={setSelectedUsageTypeFilter}
                selected={selectedUsageTypeFilter}
                disabled={!assetClassOptions.length || !config?.columns.length}
              />
              <div
                className="flex-none rounded-full font-medium transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer px-4 py-2 bg-slate-100 hover:bg-slate-200 text-slate-500"
                onClick={() =>
                  setLayout((prev) =>
                    prev === BenchmarkingReportLayout.BENCHMARK_COLUMNS
                      ? BenchmarkingReportLayout.PROJECT_COLUMNS
                      : BenchmarkingReportLayout.BENCHMARK_COLUMNS,
                  )
                }
              >
                {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS ? (
                  <ColumnsIcon className="w-6 h-6" />
                ) : (
                  <RowsIcon className="w-6 h-6" />
                )}
              </div>
            </div>
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full flex relative bg-slate-50 flex-1 py-6">
          {(isFetching || isUpdating || isRemovingColumn || isUpdatingColumn) && <LoadingIndicator mode="overlay-window" />}
          <div className="w-full flex overflow-hidden flex-1 px-6 pb-2">
            <BenchmarkingFixedSection
              layout={layout}
              projects={selectedProjects}
              removeProject={handleDeleteProject}
              setIsAddWizardOpen={() => {
                if (layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS) {
                  setIsAddProjectWizardOpen(true);
                } else {
                  setIsAddColumnWizardOpen(true);
                }
              }}
              addProjectsEnabled={addProjectsEnabled}
              parameters={config?.columns ?? []}
              removeParameter={handleDeleteColumn}
              rows={report?.rows ?? []}
              columns={report?.columns ?? []}
              dropItem={dropItem}
              columnsSectionRef={columnsSectionRef}
            />
            <BenchmarkingColumnsSection
              layout={layout}
              projects={selectedProjects}
              removeProject={handleDeleteProject}
              setIsAddWizardOpen={() => {
                if (layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS) {
                  setIsAddColumnWizardOpen(true);
                } else {
                  setIsAddProjectWizardOpen(true);
                }
              }}
              addProjectsEnabled={addProjectsEnabled}
              parameters={config?.columns ?? []}
              removeParameter={handleDeleteColumn}
              rows={report?.rows ?? []}
              columns={report?.columns ?? []}
              dropItem={dropItem}
              columnsSectionRef={columnsSectionRef}
            />
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>
      <Modal isOpen={isAddColumnWizardOpen} onClose={() => setIsAddColumnWizardOpen(false)}>
        <BenchmarkAddParameterWizard
          onClose={() => setIsAddColumnWizardOpen(false)}
          config={config}
        />
      </Modal>
      <Modal isOpen={isAddProjectWizardOpen} onClose={() => setIsAddProjectWizardOpen(false)}>
        <BenchmarkAddProjectWizard
          onClose={() => setIsAddProjectWizardOpen(false)}
          config={config}
          projects={projects}
        />
      </Modal>
    </>
  );
};
