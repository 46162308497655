import cn from 'classnames';
import { DocumentViewerFileDataInlineEdit, NumberInput, TrashIcon } from '../..';
import { ReactNode } from 'react';

interface SettingsInlineEditFieldProps {
  value: string;
  label: string;
  valueInput: number | null;
  onChange?: (value: number | null) => void;
  disabled?: boolean;
  updateEditMode?: (isEditMode: boolean) => void;
  onDelete?: () => void;
  icon?: ReactNode;
  isHighlighted?: boolean;
}

export const SettingsInlineEditField = (props: SettingsInlineEditFieldProps) => {
  const { value, valueInput, label, onChange, disabled, updateEditMode, onDelete, icon, isHighlighted = false } = props;
  return (
    <div
      className={cn('relative bg-gray-100 p-2 w-[130px] flex-none flex-col border-2 rounded overflow-hidden', {
        'hover:border-2 transition-colors duration-300': onChange && !disabled,
        'border-gray-100 hover:border-gray-200': !isHighlighted,
        'border-secondary': isHighlighted,
      })}
    >
      <DocumentViewerFileDataInlineEdit
        marginX=""
        marginY="my-0"
        closeOnBlur={false}
        toggleContentClassName="w-full overflow-hidden"
        toggleContent={
          onChange && !disabled ? (
            <NumberInput label={label} onChange={onChange} value={valueInput} min={1} max={100} />
          ) : undefined
        }
        updateEditMode={updateEditMode}
      >
        <div className="w-full bg-gray-100 relative">
          <div className="text-xs text-slate-500 font-medium">{label}</div>
          <div className="font-bold">{value}</div>
          {onDelete && (
            <TrashIcon
              className="text-slate-500 w-4 absolute top-0 right-0 hover:text-slate-700"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
            />
          )}
          {icon && <div className="text-slate-500 w-4 absolute top-0 right-0">{icon}</div>}
        </div>
      </DocumentViewerFileDataInlineEdit>
    </div>
  );
};
