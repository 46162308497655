import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  BaseSelect,
  BaseSelectOption,
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { BudgetStatusWidgetColumnType } from '@client/shared/api';
import { CostCatalogElementSelect } from '../../ElementSelects';

export const WidgetDashboardEditFormBudgetStatusValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  costCatalogElements: yup
    .array()
    .of(yup.string().required('validation.required'))
    .min(1)
    .required('validation.required'),
  columns: yup
    .array()
    .of(yup.mixed<BudgetStatusWidgetColumnType>().required('validation.required'))
    .min(1)
    .max(3)
    .required('validation.required'),
});

export type WidgetDashboardEditFormBudgetStatusValidationValues = InferType<
  typeof WidgetDashboardEditFormBudgetStatusValidationSchema
>;

const DEFAULT_BUDGET_STATUS_COLUMN_TYPE: BudgetStatusWidgetColumnType = 'CurrentBudget';

export const WidgetEditFormBudgetStatus = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormBudgetStatusValidationValues>>();
  const [columns, setColumns] = useState<BudgetStatusWidgetColumnType[]>([DEFAULT_BUDGET_STATUS_COLUMN_TYPE]);
  const [selectedCostCatalogElements, setSelectedCostCatalogElements] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setColumns(widget?.widget.additionalConfig?.BudgetStatus?.columns ?? [DEFAULT_BUDGET_STATUS_COLUMN_TYPE]);
    setSelectedCostCatalogElements(widget?.widget.additionalConfig?.BudgetStatus?.costCatalogElements ?? []);
  }, [widget?.widget.additionalConfig?.BudgetStatus]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.budgetStatus.title'),
      size: selectedSize,
      costCatalogElements: widget?.widget.additionalConfig?.BudgetStatus?.costCatalogElements ?? [],
      columns: widget?.widget.additionalConfig?.BudgetStatus?.columns ?? [DEFAULT_BUDGET_STATUS_COLUMN_TYPE],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.BudgetStatus]);

  const onAddColumn = () => {
    if (columns.length < 3) {
      const copy = [...columns];
      copy.push(DEFAULT_BUDGET_STATUS_COLUMN_TYPE);
      setColumns(copy);
      formRef.current?.setValue('columns', copy);
    }
  };

  const onUpdateColumn = (type: BudgetStatusWidgetColumnType, index: number) => {
    if (columns[index]) {
      const copy = [...columns];
      copy[index] = type;
      setColumns(copy);
      formRef.current?.setValue('columns', copy);
    }
  };

  const onDeleteColumn = (index: number) => {
    if (columns[index]) {
      const copy = [...columns];
      copy.splice(index, 1);
      setColumns(copy);
      formRef.current?.setValue('columns', copy);
    }
  };

  const columnsLimit = 3;

  useEffect(() => {
    formRef.current?.setValue('costCatalogElements', selectedCostCatalogElements);
  }, [selectedCostCatalogElements]);

  return (
    <Form<WidgetDashboardEditFormBudgetStatusValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormBudgetStatusValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
        <SlideOverTitle
          title={t('dashboard.widget.budgetStatus.costs')}
          className="w-full flex gap-2 flex-wrap items-center justify-between"
        />
        <CostCatalogElementSelect
          label={t('dashboard.widget.budgetStatus.costs')}
          selectedCostCatalogElements={selectedCostCatalogElements}
          setSelectedCostCatalogElements={setSelectedCostCatalogElements}
        />
        <SlideOverTitle
          title={t('dashboard.widget.budgetStatus.columnsTitle')}
          className="w-full flex gap-2 flex-wrap items-center justify-between"
        >
          <span className="text-xs">{`${columns.length} / ${columnsLimit}`}</span>
        </SlideOverTitle>
        {columns.map((type, index) => (
          <BudgetStatusColumnForm
            key={`budget-status-column-form-${index}`}
            type={type}
            index={index}
            onUpdate={(data: BudgetStatusWidgetColumnType) => onUpdateColumn(data, index)}
            canDelete={columns.length > 1}
            onDelete={() => onDeleteColumn(index)}
            handlePopoverVisibility={(isOpen) => setIsDropdownOpen(isOpen)}
          />
        ))}
        {columns.length < 3 && !isDropdownOpen && (
          <div className="flex w-full justify-end items-center z-50 relative">
            <AddButton onClick={onAddColumn} className="-mt-4" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};

export interface BudgetStatusColumnFormProps {
  type: BudgetStatusWidgetColumnType;
  onUpdate: (type: BudgetStatusWidgetColumnType) => void;
  index: number;
  canDelete: boolean;
  onDelete: () => void;
  handlePopoverVisibility?: (isOpen: boolean) => void;
}

export const BudgetStatusColumnForm = (props: BudgetStatusColumnFormProps) => {
  const { type, index, canDelete, onDelete, onUpdate, handlePopoverVisibility } = props;
  const { t } = useTranslation();

  const options: BaseSelectOption[] = useMemo(() => {
    return [
      {
        value: 'CurrentBudget',
        label: t('dashboard.widget.budgetStatus.columnType.currentBudget'),
      },
      {
        value: 'Obligo',
        label: t('dashboard.widget.budgetStatus.columnType.obligo'),
      },
      {
        value: 'Forecast',
        label: t('dashboard.widget.budgetStatus.columnType.forecast'),
      },
      {
        value: 'AbsolutePayment',
        label: t('dashboard.widget.budgetStatus.columnType.absolutePayment'),
      },
      {
        value: 'Payment',
        label: t('dashboard.widget.budgetStatus.columnType.payment'),
      },
      {
        value: 'MainContract',
        label: t('dashboard.widget.budgetStatus.columnType.mainContract'),
      },
      {
        value: 'Invoice',
        label: t('dashboard.widget.budgetStatus.columnType.invoice'),
      },
      {
        value: 'Supplement',
        label: t('dashboard.widget.budgetStatus.columnType.supplement'),
      },
      {
        value: 'TotalContract',
        label: t('dashboard.widget.budgetStatus.columnType.totalContract'),
      },
    ];
  }, [t]);

  return (
    <div className="flex justify-between items-center max-w-full gap-2 mt-1">
      <BaseSelect
        className="flex-1 truncate"
        options={options}
        label={`${t('dashboard.widget.budgetStatus.columnType.label')} ${index + 1}`}
        value={type}
        onChange={(val) => onUpdate(val as BudgetStatusWidgetColumnType)}
        handlePopoverVisibility={handlePopoverVisibility}
      />
      {canDelete && <TrashIcon className="text-gray-500 w-5 h-5 cursor-pointer flex-none" onClick={onDelete} />}
    </div>
  );
};
