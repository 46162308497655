import { useGetCurrency, useGetCurrencySymbol, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useApiGetBmwRiskReportQuery } from '@client/shared/api';
import {
  DecoratedCard,
  LoadingIndicator,
  MatrixCellData,
  SummaryCellData,
  WidgetChart,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
  WidgetMatrix,
  WidgetSummary,
  WidgetTableRow,
} from '@client/shared/toolkit';
import { formatCurrency, formatLargeNumber } from '@client/shared/utilities';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { BarController, ChartDataset, LinearScale, CategoryScale, BarElement, Tooltip, Chart as ChartJS } from 'chart.js';
import { useTranslation } from 'react-i18next';

ChartJS.register(BarController, CategoryScale, LinearScale, BarElement, Tooltip);

export const ReportRisk = () => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const currentCurrency = useGetCurrency();
  const currentCurrencySymbol = useGetCurrencySymbol();
  const { data: report, isFetching } = useApiGetBmwRiskReportQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  const tableDataRows: WidgetTableRow[] = useMemo(() => {
    const rows: WidgetTableRow[] = [];
    if (report?.top20RiskElements) {
      const elements = [...report.top20RiskElements];
      elements.forEach((riskElement) => {
        rows.push({
          cols: [
            riskElement.riskElementCode,
            riskElement.riskElementDescription,
            `${riskElement.probability} %`,
            formatCurrency(riskElement.riskElementValue, { maxDigits: 0, currency: currentCurrency }),
            riskElement.status ?? '-',
            riskElement.responsible ?? '-',
            riskElement.measure ?? '-',
          ],
        });
      });
    }
    return rows;
  }, [currentCurrency, report]);

  const tableTitles = [
    t('reporting.reportRisk.tableColumnID'),
    t('reporting.reportRisk.tableColumnDescription'),
    t('reporting.reportRisk.tableColumnProbability'),
    t('reporting.reportRisk.tableColumnCosts'),
    t('reporting.reportRisk.tableColumnStatus'),
    t('reporting.reportRisk.tableColumnResponsiblePerson'),
    t('reporting.reportRisk.tableColumnMeasure'),
  ];

  const matrixData = useMemo(() => {
    let matrixCells: MatrixCellData[][] = [];
    if (report?.matrix) {
      matrixCells = [
        [
          { topValue: report.matrix.a11.riskElementsCount, bottomValue: report.matrix.a11.riskElementsValue, color: 'bg-yellow-200'},
          { topValue: report.matrix.a12.riskElementsCount, bottomValue: report.matrix.a12.riskElementsValue, color: 'bg-red-300' },
          { topValue: report.matrix.a13.riskElementsCount, bottomValue: report.matrix.a13.riskElementsValue, color: 'bg-red-300' }, 
        ],
        [
          { topValue: report.matrix.a21.riskElementsCount, bottomValue: report.matrix.a21.riskElementsValue, color: 'bg-green-300' },
          { topValue: report.matrix.a22.riskElementsCount, bottomValue: report.matrix.a22.riskElementsValue, color: 'bg-yellow-200' },
          { topValue: report.matrix.a23.riskElementsCount, bottomValue: report.matrix.a13.riskElementsValue, color: 'bg-red-300' },        
        ],
        [
          { topValue: report.matrix.a31.riskElementsCount, bottomValue: report.matrix.a31.riskElementsValue, color: 'bg-green-300' },
          { topValue: report.matrix.a32.riskElementsCount, bottomValue: report.matrix.a32.riskElementsValue, color: 'bg-green-300' },
          { topValue: report.matrix.a33.riskElementsCount, bottomValue: report.matrix.a33.riskElementsValue, color: 'bg-yellow-200' },        
        ],
      ];
    }
    return matrixCells;
  }, [report?.matrix]);

  const summaryData = useMemo(() => {
    let summaryCells: SummaryCellData[] = [];
    if (report) {
      summaryCells = [
        { label: t('reporting.reportRisk.totalRAndOs'), value: report.totalRAndOs },
        { label: t('reporting.reportRisk.totalRisks'), value: report.totalPositiveRiskElementsCount },
        { label: t('reporting.reportRisk.totalOpportunities'), value: report.totalOpportunitiesCount },
        { label: t('reporting.reportRisk.totalHighRAndOs'), value: report.totalHighRAndOsCount },
        {
          label: t('reporting.reportRisk.totalValueOfRAndOs'),
          value: formatCurrency(report.totalValueOfRAndOs, { maxDigits: 0, currency: currentCurrency }),
        },
        {
          label: t('reporting.reportRisk.totalRiskValue'),
          value: formatCurrency(report.totalRiskValue, { maxDigits: 0, currency: currentCurrency }),
        },
        {
          label: t('reporting.reportRisk.totalOpportunitiesValue'),
          value: formatCurrency(report.totalOpportunitiesValue, { maxDigits: 0, currency: currentCurrency }),
        },
        {
          label: t('reporting.reportRisk.totalValueOfHighRAndOs'),
          value: formatCurrency(report.totalValueOfHighRAndOs, { maxDigits: 0, currency: currentCurrency }),
        },
      ];
    }
    return summaryCells;
  }, [currentCurrency, report, t]);

  const chartData = useMemo(
    () => ({
      labels: [
        t('reporting.reportRisk.chartLow'),
        t('reporting.reportRisk.chartMedium'),
        t('reporting.reportRisk.chartHigh'),
      ],
      datasets: [
        {
          label: t('reporting.reportRisk.chartLabelRisks'),
          data: [
            report?.lowProbability.riskElementsValue ?? 0,
            report?.mediumProbability.riskElementsValue ?? 0,
            report?.highProbability.riskElementsValue ?? 0,
          ],
          backgroundColor: ['rgb(254, 240, 138)', 'rgb(134, 239, 172)', 'rgb(252, 165, 165)'],
        },
        {
          label: t('reporting.reportRisk.chartLabelOpportunities'),
          data: [
            report?.lowProbability.opportunityElementsValue ?? 0,
            report?.mediumProbability.opportunityElementsValue ?? 0,
            report?.highProbability.opportunityElementsValue ?? 0,
          ],
          backgroundColor: ['rgb(254, 240, 138)', 'rgb(134, 239, 172)', 'rgb(252, 165, 165)'],
        },
      ],
    }),
    [report, t],
  );

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: function (context: any) {
            if (!context?.raw) return '';
            const value = context.raw;
            const probabilityType =
              context.datasetIndex === 0 || context.datasetIndex === 1
                ? 'lowProbability'
                : context.datasetIndex === 2 || context.datasetIndex === 3
                  ? 'mediumProbability'
                  : 'highProbability';
            const count = report?.[probabilityType]?.elementsCount;

            return [
              context.dataset.label,
              `${count} ${t('reporting.reportRisk.chartElements')}`,
              formatCurrency(value, { maxDigits: 0, currency: currentCurrency }),
            ];
          },
        },
      },
    },
    datalabels: {
      display: true,
      color: '#000',
      align: 'end',
      anchor: 'end',
      font: {
        size: 12,
      },
      formatter: (value: number) => value,
    },
    legend: {
      display: false,
    },
    scales: {
      y: {
        grid: {
          drawOnChartArea: true,
          drawBorder: false,
          color: (context: { tick: { value: number } }) => (context.tick.value === 0 ? '#a0a0a0' : '#FFFFFF'),
        },
        ticks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: function (value: any) {
            const number = typeof value === 'number' ? value : 0;
            return `${formatLargeNumber(number)} ${currentCurrencySymbol}`;
          },
        },
      },
      x: {
        stacked: true,
      },
    },
  };

  useEffect(() => {
    if (report) {
      const displayValueOnTopPlugin = {
        id: 'displayValueOnTop',
        afterDatasetsDraw(chart: ChartJS) {
          const {
            ctx,
            chartArea: { top },
          } = chart;

          chart.data.datasets.forEach((dataset: ChartDataset, datasetIndex: number) => {
            const meta = chart.getDatasetMeta(datasetIndex);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            meta.data.forEach((bar: any, index: number) => {
              const value = dataset.data?.[index];
              if (typeof value === 'number' && bar) {
                ctx.save();
                ctx.fillStyle = 'gray';
                ctx.font = 'bold 12px Arial';
                ctx.textAlign = 'center';

                const xPos = bar?.x ?? 0;
                const yPos = bar?.y ?? 0;
                const allOpportunitiesAreZero = chart.data.datasets[1]?.data.every(
                  (value) => typeof value === 'number' && value === 0,
                );
                if (dataset.label === t('reporting.reportRisk.chartLabelRisks') || !allOpportunitiesAreZero) {
                  ctx.fillText(
                    formatCurrency(value, { maxDigits: 0, currency: currentCurrency }),
                    xPos,
                    dataset.label === t('reporting.reportRisk.chartLabelOpportunities')
                      ? value === 0 || (bar?.base ?? 0) + 20 > yPos - 10
                        ? (bar?.base ?? 0) + 20
                        : yPos - 10
                      : yPos > top
                        ? Math.max(top + 20, yPos - 10)
                        : top + 35,
                  );
                  ctx.restore();
                }

                if (dataset.label === t('reporting.reportRisk.chartLabelRisks')) {
                  let count;
                  switch (index) {
                    case 0:
                      count = report?.lowProbability.elementsCount;
                      break;
                    case 1:
                      count = report?.mediumProbability.elementsCount;
                      break;
                    case 2:
                      count = report?.highProbability.elementsCount;
                      break;
                    default:
                      count = 0;
                  }
                  ctx.save();
                  ctx.fillStyle = 'gray';
                  ctx.font = 'bold 20px Arial';
                  ctx.textAlign = 'center';
                  ctx.fillText(count?.toString(), xPos, yPos > top ? Math.max(top + 20, yPos - 10) - 15 : yPos + 20);
                  ctx.restore();
                }
              }
            });
          });
        },
      };
      ChartJS.register(displayValueOnTopPlugin);
    }
  }, [currentCurrency, report, t]);

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('reporting.reportRiskTitle')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full p-6 bg-white">
        {isFetching && <LoadingIndicator mode="overlay" />}
        <div className={classNames('flex w-full gap-8 2xl:flex-row flex-col')}>
          <div className={classNames('flex flex-col 2xl:w-5/12 gap-5')}>
            <WidgetSummary
              title={t('reporting.reportRisk.riskSummaryTitle')}
              isLoading={isFetching}
              data={summaryData}
            />
            <WidgetMatrix
              title={t('reporting.reportRisk.riskMatrixTitle')}
              isLoading={isFetching}
              data={matrixData}
              rowLabels={[t('project.dashboard.widget.matrix.high'), t('project.dashboard.widget.matrix.medium'), t('project.dashboard.widget.matrix.low')]}
              colLabels={[t('project.dashboard.widget.matrix.low'), t('project.dashboard.widget.matrix.medium'), t('project.dashboard.widget.matrix.high')]}
              verticalTitle={t('reporting.reportRisk.riskMatrixVerticalTitle')}
              horizontalTitle={t('reporting.reportRisk.riskMatrixHorizontalTitle')}
            />

            <WidgetChart
              title={t('reporting.reportRisk.riskChartTitle')}
              isLoading={isFetching}
              chartData={chartData}
              chartOptions={chartOptions}
              className="min-h-[300px]"
            />
          </div>
          <div className="2xl:w-7/12">
            <WidgetContainer>
              {isFetching ? (
                <LoadingIndicator className="p-4" />
              ) : (
                <>
                  <WidgetContainerTitle>{t('reporting.reportRisk.tableTitle')}</WidgetContainerTitle>
                  <WidgetContainerContent>
                    <div className="grid text-[13px] font-semibold text-slate-600 pb-1 border-b grid-cols-10">
                      {tableTitles.map((tableTitle, index) => (
                        <div
                          className={classNames('col-span-1 p-2 break-words', {
                            'col-span-4': index === 1,
                            'border-r-2 border-slate-300': index !== tableTitles.length - 1,
                          })}
                          key={`widget-table-title-${index}`}
                        >
                          {tableTitle}
                        </div>
                      ))}
                    </div>

                    {tableDataRows.length > 0 ? (
                      tableDataRows.map((row, rowIndex) => (
                        <div
                          key={`widget-table-row-${rowIndex}`}
                          className="grid grid-cols-10 text-[13px] border-b border-dotted "
                        >
                          {row.cols.length > 0 &&
                            row.cols.map((col, colIndex) => (
                              <div
                                className={classNames('border-y-dotted col-span-1 relative p-2', {
                                  'col-span-4': colIndex === 1,
                                  'border-r-2': colIndex !== row.cols.length - 1,
                                })}
                                key={`widget-table-row-${rowIndex}-col-${colIndex}`}
                              >
                                {colIndex === 0 && (
                                  <div
                                    className={classNames('w-1.5 h-6 rounded -ml-4 left-0 absolute', {
                                      'bg-red-300':
                                        report?.top20RiskElements?.[rowIndex]?.riskScore?.toLowerCase() === 'red',
                                      'bg-yellow-300':
                                        report?.top20RiskElements?.[rowIndex]?.riskScore?.toLowerCase() === 'amber',
                                      'bg-green-300':
                                        report?.top20RiskElements?.[rowIndex]?.riskScore?.toLowerCase() === 'green',
                                    })}
                                  />
                                )}
                                {col}
                              </div>
                            ))}
                        </div>
                      ))
                    ) : (
                      <div className="mt-2 italic text-slate-500 text-xs">
                        {t('reporting.reportRisk.tableEmptyMessage')}
                      </div>
                    )}
                  </WidgetContainerContent>
                </>
              )}
            </WidgetContainer>
          </div>
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
