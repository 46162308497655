import classNames from 'classnames';
import React, { ReactNode, useEffect, useMemo, useState, useRef } from 'react';
import { createFileList, formatFileSize } from '@client/shared/utilities';
import { DocumentIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '../icons';
import { fileTypeFromBuffer } from 'file-type';
import toast from 'react-hot-toast';

interface FileInputProps {
  acceptedFileTypes: string[];
  multiple: boolean;
  className?: string;
  uploadDescription?: string | ReactNode;
  icon?: React.ReactNode;
  selectedFiles?: FileList | null;
  onChange: (fileList: FileList | null) => void;
  maxFileSize?: number;
  maxFilesCount?: number;
  setError?: (error: boolean) => void;
  disabled?: boolean;
}

export const FileInput = ({
  acceptedFileTypes,
  className,
  icon,
  multiple,
  uploadDescription,
  selectedFiles,
  onChange,
  setError,
  maxFileSize = 15000000,
  maxFilesCount,
  disabled = false,
}: FileInputProps) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>(selectedFiles ? Array.from(selectedFiles) : []);

  const [wrongFileTypeFileNames, setWrongFileTypeFileNames] = useState<string[]>([]);
  const [wrongFileSizeFileNames, setWrongFileSizeFileNames] = useState<string[]>([]);
  const [brokenFileNames, setBrokenFileNames] = useState<string[]>([]);

  const wrongFileNames = useMemo(() => {
    return [...wrongFileSizeFileNames, ...wrongFileTypeFileNames, ...brokenFileNames];
  }, [wrongFileSizeFileNames, wrongFileTypeFileNames, brokenFileNames]);

  useEffect(() => {
    setFiles(selectedFiles ? Array.from(selectedFiles) : []);
  }, [selectedFiles]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = async (newFiles: File[] | null) => {
    const wrongTypes: string[] = [];
    const wrongSizes: string[] = [];
    const brokenFiles: string[] = [];

    if (newFiles) {
      let allFilesList = [...newFiles];

      // filter out duplicates
      allFilesList = allFilesList.filter((value, index, self) =>
          index === self.findIndex((f) => (
            f.name === value.name
          ))
      );

      if (maxFilesCount && maxFilesCount < allFilesList.length) {
        const excess = allFilesList.length - maxFilesCount;
        allFilesList.splice(allFilesList.length - excess, excess);
        toast.error(t('app.fileUploadCountExceededMessage') + ' ' + maxFilesCount);
      }

      setFiles(allFilesList);

      // File validation
      if ((acceptedFileTypes.length && !acceptedFileTypes.includes('*')) || maxFileSize) {
        const allAcceptedFileTypes = acceptedFileTypes.join('');

        for (const file of allFilesList) {
          const buffer = await file.arrayBuffer();
          const fileType = await fileTypeFromBuffer(buffer);
          const mimeType = fileType?.mime ?? file.type;
          const fileEnding = fileType?.ext ?? file.name.split('.').pop();

          if (fileEnding === 'json') {
            const data = await file.text();
            try {
              // try parsing json
              JSON.parse(data);
            } catch (e) {
              brokenFiles.push(file.name);
            }
          } else if (fileType?.ext.toLowerCase() !== file.name.toLowerCase().split('.').pop()) {
            //The following file types will not be accepted:
            // MS-CFB: Microsoft Compound File Binary File Format based formats, too old and difficult to parse:
            // .doc - Microsoft Word 97-2003 Document
            // .xls - Microsoft Excel 97-2003 Document
            // .ppt - Microsoft PowerPoint97-2003 Document
            // .msi - Microsoft Windows Installer
            // .csv - Reason.
            // .svg - Detecting it requires a full-blown parser. Check out is-svg for something that mostly works.
            const noValidationPossible = ['doc', 'xls', 'ppt', 'msi', 'csv', 'svg', 'xml'];
            if (fileEnding && !noValidationPossible.includes(fileEnding)) {
              brokenFiles.push(file.name);
            }
          }

          // Validation of accepted file types if defined
          if (acceptedFileTypes.length && fileEnding) {
            if (!allAcceptedFileTypes.includes(fileEnding)) {
              if (
                !acceptedFileTypes.includes('image/*') ||
                (acceptedFileTypes.includes('image/*') && !mimeType.includes('image'))
              ) {
                wrongTypes.push(file.name);
              }
            }
          }

          // Validation of size if maxSize defined
          if (maxFileSize && file.size >= maxFileSize) {
            wrongSizes.push(file.name);
          }
        }
        if (setError) {
          if (wrongTypes.length || wrongSizes.length || brokenFiles.length) {
            setError(true);
          } else {
            setError(false);
          }
        }

        setWrongFileTypeFileNames(wrongTypes);
        setWrongFileSizeFileNames(wrongSizes);
        setBrokenFileNames(brokenFiles);
      }

      if (onChange && !wrongTypes.length && !wrongSizes.length && !brokenFiles.length) {
        onChange(createFileList(...allFilesList));
      }
    }

    //event.target.value = "";
  };

  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    handleChange(newFiles);
  };

  const handleFilesContainerDivClick = () => {
    fileInputRef.current?.click();
  };

  const loadPreview = (file: File) => {
    return URL.createObjectURL(file);
  };

  return (
    <div className={classNames('flex flex-col gap-2', className)}>
      <div
        className={classNames(
          'relative flex flex-col text-gray-400 rounded cursor-pointer hover:bg-gray-100 py-8 transition-colors duration-300',
          className,
        )}
        onDragOver={(event) => {
          event.preventDefault();
        }}
        onDrop={(event) => {
          event.preventDefault();
          const newFiles = event.dataTransfer.files;
          const allFilesList = [...files].concat(newFiles ? Array.from(newFiles) : []);
          handleChange(allFilesList);
        }}

      >
        <input
          disabled = {disabled}
          className={`absolute inset-0 w-full h-full p-0 m-0 outline-none opacity-0 ${
            disabled ? "cursor-not-allowed" : " cursor-pointer"
          }`}
          multiple={multiple}
          accept={acceptedFileTypes.join(',')}
          ref={fileInputRef}
          type="file"
          onChange={(event) => {
            const { files: newFiles } = event.target as HTMLInputElement;
            const allFilesList = [...files].concat(newFiles ? Array.from(newFiles) : []);
            handleChange(allFilesList);
          }}
        />

        <div className="flex flex-col items-center justify-center text-center text-medium h-full">
          {icon ? icon : <DocumentIcon className="w-10 h-10" />}
          <p className="mt-6">{uploadDescription ? uploadDescription : t('app.fileUploadMessage')}</p>
        </div>

        {files.length > 0 && (
          <div
            className="absolute flex flex-row flex-wrap inset-0 overflow-x-auto p-4 gap-2 z-40"
            onClick={handleFilesContainerDivClick}
          >
            {files.map((file, index) => (
              <div className="relative w-24" key={`upload-file-${index}`}>
                <div
                  key={index}
                  className="flex flex-col flex-shrink-0 items-center overflow-hidden text-center bg-gray-100 border rounded select-none"
                >
                  <div
                    className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeFile(index);
                    }}
                  >
                    <TrashIcon className="w-4 h-4 text-gray-700" />
                  </div>
                  <div className="flex items-center justify-center w-20 h-20">
                    {file.type.includes('image/') ? (
                      <img className="inline-block" src={loadPreview(file)} alt="Preview" />
                    ) : (
                      <DocumentIcon className="w-6 h-6 text-gray-400" />
                    )}
                  </div>
                  <div className="w-full flex flex-col p-2 text-xs bg-white bg-opacity-50">
                    <span className="w-full font-bold text-gray-900 truncate">{file.name}</span>
                    <span className="text-xs text-gray-900">{formatFileSize(file.size, 1000)}</span>
                  </div>
                </div>
                {wrongFileNames.includes(file.name) && (
                  <div className="text-xs bg-red-500 text-white p-1.5 flex flex-col items-center justify-center text-center">
                    {wrongFileTypeFileNames.includes(file.name) && <span>{t('fileUpload.error.wrongFileType')}</span>}
                    {wrongFileSizeFileNames.includes(file.name) && <span>{t('fileUpload.error.tooLarge')}</span>}
                    {brokenFileNames.includes(file.name) && <span>{t('fileUpload.error.brokenFile')}</span>}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {files.length > 0 && wrongFileNames.length > 0 && (
        <div className="px-2 flex gap-1.5 text-xs text-red-500 items-center">
          <ExclamationTriangleIcon className="w-5 flex-none" />
          <div>
            {wrongFileTypeFileNames.length > 0 && (
              <div className="flex gap-1.5">
                {t('fileUpload.hint.fileType')} {acceptedFileTypes.join(', ')}
              </div>
            )}
            {wrongFileSizeFileNames.length > 0 && maxFileSize && (
              <div className="flex gap-1.5">
                {t('fileUpload.hint.maximumFileSize')} {formatFileSize(maxFileSize, 1000)}
              </div>
            )}
            {brokenFileNames.length > 0 && (
              <div className="flex gap-1.5">{t('fileUpload.hint.brokenFilesOrUndefinedFileType')}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
