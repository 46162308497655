import cn from 'classnames';
import { SlideOverSortableListItemData } from './SlideOverSortableList';
import React, { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';

export interface SlideOverSortableListItemProps {
  className?: string;
  item: SlideOverSortableListItemData;
  gridCols: string;
  level?: number;
  earlyCompact?: boolean;
  defaultOpen?: boolean;
  onClick?: (index: number, id: string, level: number) => void;
  index?: number;
}

export const SlideOverSortableListItem = ({
  item,
  gridCols,
  className,
  level = 0,
  earlyCompact = false,
  defaultOpen = true,
  onClick,
  index = 0,
}: SlideOverSortableListItemProps) => {
  return (
    <Disclosure as="div" defaultOpen={defaultOpen} className="w-full flex flex-col">
      {({ open }) => (
        <>
          <div
            className={cn(
              'w-full flex flex-col items-center cursor-pointer px-6 py-3 gap-3 truncate relative hover:bg-slate-50 transition-colors duration-300',
              {
                'md:flex-row md:gap-0': !earlyCompact,
                'lg:flex-row lg:gap-0': earlyCompact,
              },
              className,
            )}
            onClick={
              onClick
                ? (e) => {
                    const target = e.target as HTMLElement;
                    if (!target.matches('.disclosure-button')) {
                      onClick(index, item.id, level);
                    }
                  }
                : undefined
            }
          >
            <div
              className={cn('w-full flex gap-3 whitespace-nowrap', {
                'md:w-1/3 md:flex-none md:pr-3 md:gap-6': !earlyCompact,
                'lg:w-1/3 lg:flex-none lg:pr-3 lg:gap-6': earlyCompact,
                // 'pl-[55px]': level === 1, not used, yet
              })}
            >
              <div
                className={cn('hidden w-8 justify-center items-center flex-none', {
                  'md:flex': !earlyCompact,
                  'lg:flex': earlyCompact,
                })}
              >
                {(item.childItems && item.childItems.length > 0) || item.isCollapsible ? (
                  <DisclosureButton as="div" className="disclosure-button">
                    <ChevronDownIcon
                      className={cn(
                        'w-5 transform transition-transform duration-300 font-bold disclosure-button pointer-events-none',
                        open ? '-rotate-180' : '',
                      )}
                    />
                  </DisclosureButton>
                ) : (
                  item.icon
                )}
              </div>
              <div
                className={cn('w-full flex flex-col gap-1 justify-center pr-10 overflow-hidden', {
                  'md:pr-0': !earlyCompact,
                  'lg:pr-0': earlyCompact,
                })}
              >
                <div
                  className={cn('leading-tight font-bold truncate', {
                    'text-lg': level === 0,
                    'text-[15px]': level > 0,
                  })}
                  data-level={level}
                >
                  {item.name}
                </div>
                {item.description && (
                  <div className="text-[11px] leading-tight text-gray-400 truncate">{item.description}</div>
                )}
              </div>
            </div>
            <div
              className={cn(
                'w-full grid gap-3 whitespace-nowrap',
                {
                  'md:flex-1 md:justify-between md:pl-3': !earlyCompact,
                  'lg:flex-1 lg:justify-between lg:pl-3': earlyCompact,
                },
                gridCols,
              )}
            >
              {item.cols?.map((col, i) => (
                <Fragment key={`list-item-${i}-${col.header}`}>
                  <div
                    className={cn(
                      'flex flex-col gap-0.5 justify-center leading-tight text-[13px] text-gray-400 whitespace-break-spaces',
                      {
                        'md:hidden': !earlyCompact,
                        'lg:hidden': earlyCompact,
                      },
                    )}
                  >
                    {col.header}
                  </div>
                  <div className="flex flex-col justify-center gap-0.5 text-[15px] leading-none text-right">
                    <span className="truncate">{col.value}</span>
                    <span className="text-[10px] text-gray-400 font-light">{col.title}</span>
                  </div>
                </Fragment>
              ))}
            </div>
            {item.contextMenu && (
              <div
                className={cn(
                  'rotate-90 transform absolute -right-1 top-2 w-10 flex-none whitespace-nowrap flex items-center justify-end',
                  {
                    'md:rotate-0 md:transform-none md:right-auto md:top-auto md:relative': !earlyCompact,
                    'lg:rotate-0 lg:transform-none lg:right-auto lg:top-auto lg:relative': earlyCompact,
                  },
                )}
              >
                {item.contextMenu}
              </div>
            )}
          </div>
          {item.childItems && item.childItems.length > 0 && (
            <DisclosurePanel>
              {item.childItems.map((childItem, childIndex) => (
                <SlideOverSortableListItem
                  key={`list-item-${level}-child-item-${childIndex}`}
                  item={childItem}
                  gridCols={gridCols}
                  level={level + 1}
                  earlyCompact={earlyCompact}
                  onClick={onClick}
                  index={childIndex}
                />
              ))}
            </DisclosurePanel>
          )}

          {item.children && (
            <DisclosurePanel>
              <div className="w-full border-b">{item.children}</div>
            </DisclosurePanel>
          )}
        </>
      )}
    </Disclosure>
  );
};
