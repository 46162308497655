import {
  UpsertBudgetStatusWidgetPayload,
  UpsertCashOutWidgetPayload,
  UpsertKpisWidgetPayload,
  UpsertMapViewWidgetPayload,
  UpsertProjectClockWidgetPayload,
  UpsertProjectInformationWidgetPayload,
  UpsertProjectUdfsWidgetPayload,
  UpsertRiskMitigationListWidgetPayload,
  UpsertTasksWidgetPayload,
  UpsertTextboxWidgetPayload,
  UpsertTrafficLightsWidgetPayload,
  UpsertWaterfallWidgetPayload,
} from '@client/shared/api';
import { Widget, WidgetConfig } from '../WidgetDashboard';
import { DashboardConfigKeys, DashboardWidgetType, WidgetSizeType } from './widgetDashboardConfig';
import {
  WidgetDashboardEditFormBudgetStatusValidationValues,
  WidgetDashboardEditFormKpiValidationValues,
  WidgetDashboardEditFormMapViewValidationValues,
  WidgetDashboardEditFormProjectClockValidationValues,
  WidgetDashboardEditFormProjectInformationValidationValues,
  WidgetDashboardEditFormRiskMitigationListValidationValues,
  WidgetDashboardEditFormTasksValidationValues,
  WidgetDashboardEditFormTextBoxValidationValues,
  WidgetDashboardEditFormTrafficLightsValidationValues,
} from '../WidgetEditForms';
import { WidgetDashboardEditFormValues } from '../WidgetDashboardEdit';

const generateWidgetPayload = (widget: Widget) => {
  return {
    name: widget.widget.title,
    startingColumn: widget.layout.x,
    startingRow: widget.layout.y,
    amountOfColumns: widget.layout.w,
    amountOfRows: widget.layout.h,
    // TODO this needs improvement
    ...(widget.widget.additionalConfig?.BudgetStatus?.costCatalogElements !== undefined && { costCatalogElements: widget.widget.additionalConfig.BudgetStatus.costCatalogElements }), // Budget status widget
    ...(widget.widget.additionalConfig?.BudgetStatus?.columns !== undefined && { columns: widget.widget.additionalConfig.BudgetStatus.columns }), // KPIs widget
    ...(widget.widget.additionalConfig?.KPIs !== undefined && { kpis: widget.widget.additionalConfig.KPIs }), // KPIs widget
    ...(widget.widget.additionalConfig?.MapView?.lat !== undefined && {
      lat: widget.widget.additionalConfig.MapView.lat,
    }), // MapView widget
    ...(widget.widget.additionalConfig?.MapView?.lng !== undefined && {
      lng: widget.widget.additionalConfig.MapView.lng,
    }), // MapView widget
    ...(widget.widget.additionalConfig?.ProjectInformation?.projectManagerId !== undefined && {
      projectManagerId: widget.widget.additionalConfig.ProjectInformation.projectManagerId,
    }), // ProjectInformation widget
    ...(widget.widget.additionalConfig?.ProjectInformation?.clientId !== undefined && {
      clientId: widget.widget.additionalConfig.ProjectInformation.clientId,
    }), // ProjectInformation widget
    ...(widget.widget.additionalConfig?.RiskMitigationList !== undefined && {
      riskMitigations: widget.widget.additionalConfig.RiskMitigationList,
    }), // RiskMitigationList widget
    ...(widget.widget.additionalConfig?.Tasks !== undefined && { tasks: widget.widget.additionalConfig?.Tasks }), // Tasks widget
    ...(widget.widget.additionalConfig?.TextBox?.text && { text: widget.widget.additionalConfig?.TextBox?.text }), // Text widget
    ...(widget.widget.additionalConfig?.TrafficLight !== undefined && {
      trafficLights: widget.widget.additionalConfig.TrafficLight,
    }), // Traffic Lights widget
    // ...(widget.widget.additionalConfig?.ProjectClock !== undefined && { datasets: widget.widget.additionalConfig.ProjectClock}) // Project Clock widget
    ...(widget.widget.additionalConfig?.ProjectClock !== undefined && { datasets: widget.widget.additionalConfig.ProjectClock }), // Project Clock widget
    ...(widget.widget.additionalConfig?.Waterfall !== undefined && {}) // Waterfall widget
  };
};

export const prepareWidgetsToSave = (
  currentLayout: Widget[],
  widgetToSave?: Widget,
  type: DashboardConfigKeys = 'default',
) => {
  const budgetStatusWidgets: UpsertBudgetStatusWidgetPayload[] = [];
  const cashOutWidgets: UpsertCashOutWidgetPayload[] = [];
  const kpisWidgets: UpsertKpisWidgetPayload[] = [];
  const mapViewWidgets: UpsertMapViewWidgetPayload[] = [];
  const projectInformationWidgets: UpsertProjectInformationWidgetPayload[] = [];
  const projectUdfsWidgets: UpsertProjectUdfsWidgetPayload[] = [];
  const riskMitigationListWidgets: UpsertRiskMitigationListWidgetPayload[] = [];
  const tasksWidgets: UpsertTasksWidgetPayload[] = [];
  const textboxWidgets: UpsertTextboxWidgetPayload[] = [];
  const trafficLightsWidgets: UpsertTrafficLightsWidgetPayload[] = [];
  const projectClockWidgets: UpsertProjectClockWidgetPayload[] = [];
  const waterfallWidgets: UpsertWaterfallWidgetPayload[] = [];

  const allWidgets = !widgetToSave || widgetToSave.widget.id ? currentLayout : [...currentLayout, widgetToSave];

  allWidgets.forEach((layoutItem) => {
    if (!layoutItem.layout) return;

    const widgetPayload =
      widgetToSave && layoutItem.widget.id === widgetToSave.widget.id
        ? generateWidgetPayload(widgetToSave)
        : generateWidgetPayload(layoutItem);

    switch (layoutItem.widget.type) {
      case DashboardWidgetType.BudgetStatus:
        budgetStatusWidgets.push(widgetPayload as UpsertBudgetStatusWidgetPayload);
        break;
      case DashboardWidgetType.CashOutPlan:
        cashOutWidgets.push(widgetPayload as UpsertCashOutWidgetPayload);
        break;
      case DashboardWidgetType.KPIs:
        kpisWidgets.push(widgetPayload as UpsertKpisWidgetPayload);
        break;
      case DashboardWidgetType.MapView:
        mapViewWidgets.push(widgetPayload as UpsertMapViewWidgetPayload);
        break;
      case DashboardWidgetType.ProjectInformation:
        projectInformationWidgets.push(widgetPayload as UpsertProjectInformationWidgetPayload);
        break;
      case DashboardWidgetType.ProjectUdfs:
        projectUdfsWidgets.push(widgetPayload as UpsertProjectUdfsWidgetPayload);
        break;
      case DashboardWidgetType.RiskMitigationList:
        riskMitigationListWidgets.push(widgetPayload as UpsertRiskMitigationListWidgetPayload);
        break;
      case DashboardWidgetType.Tasks:
        tasksWidgets.push(widgetPayload as UpsertTasksWidgetPayload);
        break;
      case DashboardWidgetType.TextBox:
        textboxWidgets.push(widgetPayload as UpsertTextboxWidgetPayload);
        break;
      case DashboardWidgetType.TrafficLight:
        trafficLightsWidgets.push(widgetPayload as UpsertTrafficLightsWidgetPayload);
        break;
      case DashboardWidgetType.ProjectClock:
        projectClockWidgets.push(widgetPayload as UpsertProjectClockWidgetPayload);
        break;
      case DashboardWidgetType.Waterfall:
        waterfallWidgets.push(widgetPayload as UpsertWaterfallWidgetPayload);
        break;
      default:
        break;
    }
  });

  if (type === 'report') {
    return {
      budgetStatusWidgets,
      cashOutWidgets,
      kpisWidgets,
      projectInformationWidgets,
      riskMitigationListWidgets,
      tasksWidgets,
      textboxWidgets,
      trafficLightsWidgets,
      projectClockWidgets,
      waterfallWidgets
    };
  }
  return { cashOutWidgets, kpisWidgets, mapViewWidgets, projectUdfsWidgets };
};

export const prepareWidgetToSave = (
  data: WidgetDashboardEditFormValues,
  widget: Widget,
  size?: WidgetSizeType,
): Widget => {
  return {
    layout: {
      ...widget.layout,
      x: widget?.layout.x ?? 0,
      y: widget?.layout.y ?? 0,
      w: size ? size.w : 1,
      h: size ? size.h : 1,
    },
    widget: getUpdatedWidgetForType(data, widget),
  };
};

export const getUpdatedWidgetForType = (data: WidgetDashboardEditFormValues, widget: Widget): WidgetConfig => {
  switch (widget.widget.type as DashboardWidgetType) {
    case DashboardWidgetType.BudgetStatus: {
      const mappedData = data as WidgetDashboardEditFormBudgetStatusValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.BudgetStatus]: {
            costCatalogElements: mappedData.costCatalogElements ?? [],
            columns: mappedData.columns ?? []
          }
        },
      };
    }
    case DashboardWidgetType.KPIs: {
      const mappedData = data as WidgetDashboardEditFormKpiValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.KPIs]: mappedData.kpis ?? [],
        },
      };
    }
    case DashboardWidgetType.MapView: {
      const mappedData = data as WidgetDashboardEditFormMapViewValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.MapView]: {
            lat: mappedData.lat ?? undefined,
            lng: mappedData.lng ?? undefined,
          },
        },
      };
    }
    case DashboardWidgetType.ProjectInformation: {
      const mappedData = data as WidgetDashboardEditFormProjectInformationValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.ProjectInformation]: {
            projectManagerId: mappedData.projectManagerId,
            clientId: mappedData.clientId,
          },
        },
      };
    }
    case DashboardWidgetType.RiskMitigationList: {
      const mappedData = data as WidgetDashboardEditFormRiskMitigationListValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.RiskMitigationList]: mappedData.riskMitigations ?? [],
        },
      };
    }
    case DashboardWidgetType.Tasks: {
      const mappedData = data as WidgetDashboardEditFormTasksValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.Tasks]: mappedData.tasks ?? [],
        },
      };
    }
    case DashboardWidgetType.TextBox: {
      const mappedData = data as WidgetDashboardEditFormTextBoxValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.TextBox]: {
            text: mappedData.text ?? '',
          },
        },
      };
    }
    case DashboardWidgetType.TrafficLight: {
      const mappedData = data as WidgetDashboardEditFormTrafficLightsValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.TrafficLight]: mappedData.trafficLights ?? [],
        },
      };
    }
    case DashboardWidgetType.ProjectClock: {
      const mappedData = data as WidgetDashboardEditFormProjectClockValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        additionalConfig: {
          [DashboardWidgetType.ProjectClock]: mappedData.datasets ?? []
        }
      };
    }
    case DashboardWidgetType.Waterfall: {
      // const mappedData = data as WidgetDashboardEditFormWaterfallValidationValues;
      return  {
        ...widget.widget,
        title: data.title
      };
    }
    default:
      return {
        ...widget.widget,
        title: data.title,
      };
  }
};
