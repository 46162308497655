import classNames from 'classnames';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '.';
import { LoadingIndicator } from '..';
import { Fragment } from 'react';

export interface MatrixCellData {
  topValue?: string | number;
  bottomValue: string | number;
  color?: string;
}

interface WidgetMatrixProps {
  title?: string;
  isLoading?: boolean;
  data: MatrixCellData[][];
  rowLabels?: string[];
  colLabels?: string[];
  verticalTitle?: string;
  horizontalTitle?: string;
}

export const WidgetMatrix = ({
  title,
  isLoading = false,
  data,
  rowLabels = [],
  colLabels = [],
  verticalTitle,
  horizontalTitle,
}: WidgetMatrixProps) => {
  const rowCount = data.length;
  const colCount = rowCount > 0 ? data[0].length : 0;
  const totalRows = (horizontalTitle ? 1 : 0) + (colLabels.length > 0 ? 1 : 0) + rowCount;
  const totalCols = (verticalTitle ? 1 : 0) + (rowLabels.length > 0 ? 1 : 0) + colCount;

  return (
    <WidgetContainer>
      {isLoading ? (
        <LoadingIndicator className="p-4" />
      ) : (
        <>
          {title && <WidgetContainerTitle>{title}</WidgetContainerTitle>}

          <WidgetContainerContent>
            <div className="px-5 h-full overflow-auto">
              <div
                className="grid gap-2 w-full"
                style={{
                  gridTemplateRows: `repeat(${totalRows}, auto)`,
                  gridTemplateColumns: `repeat(${totalCols}, minmax(0, 1fr))`,
                }}
              >
                {horizontalTitle && (
                  <>
                    {verticalTitle && <div />}
                    {rowLabels.length > 0 && <div />}

                    <div
                      style={{
                        gridColumn: `span ${colCount}`,
                      }}
                      className="text-center font-bold text-slate-600"
                    >
                      {horizontalTitle}
                    </div>
                  </>
                )}

                {colLabels.length > 0 && (
                  <>
                    {verticalTitle && <div />}
                    {rowLabels.length > 0 && <div />}

                    {colLabels.map((label, idx) => (
                      <div key={idx} className="text-center font-medium p-2">
                        {label}
                      </div>
                    ))}
                  </>
                )}

                {data.map((row, rowIdx) => {
                  const isFirstDataRow = rowIdx === 0 && verticalTitle;

                  return (
                    <Fragment key={rowIdx}>
                      {verticalTitle && isFirstDataRow && (
                        <div
                          style={{
                            gridRow: `span ${rowCount}`,
                            writingMode: 'vertical-lr',
                            transform: 'rotate(180deg)',
                          }}
                          className="font-bold text-slate-600 flex justify-center items-center"
                        >
                          {verticalTitle}
                        </div>
                      )}

                      {rowLabels.length > 0 && (
                        <div
                          className="flex justify-center items-center text-center font-medium p-2"
                          style={{ minHeight: '2.5rem' }}
                        >
                          {rowLabels[rowIdx]}
                        </div>
                      )}

                      {row.map((cell, colIdx) => (
                        <div
                          key={colIdx}
                          className={classNames(
                            'flex flex-col items-center justify-center rounded py-2 px-4',
                            cell.color ? cell.color : 'bg-gray-200',
                          )}
                        >
                          {(cell.topValue || cell.topValue === 0) && (
                            <span className="font-bold text-lg">{cell.topValue}</span>
                          )}
                          <span className="text-xs">{cell.bottomValue}</span>
                        </div>
                      ))}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
