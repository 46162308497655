import { 
  Button, 
  FileInput, 
  LoadingIndicator, 
  SlideOver, 
  SlideOverOnCloseProps 
} from '@client/shared/toolkit';
import { 
  GaebItemDataRecord, 
  useApiPostImportGaebDataMutation, 
  useApiPostParseGaebFileMutation 
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { GaebImportRecord } from './GaebImportRecord';

interface ImportGaebFileSlideOverProps extends SlideOverOnCloseProps{
  contractId : string
}

export const ImportGaebFileSlideOver = ({ onClose, contractId }: ImportGaebFileSlideOverProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | undefined>();
  const [fileError, setFileError] = useState(false);
  const [records, setRecords] = useState<GaebItemDataRecord[]>([]);

  const [post, { isLoading: isLoadingFile }] = useApiPostParseGaebFileMutation();
  const [callImport, {isLoading: isLoadingImport}] = useApiPostImportGaebDataMutation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const handleFileChange = (fileList: FileList | null) => {
    setRecords([]);
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    } else {
      setFile(undefined);
    }
  };

  useEffect(() => {
    const uploadFile = async () => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append('file', file);

          const result = await safeMutation(
            post,
            {
              projectId: loadedProjectId ?? '',
              calculationModelId: loadedCalculationModelId ?? '',
              contractId: contractId,
              body: formData as unknown as { file: Blob },
            },
            isLoadingFile,
          );

          setRecords(result?.gaebItems ?? []);
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (!fileError) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleDataImport = () => {

      try {
        callImport({
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedCalculationModelId ?? '',
          contractId: contractId,
          body: {
            data : records ?? [] 
          }
        }).unwrap()
        
        onClose(true);
      } catch {
        /* left blank */
      }
  };

  return (
    <>
      {(isLoadingFile || isLoadingImport) && (
        <LoadingIndicator text={t('projectContract.fetchingGaebFileDataLoadingIndicator') ?? ''} mode="overlay" />
      )}
      <SlideOver.Header
        title={t('projectContract.gaebImport')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />

      <SlideOver.Content className="p-8 h-full">
        <div className="m-0">
          <div className="divide-gray-100 divide-y">
            <div className="bg-white p-2">
              <FileInput
                acceptedFileTypes={['.xml']}
                multiple={false}
                onChange={handleFileChange}
                setError={setFileError}
              />
            </div>
            <div className="mt-2 w-full flex bg-white shadow rounded-tl rounded-bl">
              <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none bg-blue-600')} />
              <div className="flex w-full flex-col text-primary">
                {records.map((item, i) => (
                  <div className="ml-5" key={`gaeb-item-${item.code}-${i}`}>
                    <GaebImportRecord element={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" disabled={!records?.length} onClick={() => handleDataImport()}>
            {t('common.import')}
          </Button>
        </div>
      </SlideOver.Controls>
    </>
  );
};
