import {
  ApiDeleteContractTitleApiArg,
  ApiDeleteContractTitleApiResponse,
  ApiDeleteContractTitleDocumentApiArg,
  ApiDeleteContractTitleDocumentApiResponse,
  ApiGetContractTitleDocumentsApiArg,
  ApiGetContractTitleDocumentsApiResponse,
  ApiPostCreateContractTitleApiArg,
  ApiPostCreateContractTitleApiResponse,
  ApiPostCreateContractTitleDocumentApiArg,
  ApiPostCreateContractTitleDocumentApiResponse,
  ApiPostCreateContractTitleEmbedLinkDocumentApiArg,
  ApiPostCreateContractTitleEmbedLinkDocumentApiResponse,
  ApiPostCreateSubContractTitleApiArg,
  ApiPostCreateSubContractTitleApiResponse,
  ApiPostImportGaebDataApiArg,
  ApiPostImportGaebDataApiResponse,
  ApiPostUpdateContractTitleApiArg,
  ApiPostUpdateContractTitleApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiContractTitleEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetContractTitleDocuments: {
    providesTags: (
      _result: ApiGetContractTitleDocumentsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetContractTitleDocumentsApiArg,
    ) => [{ type: ApiTagTypes.ContractTitleDocuments, id: args.contractTitleId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateContractTitle: {
    invalidatesTags: (
      _result: ApiPostCreateContractTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateContractTitleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateContractTitle: {
    invalidatesTags: (
      _result: ApiPostUpdateContractTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateContractTitleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
            { type: ApiTagTypes.UserDefinedFields, id: args.contractTitleId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeleteContractTitle: {
    invalidatesTags: (
      _result: ApiDeleteContractTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteContractTitleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateContractTitleDocument: {
    invalidatesTags: (
      _result: ApiPostCreateContractTitleDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateContractTitleDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ContractTitleDocuments, id: args.contractTitleId }] : []),
  },
  apiPostCreateContractTitleEmbedLinkDocument: {
    invalidatesTags: (
      _result: ApiPostCreateContractTitleEmbedLinkDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateContractTitleEmbedLinkDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ContractTitleDocuments, id: args.contractTitleId }] : []),
  },
  apiDeleteContractTitleDocument: {
    invalidatesTags: (
      _result: ApiDeleteContractTitleDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteContractTitleDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ContractTitleDocuments, id: args.contractTitleId }] : []),
  },
  apiPostImportGaebData: {
    invalidatesTags: (
      _result: ApiPostImportGaebDataApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostImportGaebDataApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateSubContractTitle: {
    invalidatesTags: (
      _result: ApiPostCreateSubContractTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateSubContractTitleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId },
          ]
        : [],
  },  
};
