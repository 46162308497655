import {
  ComboSelect,
  ComboSelectOption,
  ContractIcon,
  DocumentPositionBoundingBoxReadModel,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  Form,
  FormField,
  FormWatch,
  FormRefHandle,
  InvoiceDocumentPositionReadModel,
  ComboSelectAdditionalOption,
  Modal,
  GuideDialog,
  Button,
  PaidBillDottedIcon,
  SlideOverTitle,
  GuideDialogControls,
  WorkflowIcon,
  BaseSelectOption,
} from '@client/shared/toolkit';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiEvalResultReadModel,
  AiEvalValuesReadModel,
  InvoiceState,
  InvoiceType,
  ProbisErrorDataType,
  ShortContractReadModel,
  useApiGetContractQuery,
  useApiGetContractsQuery,
  useApiGetProjectSelectCompanyBranchesQuery,
  useApiGetWorkflowsQuery,
  useApiPostCreateInvoiceByContractMutation,
  useApiPostCreateInvoiceMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiPostUpdateAiEvalDocumentInvoiceMetadataMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import {
  formatDateOnly,
  //  formatDate,
  safeMutation,
} from '@client/shared/utilities';
import { addDays } from 'date-fns';
import {
  InvoiceCreateFormValidationSchema,
  InvoiceCreateFormValidationValues,
} from '../InvoiceCreateFormValidationValues';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cn from 'classnames';
import { findBranchByIbanOrName, InvoiceDocumentReviewContractorAndClient } from './InvoiceDocumentReviewContractorAndClient';
import { InvoiceDocumentReviewValues } from './InvoiceDocumentReviewValues';
import { ContractNewWizard } from '../../Contract';
import { InvoiceDataEdit } from '../InvoiceTab';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { InvoiceEditContext } from '..';
import { useForm } from 'react-hook-form';

interface InvoiceDocumentFileDataProps {
  invoiceDocument?: AiEvalResultReadModel;
  fileData?: AiEvalValuesReadModel | null;
  allBoundingBoxes?: {
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null;
  hoveredBox: number | null;
  showAllBoxes: boolean;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  setShowAllBoxes: () => void;
  setHoveredBox: (index: number | null) => void;
  setIsFormValid?: (isValid: boolean) => void;
  onClose: () => void;
  setIsLoading?: (isLoading: boolean) => void;
}

export type InvoiceDocumentFileDataRef = {
  createInvoice: () => Promise<boolean | undefined>;
  saveInvoice: () => Promise<boolean | undefined>;
};

export const InvoiceDocumentFileData = forwardRef<InvoiceDocumentFileDataRef, InvoiceDocumentFileDataProps>(
  (props, ref) => {
    const { invoiceDocument, fileData, hoveredBox, boxes, setHoveredBox, setIsFormValid, onClose, setIsLoading } =
      props;

    const { t } = useTranslation();

    const {
      guideDialogData,
      setGuideDialogData,
      slideOverOpen,
      setUnsavedData,
      updateUnsavedData,
      setIsSaving,
      setIsUpdated,
    } = useContext(InvoiceEditContext);

    const loadedProjectId = useLoadedProjectId();
    const loadedVariantId = useLoadedVariantId();
    const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');
    
    const [contractElement, setContractElement] = useState<HTMLElement | null>(null);

    const [createInvoice, { isLoading: isCreating }] = useApiPostCreateInvoiceMutation();
    const [createInvoiceByContract, { isLoading: isCreatingByContract }] = useApiPostCreateInvoiceByContractMutation();
    const [updateInvoiceDocument, { isLoading: isUpdating }] = useApiPostUpdateAiEvalDocumentInvoiceMetadataMutation();
    const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);
    const [getNextCode, { isLoading: isGettingCode }] = useApiPostGenerateNextProjectObjectCodeMutation();

    const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetContractsQuery(
      {
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
      },
      {
        skip: !loadedProjectId || !loadedVariantId,
      },
    );

    const formRef = useRef<FormRefHandle<InvoiceCreateFormValidationValues>>(null);

    const [contractOptions, setContractOptions] = useState<ComboSelectOption[]>([]);
    const [codeError, setCodeError] = useState(false);
    const [loadedContracts, setLoadedContracts] = useState<ShortContractReadModel[]>([]);
    const [selectedContract, setSelectedContract] = useState<ShortContractReadModel | null>(null);
    const [allowChangeMode, setAllowChangeMode] = useState(true);
    const [isCreateContractWizardOpen, setIsCreateContractWizardOpen] = useState(false);

    const { data: fetchedContract } = useApiGetContractQuery(
      {
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
        contractId: selectedContract?.id ?? '',
      },
      {
        skip: !loadedProjectId || !loadedVariantId || !selectedContract?.id,
      },
    );

    useEffect(() => {
      if (!(invoiceDocument?.document?.uploadedByContract?.id || invoiceDocument?.result?.contractId) && selectedContract && fetchedContract && fetchedContract.workflowDefinitionId && formRef.current) {
        formRef.current?.setValue('workflow', fetchedContract.workflowDefinitionId);
      }
    }, [selectedContract, fetchedContract, invoiceDocument]);

    const { data: workflows } = useApiGetWorkflowsQuery();

    const workflowOptions = useMemo(() => {
      return workflows?.workflows
        .filter((workflow) => workflow.workflowType === 'Invoice')
        .map((workflow) => ({
          label: workflow.name,
          value: workflow.definitionId,
        })) as BaseSelectOption[];
    }, [workflows]);

    useEffect(() => {
      const getNextInvoiceCode = async () => {
        if (loadedProjectId && loadedVariantId && !formRef.current?.getValues().code) {
          const isGettingNextCode = false;
          const nextCodeResponse = await safeMutation(
            getNextCode,
            {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: { projectObjectType: 'Invoice' },
            },
            isGettingNextCode,
          );
          if (typeof nextCodeResponse !== 'undefined') {
            formRef.current?.setValue('code', nextCodeResponse.code);
          }
        }
      };

      if (formRef.current && !formRef.current.getValues().code && !isGettingCode && invoiceDocument) {
        getNextInvoiceCode();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDocument]);

    const { data: branches } = useApiGetProjectSelectCompanyBranchesQuery(
      {
        projectId: loadedProjectId ?? '',
      },
      {
        skip: !loadedProjectId,
      },
    );

    const defaultFormValues = useMemo(() => {
      let dateOfReceipt = '';
      if (invoiceDocument?.result?.invoiceDetails?.dateOfReceipt || invoiceDocument?.document.created) {
        dateOfReceipt = formatDateOnly(new Date(invoiceDocument?.result?.invoiceDetails?.dateOfReceipt ?? invoiceDocument.document.created));
      }

      const contractId =
        invoiceDocument?.document?.uploadedByContract?.id ?? invoiceDocument?.result?.contractId ?? null;

      const foundContract = loadedContracts.find((contract) => contract.id === contractId);
      const netValue =
        invoiceDocument?.result?.invoiceDetails.net === 0 && invoiceDocument?.result?.invoiceDetails.gross
          ? invoiceDocument?.result?.invoiceDetails.gross
            ? Number(
                (
                  invoiceDocument?.result?.invoiceDetails.gross /
                  (1 + invoiceDocument?.result?.invoiceDetails.vat / 100)
                ).toFixed(2),
              )
            : 0
          : invoiceDocument?.result?.invoiceDetails.net ?? 0;

      const foundContractor = branches ? findBranchByIbanOrName(
        branches,
        invoiceDocument?.result?.contractorName,
        invoiceDocument?.result?.contractorBankDetails?.iban,
      ) : null;

      return {
        invoicingPartyId: foundContract?.contractorId ?? foundContractor?.id ?? null,
        invoiceRecipientId: foundContract?.clientId ?? invoiceDocument?.result?.customerId ?? null,
        contractId: contractId ?? null,
        code: invoiceDocument?.result?.invoiceCode ?? '',
        externalCode: invoiceDocument?.result?.externalCode ?? invoiceDocument?.result?.invoiceDetails.number ?? '',
        state: 'Pending' as InvoiceState,
        type: invoiceDocument?.result?.invoiceDetails.type ?? ('Single' as InvoiceType),
        dateOfReceipt: dateOfReceipt,
        invoiceDate: invoiceDocument?.result?.invoiceDetails.invoiceDate ?? null,
        dateOfAudit: null,
        dateOfApproval: null,
        paymentDate: null,
        vat: invoiceDocument?.result?.invoiceDetails.vat ?? 0,
        net: netValue,
        claim: invoiceDocument?.result?.invoiceDetails.gross ?? 0,
        dueDate: invoiceDocument?.result?.invoiceDetails.dueDate ?? null,
        cashDiscountDate: invoiceDocument?.result?.invoiceDetails.cashDiscountDate ?? null,
        workflow: invoiceDocument?.result?.workflowDefinitionId ?? fetchedContract?.workflowDefinitionId ?? null,
        comment: invoiceDocument?.result?.comment ?? '',
      };
    }, [invoiceDocument, loadedContracts, branches, fetchedContract]);

    useEffect(() => {
      if(!defaultFormValues.contractId && invoiceDocument?.result?.invoiceDetails.type !== undefined && invoiceDocument?.result?.invoiceDetails.type !== 'Single' && setIsFormValid) {
        setIsFormValid(false);
      }
    }, [defaultFormValues.contractId, invoiceDocument?.result?.invoiceDetails.type, setIsFormValid]);

    useEffect(() => {
      if (typeof fetchedContracts !== 'undefined') {
        let allContracts: ShortContractReadModel[] = fetchedContracts.contracts ?? [];
        let contractOptions =
          fetchedContracts.contracts?.filter((contract) => !contract.isPxContract ).map((contract) => {
            return {
              value: contract.id,
              label: `${contract.code} - ${contract.name}`,
            };
          }) ?? [];

        fetchedContracts.commitments?.forEach((commitment) => {
          allContracts = [...allContracts, ...(commitment.contracts ?? [])];
          contractOptions = contractOptions.concat(
            commitment.contracts?.filter((contract) => !contract.isPxContract ).map((contract) => {
              return {
                value: contract.id,
                label: `${contract.code} - ${contract.name}`,
              };
            }) ?? [],
          );
        });
        setLoadedContracts(allContracts);
        setContractOptions(contractOptions);
      }
    }, [fetchedContracts]);

    useEffect(() => {
      if (createdContract && loadedContracts.length && formRef.current) {
        const foundContract = loadedContracts.find((contract) => contract.id === createdContract);
        if (foundContract) {
          setSelectedContract(foundContract ?? null);
          formRef.current.setValue('invoicingPartyId', foundContract.contractorId ?? null);
          formRef.current.setValue('invoiceRecipientId', foundContract.clientId ?? null);
          formRef.current.setValue('contractId', foundContract.id);
        }
      }
    }, [loadedContracts, createdContract]);

    useEffect(() => {
      if (
        (invoiceDocument?.document?.uploadedByContract?.id || invoiceDocument?.result?.contractId) &&
        loadedContracts.length &&
        formRef.current &&
        !createdContract
      ) {
        const contractId = invoiceDocument?.document?.uploadedByContract?.id ?? invoiceDocument?.result?.contractId;
        const foundContract = loadedContracts.find((contract) => contract.id === contractId);
        if (foundContract) {
          setSelectedContract(foundContract ?? null);
          formRef.current.setValue('invoicingPartyId', foundContract.contractorId ?? null);
          formRef.current.setValue('invoiceRecipientId', foundContract.clientId ?? null);
          formRef.current.setValue('contractId', foundContract.id);
        }
      }
    }, [
      createdContract,
      loadedContracts,
      invoiceDocument?.document?.uploadedByContract?.id,
      invoiceDocument?.result?.contractId,
    ]);

    const positions: InvoiceDocumentPositionReadModel[] = []; // TODO

    const handleError = (e: unknown) => {
      const error = e as FetchBaseQueryError;
      const data = error.data as ProbisErrorDataType;
      if (data?.code === 'error.invoice.code_already_exists') {
        setCodeError(true);
      }
      setIsSaving(false);
    };

    const reset = () => {
      setUnsavedData([]);
      setIsSaving(false);
      setIsUpdated(true);
    };

    const handleSubmit = async (data: InvoiceCreateFormValidationValues, mode: 'create' | 'save') => {
      if (loadedProjectId && loadedVariantId && invoiceDocument?.document.id) {
        if (data.contractId && mode === 'create') {
          try {
            await safeMutation(
              createInvoiceByContract,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                contractId: data.contractId,
                body: {
                  invoiceDocumentId: invoiceDocument.document.id,
                  code: data.code,
                  externalCode: data.externalCode,
                  type: data.type,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                  workflowDefinitionId: data.workflow,
                },
              },
              isCreatingByContract,
            );
            reset();
            onClose();
            return true;
          } catch (e) {
            handleError(e);
            return false;
          }
        } else if (mode === 'create' && !data.contractId) {
          try {
            await safeMutation(
              createInvoice,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                body: {
                  invoiceDocumentId: invoiceDocument.document.id,
                  invoicingPartyId: data.invoicingPartyId,
                  invoiceRecipientId: data.invoiceRecipientId,
                  code: data.code,
                  externalCode: data.externalCode,
                  type: data.type,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                  workflowDefinitionId: data.workflow,
                },
              },
              isCreating,
            );
            reset();
            onClose();
            return true;
          } catch (e) {
            handleError(e);
            return false;
          }
        } else if (mode === 'save') {
          try {
            await safeMutation(
              updateInvoiceDocument,
              {
                projectId: loadedProjectId,
                documentId: invoiceDocument.document.id,
                body: {
                  contractId: data.contractId,
                  workflowDefinitionId: data.workflow,
                  invoiceType: data.type,
                  invoicingPartyId: data.invoicingPartyId,
                  invoiceRecipientId: data.invoiceRecipientId,
                  code: data.code,
                  externalCode: data.externalCode,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                },
              },
              isUpdating,
            );
            reset();
            return true;
          } catch (e) {
            handleError(e);
            return false;
          }
        }
      }
    };

    const { trigger } = useForm();

    useImperativeHandle(ref, () => ({
      createInvoice: async () => {
        if (formRef.current) {
          const isValid = await trigger();
          if (setIsFormValid) {
            setIsFormValid(isValid);
          }
          return (await handleSubmit(formRef.current.getValues(), 'create')) ?? false;
        }
        return false;
      },
      saveInvoice: async () => {
        if (formRef.current) {
          const isValid = await trigger();
          if (setIsFormValid) {
            setIsFormValid(isValid);
          }
          return (await handleSubmit(formRef.current.getValues(), 'save')) ?? false;
        }
        return false;
      },
    }));

    useEffect(() => {
      if (setIsLoading) {
        setIsLoading(isCreating || isCreatingByContract || isUpdating || isFetchingContracts);
      }
    }, [isCreating, isCreatingByContract, isUpdating, isFetchingContracts, setIsLoading]);

    // render form with default values only if invoice document was loaded already
    if (!invoiceDocument) return null;

    return (
      <Form<InvoiceCreateFormValidationValues>
        onSubmit={(data) => handleSubmit(data, 'create')}
        validationSchema={InvoiceCreateFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col gap-7"
        ref={formRef}
      >
        <FormWatch<InvoiceCreateFormValidationValues>
          onChange={({ invoicingPartyId, invoiceRecipientId }) => {
            if ((invoicingPartyId && !!selectedContract && selectedContract?.contractorId !== invoicingPartyId ) || (invoiceRecipientId && !!selectedContract && selectedContract?.clientId !== invoiceRecipientId)) {
              setSelectedContract(null);
            }
          }}
          fieldNames={[
            'externalCode',
            'code',
            'invoicingPartyId',
            'invoiceRecipientId',
            'type',
            'invoiceDate',
            'dateOfReceipt',
            'dueDate',
            'cashDiscountDate',
            'claim',
            'net',
            'vat',
            'workflow',
          ]}
        >
          {({ dateOfReceipt, claim, net, vat, type}) => (
            <>
            <GuideDialog
                show={
                  guideDialogData?.showDialog &&
                  guideDialogData?.elements[guideDialogData?.currentId] === 'contract' &&
                  slideOverOpen
                }
                guideDialogData={guideDialogData}
                setGuideDialogData={setGuideDialogData}
                showCount
                showCloseButton
                title={t('projectInvoice.createOrSearchContract')}
                description={t('projectInvoice.createOrSearchContractDescription')}
                controls={
                  <GuideDialogControls
                    guideDialogData={guideDialogData}
                    setGuideDialogData={setGuideDialogData}
                  />
                }
                targetElement={contractElement}
              />
              {/* CONTRACT */}
              <DocumentViewerFileDataGroup className="relative overflow-hidden" divider={false}>
                <Disclosure as="div" className="flex flex-col divide-y-2" defaultOpen>
                  {({ open }) => (
                    <>
                      <DisclosureButton>
                        <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                          <PaidBillDottedIcon className="w-9 flex-none" />
                          <SlideOverTitle
                            marginTop={false}
                            title={t('projectContract.contract')}
                            className="mt-4 flex-1 flex hover:text-gray-800 duration-300 transition-colors relative"
                          >
                            <div className={classNames('flex-1 flex items-center justify-end')}>
                              <ChevronDownIcon
                                className={classNames(
                                  'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5',
                                  {
                                    'rotate-0': open,
                                  },
                                )}
                              />
                            </div>
                          </SlideOverTitle>
                        </div>
                      </DisclosureButton>
                      <DisclosurePanel>
                        {/* CONTRACTOR & CLIENT */}
                        <InvoiceDocumentReviewContractorAndClient
                          fileData={fileData}
                          formRef={formRef}
                          selectedContract={selectedContract}
                          defaultFormValues={defaultFormValues}
                        />
                        {/* CONTRACT */}
                        <div className="pt-4" ref={setContractElement}> 
                            <DocumentViewerFileDataInlineEdit
                              allowChangeMode={allowChangeMode}
                              noHover={selectedContract ? false : true}
                              toggleContent={
                                <FormField name="contractId">
                                  {(control) => (
                                    <ComboSelect
                                      nullable
                                      icon={<ContractIcon className="h-6 w-6" />}
                                      label={t('projectContract.contract')}
                                      options={contractOptions}
                                      disabled={!canWrite}
                                      handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                      {...control}
                                      onChange={(contractId) => {
                                        const foundContract = loadedContracts.find(
                                          (contract) => contract.id === contractId,
                                        );
                                        setSelectedContract(foundContract ?? null);
                                        control.onChange(contractId);
                                        formRef.current?.setValue('invoicingPartyId', foundContract?.contractorId ?? null);
                                        formRef.current?.setValue('invoiceRecipientId', foundContract?.clientId ?? null);
                                        /*
                                         * For the due date the due date deadline days of contract are added to the invoice receipt date
                                         * For the cash discount date the ash discount deadline days of contract are added to the invoice receipt date
                                         */
                                        if (formRef.current && dateOfReceipt && foundContract) {
                                          if (foundContract?.dueDateDeadline) {
                                            formRef.current.setValue(
                                              'dueDate',
                                              addDays(dateOfReceipt, foundContract.dueDateDeadline),
                                            );
                                          }
                                          if (foundContract?.cashDiscountDeadline) {
                                            formRef.current.setValue(
                                              'cashDiscountDate',
                                              addDays(dateOfReceipt, foundContract.cashDiscountDeadline),
                                            );
                                          }
                                        }
                                        if(!foundContract && type !== 'Single') {
                                          formRef.current?.setValue('type', 'Single');
                                          if(updateUnsavedData) {
                                            updateUnsavedData('type', defaultFormValues.type === 'Single');
                                          }
                                        }

                                        if (updateUnsavedData) {
                                          updateUnsavedData('contractId', defaultFormValues.contractId === foundContract?.id);
                                        }
                                      }}
                                      additionalOptionOnClick={() => setIsCreateContractWizardOpen(true)}
                                      additionalOption={
                                        <ComboSelectAdditionalOption label={t('projectContract.createContract')} />
                                      }
                                    />
                                  )}
                                </FormField>
                              }
                            >
                              {selectedContract ? (
                                <div className="flex flex-col gap-4">
                                  <DocumentViewerFileDataSet
                                    className={
                                      selectedContract.id !== defaultFormValues.contractId ? 'text-secondary' : undefined
                                    }
                                    label={t('projectContract.contract')}
                                    title={selectedContract.name}
                                    text={selectedContract?.description}
                                  />
                                  <div className="grid grid-cols-2 gap-2 items-center">
                                    <DocumentViewerFileDataSet
                                      label={t('projectControl.contractNr')}
                                      subtitle={<span className="font-bold text-lg">{selectedContract.code}</span>}
                                    />
                                    {/* {selectedContract.commitmentId && (
                                    <DocumentViewerFileDataSet
                                      label={t('projectControl.commitmentDate')}
                                      subtitle={formatDate(selectedContract.contractDate)}
                                    />
                                  )} */}
                                  </div>
                                  {selectedContract.commitmentId && (
                                    <DocumentViewerFileDataSet
                                      label={t('projectControl.commitments')}
                                      subtitle={
                                        fetchedContracts?.commitments?.find(
                                          (commitment) => commitment.id === selectedContract.commitmentId,
                                        )?.name ?? ''
                                      }
                                    />
                                  )}
                                </div>
                              ) : (
                                <DocumentViewerFileDataSet
                                  label={t('projectContract.contract')}
                                  subtitle={
                                    <div className="w-full flex justify-center">
                                      <Button variant="secondary">{t('common.add')}</Button>
                                    </div>
                                  }
                                />
                              )}
                            </DocumentViewerFileDataInlineEdit>
                        </div>
                        {/* WORKFLOW */}
                        <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['workflow']}>
                          {({ workflow }) => (
                            <DocumentViewerFileDataInlineEdit
                              allowChangeMode={allowChangeMode}
                              className="mt-7"
                              toggleContent={
                                canWrite ? (
                                  <FormField name="workflow">
                                    {(control) => (
                                      <ComboSelect
                                        label={t('app.workflow')}
                                        icon={<WorkflowIcon className="h-6 w-6" />}
                                        nullable
                                        options={workflowOptions}
                                        disabled={!canWrite || workflowOptions.length === 0}
                                        {...control}
                                        onChange={(workflow) => {
                                          control.onChange(workflow);
                                          if (updateUnsavedData) {
                                            updateUnsavedData('workflow', workflow === defaultFormValues.workflow);
                                          }
                                        }}
                                        handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                      />
                                    )}
                                  </FormField>
                                ) : undefined
                              }
                            >
                              <DocumentViewerFileDataSet
                                label={t('app.workflow')}
                                subtitle={
                                  workflow ? workflowOptions?.find((option) => option.value === workflow)?.label : '-'
                                }
                                className={workflow !== defaultFormValues.workflow ? 'text-secondary' : undefined}
                              />
                            </DocumentViewerFileDataInlineEdit>
                          )}
                        </FormWatch>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </DocumentViewerFileDataGroup>

              {/* INVOICE DATA */}
              <DocumentViewerFileDataGroup className="relative">
                <InvoiceDataEdit
                  codeError={codeError}
                  defaultFormValues={defaultFormValues}
                  allowChangeMode={allowChangeMode}
                  setAllowChangeMode={setAllowChangeMode}
                  canEdit={canWrite}
                  updateUnsavedData={updateUnsavedData}
                  first
                  showType
                  contractId={selectedContract?.id ?? null}
                />
              </DocumentViewerFileDataGroup>

              {/* INVOICE TITLES AND VALUES */}
              <DocumentViewerFileDataGroup className="relative" divider={false}>
                {/* INVOICE TITLES */}
                {positions && positions?.length > 0 && (
                  <div className="flex flex-col gap-4 text-[15px] truncate">
                    {positions.map((position, i) => (
                      <div
                        key={`invoice-position-${i}`}
                        className={cn('flex flex-nowrap gap-4 justify-between transition-colors duration-300', {
                          'bg-red-500/20': hoveredBox === i,
                          'cursor-pointer': boxes?.length && boxes[i],
                        })}
                        onMouseEnter={boxes?.length && boxes[i] ? () => setHoveredBox(i) : undefined}
                        onMouseLeave={boxes?.length && boxes[i] ? () => setHoveredBox(null) : undefined}
                      >
                        <span className="truncate">{position.value}</span>
                        <span className="text-right whitespace-nowrap">
                          {position.correctedValue ? position.correctedValue : position.originalValue}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {/* INVOICE VALUES */}
                <InvoiceDocumentReviewValues
                  claim={claim}
                  net={net}
                  vat={vat}
                  formRef={formRef}
                  defaultFormValues={defaultFormValues}
                  updateUnsavedData={(key, value) => {
                    updateUnsavedData(key, value)}}
                />
              </DocumentViewerFileDataGroup>

              {/*
          {allBoundingBoxes && (
            <Button variant="text" onClick={setShowAllBoxes} className="text-left text-xs">
              {showAllBoxes ? 'Hide' : 'Show'} all extracted texts
            </Button>
          )}
          */}
            </>
          )}
        </FormWatch>

        <Modal isOpen={isCreateContractWizardOpen} onClose={() => setIsCreateContractWizardOpen(false)}>
          <ContractNewWizard
            onClose={(createdContract) => {
              setIsCreateContractWizardOpen(false);
              // setCreatedContract(createdContract)
            }}
            setNewContractId={setCreatedContract}
          />
        </Modal>
      </Form>
    );
  },
);
