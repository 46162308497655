import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from '.';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  BaseSelect,
  BaseSelectOption,
  DollarBagIcon,
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TimesheetIcon,
  TrashIcon,
} from '@client/shared/toolkit';
import { RiskMitigationStatus, RiskMitigationType, UpsertRiskMitigationPayload } from '@client/shared/api';

export const WidgetDashboardEditFormRiskMitigationListValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  riskMitigations: yup
    .array()
    .of(yup.mixed<UpsertRiskMitigationPayload>().required('validation.required'))
    .min(1)
    .required('validation.required'),
});

export type WidgetDashboardEditFormRiskMitigationListValidationValues = InferType<
  typeof WidgetDashboardEditFormRiskMitigationListValidationSchema
>;

const DEFAULT_RISK_MITIGATION: UpsertRiskMitigationPayload = {
  name: '',
  description: '',
  type: 'Time',
  status: 'Critical',
};

const RISK_MITIGATION_LIMIT = 10;

export const WidgetEditFormRiskMitigationList = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormRiskMitigationListValidationValues>>();
  const [riskMitigations, setRiskMitigationList] = useState<UpsertRiskMitigationPayload[]>([DEFAULT_RISK_MITIGATION]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setRiskMitigationList(widget?.widget.additionalConfig?.RiskMitigationList ?? [DEFAULT_RISK_MITIGATION]);
  }, [widget?.widget.additionalConfig?.RiskMitigationList]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.riskMitigationList.title'),
      size: selectedSize,
      riskMitigations: widget?.widget.additionalConfig?.RiskMitigationList ?? [DEFAULT_RISK_MITIGATION],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.RiskMitigationList]);

  const onAdd = () => {
    if (riskMitigations.length < RISK_MITIGATION_LIMIT) {
      const copy = [...riskMitigations];
      copy.push(DEFAULT_RISK_MITIGATION);
      setRiskMitigationList(copy);
      formRef.current?.setValue('riskMitigations', copy);
    }
  };

  const onUpdateRiskMitigationList = (data: UpsertRiskMitigationPayload, index: number) => {
    if (riskMitigations[index]) {
      const copy = [...riskMitigations];
      copy[index] = data;
      setRiskMitigationList(copy);
      formRef.current?.setValue('riskMitigations', copy);
    }
  };

  const onDeleteRiskMitigationList = (index: number) => {
    if (riskMitigations[index]) {
      const copy = [...riskMitigations];
      copy.splice(index, 1);
      setRiskMitigationList(copy);
      formRef.current?.setValue('riskMitigations', copy);
    }
  };

  return (
    <Form<WidgetDashboardEditFormRiskMitigationListValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormRiskMitigationListValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>

        {riskMitigations.map((riskMitigation, index) => (
          <RiskMitigationListForm
            key={`risk-mitigation-form-${index}`}
            riskMitigation={riskMitigation}
            index={index}
            onUpdateRiskMitigationList={(data: UpsertRiskMitigationPayload) => onUpdateRiskMitigationList(data, index)}
            canDelete={riskMitigations.length > 1}
            onDelete={() => onDeleteRiskMitigationList(index)}
            handlePopoverVisibility={(isOpen) => setIsDropdownOpen(isOpen)}
          />
        ))}
        {riskMitigations.length < RISK_MITIGATION_LIMIT && !isDropdownOpen && (
          <div className="flex w-full justify-end items-center z-50 relative">
            <AddButton onClick={onAdd} className="-mt-4" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};

export interface RiskMitigationListFormProps {
  riskMitigation: UpsertRiskMitigationPayload;
  onUpdateRiskMitigationList: (data: UpsertRiskMitigationPayload) => void;
  index: number;
  canDelete: boolean;
  onDelete: () => void;
  handlePopoverVisibility?: (isOpen: boolean) => void;
}

export const RiskMitigationListForm = (props: RiskMitigationListFormProps) => {
  const { riskMitigation, index, canDelete, onDelete, onUpdateRiskMitigationList, handlePopoverVisibility } = props;
  const { t } = useTranslation();

  const riskMitigationStatusOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        value: 'ToConsider',
        label: t('dashboard.widget.riskMitigation.status.toConsider'),
      },
      {
        value: 'Important',
        label: t('dashboard.widget.riskMitigation.status.important'),
      },
      {
        value: 'Critical',
        label: t('dashboard.widget.riskMitigation.status.critical'),
      },
    ];
  }, [t]);

  const riskMitigationIconOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        value: 'Time',
        label: t('dashboard.widget.riskMitigation.riskMitigationTypeTime'),
        info: <TimesheetIcon className="w-5 h-5" />,
      },
      {
        value: 'Cost',
        label: t('dashboard.widget.riskMitigation.riskMitigationTypeCost'),
        info: <DollarBagIcon className="w-5 h-5" />,
      },
    ];
  }, [t]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <SlideOverTitle title={`${index + 1}. ${t('dashboard.widget.riskMitigation.riskMitigation')}`} />
        {canDelete && <TrashIcon className="text-gray-500 w-5 h-5 mt-4 cursor-pointer" onClick={onDelete} />}
      </div>
      <TextInput
        label={t('dashboard.widget.riskMitigation.riskMitigationTitle')}
        value={riskMitigation.name}
        onChange={(val) => {
          const copy = { ...riskMitigation };
          copy.name = val ?? 0.1;
          onUpdateRiskMitigationList(copy);
        }}
      />
      <TextInput
        label={t('dashboard.widget.riskMitigation.riskMitigationDescription')}
        value={riskMitigation.description ?? ''}
        onChange={(val) => {
          const copy = { ...riskMitigation };
          copy.description = val ?? 0.1;
          onUpdateRiskMitigationList(copy);
        }}
        inputType="textarea"
      />
      <BaseSelect
        label={t('dashboard.widget.riskMitigation.riskMitigationType')}
        value={riskMitigation.type}
        options={riskMitigationIconOptions}
        onChange={(val) => {
          const copy = { ...riskMitigation };
          copy.type = (val as RiskMitigationType) ?? 'Time';
          onUpdateRiskMitigationList(copy);
        }}
        handlePopoverVisibility={handlePopoverVisibility}
      />
      <BaseSelect
        label={t('dashboard.widget.riskMitigation.riskMitigationStatus')}
        value={riskMitigation.status}
        options={riskMitigationStatusOptions}
        onChange={(val) => {
          const copy = { ...riskMitigation };
          copy.status = (val as RiskMitigationStatus) ?? 'Critical';
          onUpdateRiskMitigationList(copy);
        }}
        handlePopoverVisibility={handlePopoverVisibility}
      />
    </div>
  );
};
